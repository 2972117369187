import { ConfigurationFormData } from "./interfaces";

export const validateFormData = (
  data: ConfigurationFormData | null,
  stepIndex: number
): { [key: string]: string } => {
  const errors: { [key: string]: string } = {};

  if (!data) return errors;

  // Metadata tab validations (stepIndex === 0)
  if (stepIndex === 0) {
    if (!data.bidderId) {
      errors.bidderId = "Please select a bidder";
    }
    if (!data.bidderAlias || !/^[a-zA-Z0-9_-]{2,30}$/.test(data.bidderAlias)) {
      errors.bidderAlias = "Alias: Must be 2-30 alphanumeric characters with underscores/hyphens only.";
    }
    if (!data.seatId || !/^\d{3,15}$/.test(data.seatId)) {
      errors.seatId = "Seat ID: Must be 3-15 digits only.";
    }
    if (!data.endpointUrl || !/^https:\/\/[^\s,]+$/.test(data.endpointUrl)) {
      errors.endpointUrl = "Endpoint URL: Must be a valid HTTPS URL.";
    }
  }

  // Bidder Config tab validations (stepIndex === 1)
  if (stepIndex === 1) {
    if (data.timeout == null || data.timeout < 1 || data.timeout > 10000) {
      errors.timeout = "Timeout: Must be an integer between 1 and 10000 msec.";
    }
    if (
      data.bidFloorPrice == null ||
      !/^\d+(\.\d{1,2})?$/.test(data.bidFloorPrice.toString()) ||
      data.bidFloorPrice < 0.01 ||
      data.bidFloorPrice > 10000
    ) {
      errors.bidFloorPrice = "Floor Price: Must be between 0.01 and 10000 with up to 2 decimal places.";
    }
  }

  // Traffic Shaping tab validations (stepIndex === 2)
  if (stepIndex === 2) {
    if (data.rateLimit != null && (data.rateLimit < 1 || data.rateLimit > 10000)) {
      errors.rateLimit = "Rate Limit: Must be an integer between 1 and 10000 QPS.";
    }
    if (data.isTrafficShapingActive === 1) {
      if (data.trafficShapingTriggerTypeId === 1) {
        if (data.trafficShapingValue == null || data.trafficShapingValue < 1 || data.trafficShapingValue > 100) {
          errors.trafficShapingValue = "Traffic Limit for %: Must be an integer between 1 and 100 percent.";
        }
      } else {
        if (
          data.trafficShapingValue == null ||
          data.trafficShapingValue < 1 ||
          data.trafficShapingValue > 10000000
        ) {
          errors.trafficShapingValue =
            "Traffic Limit for Bid Responses: Must be an integer between 1 and 10000000";
        }
      }
    }
    if (
      data.trafficShapingWindowSec !== null &&
      (data.trafficShapingWindowSec < 1 || data.trafficShapingWindowSec > 3600)
    ) {
      errors.trafficShapingWindowSec = "Recovery After: Must be an integer between 1 and 3600 seconds.";
    }
  }

  return errors;
};
