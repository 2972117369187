import * as React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../theme";
import { CONSTANT } from "../../constants/constants";
interface UserStatusChipProps {
  status: number;
}

const UserStatusChip: React.FC<UserStatusChipProps> = ({ status }) => {
  let label = "";
  let bgColor = "";
  let color = "";

  switch (status) {
    case CONSTANT.ACCOUNT_STATUS.ACTIVE.value:
      label = CONSTANT.ACCOUNT_STATUS.ACTIVE.label;
      bgColor = colors.secGreen5;
      color = colors.secGreen100;
      break;
    case CONSTANT.ACCOUNT_STATUS.TRIAL.value:
      label = CONSTANT.ACCOUNT_STATUS.TRIAL.label;
      bgColor = colors.statusWarningBackgroundSubtle;
      color = colors.statusWarningHover;
      break;
    case CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.value:
    default:
      label = CONSTANT.ACCOUNT_STATUS.NOT_ACTIVE.label;
      bgColor = colors.secRed10;
      color = colors.secRed100;
      break;
  }

  return (
    <Chip
      label={label}
      size="medium"
      sx={{
        bgcolor: bgColor,
        color: color,
      }}
    />
  );
};

export default UserStatusChip;
