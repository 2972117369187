import { Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainRoutes from "./core/MainRoutes";
import Loader from "./components/Loader";
import "./index.css";
import PrivateRoute from "./auth/PrivateRoute";
import LoginAutotest from "./core/login/LoginAutotest";
import LoginRedirect from "./core/login/LoginRedirect";
const Login = lazy(() => import("./core/login/Login"));
const PDFOverviewWrapper = lazy(() => import("./pdftemplates/overview"));

export default function App() {
  return (
    <div id="ta1" className="App">
      <Routes>
        <Route path="/health" />
        <Route
          path="/login"
          element={
            <Suspense fallback={<Loader sx={{ height: "100vh", background: "rgb(20,48,80)" }} />}>
              <Login />
            </Suspense>
          }
        />
        <Route path="/login-autotest" element={<LoginAutotest></LoginAutotest>} />
        <Route path="/login-redirect" element={<LoginRedirect></LoginRedirect>} />
        <Route
          path="/pdf/overview"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <PrivateRoute>
                <PDFOverviewWrapper />
              </PrivateRoute>
            </Suspense>
          }
        />
        <Route path="*" element={<MainRoutes />} />
      </Routes>
    </div>
  );
}
