import { createSlice } from "@reduxjs/toolkit";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";

const initialState = {
  lift_type: null,
  ab_group_type: null,
  active_type: CONSTANT.OVERVIEW.GROUPE_TYPES.LIFT,
  reminder_is_activated: false,
  warning_pct_is_active: true,
  // active_type: "lift_type"
};

export const chartSelectorSlice = createSlice({
  name: "chartSelector",
  initialState,
  reducers: {
    setChartGroupEntryParametersConstancy(state, action) {
      state.active_type = action.payload;
    },
    setActiveType(state, action) {
      state.active_type = action.payload;
    },
    setReminderActivated(state, action) {
      state.reminder_is_activated = action.payload;
    },
    setWarningPctActive(state, action) {
      state.warning_pct_is_active = action.payload;
      let currentLS = auth.getStoredSettings();
      localStorage.setItem("be_app_settings", JSON.stringify({ ...currentLS, wn: action.payload }));
    },
  },
});

export const { setChartGroupEntryParametersConstancy, setActiveType, setReminderActivated, setWarningPctActive } =
  chartSelectorSlice.actions;

export default chartSelectorSlice.reducer;
