import React from "react";
import { Box } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef, MuiEvent } from "@mui/x-data-grid";
import { dataGridSx } from "./styles";
import UserStatusChip from "../components/Chips/UserStatusChip";
import { CustomPagination } from "../core/performanceReports/advancedReport/table/pagination/CustomPagination";
import ScrollButton from "../components/ScrollButton/ScrollButtton";
import moment from "moment";
import { mainTableStyle } from "../core/performanceReports/advancedReport/table/mainTableStyle";
import { CustomColumnMenuComponent } from "../core/performanceReports/advancedReport/table/MainTable";
import { useNavigate } from "react-router-dom";
import { tablesRowsAmountPerPageOptions } from "../parametersConstancy/parametersConstancyTypes";

export const CustomPaginationWrapper: React.FC<{ rowsPerPageOptions: number[] }> = ({ rowsPerPageOptions }) => {
  // Directly return your JS CustomPagination component here, passing any required props
  return (
    <Box sx={{ padding: "10px" }}>
      <CustomPagination rowsPerPageOptions={rowsPerPageOptions} />
    </Box>
  );
};

const defaultColumns = (): GridColDef[] => [
  {
    field: "partnerName",
    headerName: "Partner Name",
    flex: 1.2,
    sortable: true,
    align: "center",
  },
  {
    field: "partnerId",
    headerName: "Partner ID",
    headerAlign: "left",
    align: "center",
    flex: 1,
    sortable: true,
  },
  {
    field: "isActive",
    headerName: "Active Status",
    flex: 0.8,
    renderCell: (params: any) => <UserStatusChip status={params.row.isActive} />,
    sortable: true,
    align: "center",
  },
  {
    field: "creationDate",
    headerName: "Date of creation",
    flex: 1.2,
    renderCell: (params: any) => moment(params.row.creationDate).format("DD.MM.YYYY | HH:mm"),
    sortable: true,
    align: "center",
  },
  {
    field: "createdBy",
    headerName: "Created by",
    flex: 1,
    align: "center",
  },
  {
    field: "integration",
    headerName: "Integration type",
    flex: 1,
    align: "center",
  },
];

interface IAccountsTable {
  partners: Array<any>;
}

export const PartnersTable: React.FC<IAccountsTable> = ({ partners }) => {
  const navigate = useNavigate();

  const handleRowClick = (params: any) => {
    if (params.field === "createdBy") {
      navigate(`/accounts/user_details/${params.row.createdById}`);
      return;
    }
  };
  return (
    <Box id="table-container" sx={{ ...mainTableStyle(), width: "100%" }}>
      <DataGrid
        getRowId={(row) => row?.partnerId}
        key={"partnersDataGrid"}
        rowHeight={36}
        rows={partners}
        columns={defaultColumns()}
        autoHeight={true}
        hideFooterPagination={false}
        getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd")}
        sx={dataGridSx}
        disableRowSelectionOnClick
        slots={{
          footer: () => <CustomPagination rowsPerPageOptions={tablesRowsAmountPerPageOptions} />,
          columnMenu: CustomColumnMenuComponent,
        }}
        slotProps={{
          panel: {
            sx: { padding: "10px" },
          },
        }}
        onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
          event.defaultMuiPrevented = true;
          handleRowClick(params);
        }}
      />
      <ScrollButton />
    </Box>
  );
};
