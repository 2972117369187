export const boardWrapperStl = {
  height: "calc(100vh - 240px)",
  padding: "0px 10.5px",
  overflow: "scroll"
}


export const stl = {
  margin: "30px 0",
}

export const titleStl = {
  fontSize: "12px",
  fontWeight: 600,
  color: "secondary.mainDark",
  marginBottom: "10.5px",
};

export const titleStlAsterics = {
  "&::after": {
    content: "'*'",
    marginLeft: "10px",
    color: "red"
  },
};

export const inputSx = {
  mt: '0.2rem',
  width: "100%",
  // width: "calc(80% - 10px)",
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #E0E2E7',
    outline: 'none',
  },
  input: {
    color: 'secondary.mainDark',
    padding: "0.75rem",

    fontSize: "1rem",

    '&::placeholder': {
      color: '#8590A0',
    },
  },
};