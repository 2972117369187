export const setOfReturnedReportValues = {
  //values line chart
  1: (data) => data.total_cpm1 - (data.total_cpm2 * (data.pct / (100 - (data.pct === 100 ? 99 : data.pct)))),//revenue lift
  // 1: (data) => data.total_cpm1 - (data.total_cpm2 * (data.pct / (100 - data.pct))),//revenue lift //after check on prod build we can remove this
  2: (data) => data.avg_cpma - data.avg_cpmb,//avg cpm lift
  3: (data) => {
    return +(data.abFillRateLift);// fill rate lift
  },
};

export const setOfTemplateProperties = {
  //values of template, which bar chart(a/b) works with
  1: () => ({
    gr_a_cash: 0,
    gr_b_cash: 0,
    gr_a_imp: 0,
    gr_b_imp: 0,
    ep_cash: 0,
    ep_imp: 0,
    avg_cpm: 0,
  }),
  2: () => ({
    gr_a: 0,
    gr_b: 0,
    gr_a_imp: 0,
    gr_b_imp: 0,
  }),
  3: () => {
    return {
      gr_a: 0,
      gr_b: 0,
      gr_a_imp: 0,
      gr_b_imp: 0,
    }
  },
};

export const setOfCalculatedProperties = {
  //values bar chart(a/b)
  1: (chartData, rawData) => {
    chartData.gr_a_cash += rawData.total_cpm1;
    chartData.gr_b_cash += rawData.total_cpm2;
    chartData.gr_a_imp += +rawData.total_rows1;
    chartData.gr_b_imp += +rawData.total_rows2;
    chartData.ep_cash += +rawData.total_cpm2 * (100 / (100 - (rawData.pct === 100 ? 99 : rawData.pct)));//extrapolation to 100% of group B
    chartData.ep_imp += rawData.total_rows2 * (100 / (100 - (rawData.pct === 100 ? 99 : rawData.pct))); //extrapolatioin to 100% of group B
    //after check on prod build we can remove these
    // chartData.ep_cash += +rawData.total_cpm2 * (100 / (100 - rawData.pct));//extrapolation to 100% of group B
    // chartData.ep_imp += rawData.total_rows2 * (100 / (100 - rawData.pct)); //extrapolatioin to 100% of group B
    chartData.avg_cpm += (+rawData.avg_cpma);

    return chartData;
  },
  2: (chartData, rawData,) => {
    //ab cpm 
    // chartData.gr_a += rawData.avg_cpma;
    // chartData.gr_b += rawData.avg_cpmb;
    chartData.gr_a += rawData.total_cpm1;
    chartData.gr_b += rawData.total_cpm2;
    chartData.gr_a_imp += +rawData.total_rows1;;
    chartData.gr_b_imp += +rawData.total_rows2;

    return chartData;
  },
  3: (chartData, rawData,) => {
    //ab fill rate lift (featuring only in "Top 5" items selection, chart is not available) 
    chartData.gr_a += +rawData.abFillRateLift;
    chartData.gr_a_imp += +rawData.total_rows1;
    chartData.gr_b_imp += +rawData.total_rows2;

    return chartData;
  },
};

export const setOfAdditionalCalculations = {
  // apply additional calculations to values for bar chart(a/b), such as sum, avg...
  2: (chartData, dataLength) => {
    //get avg values for ab cpm chart
    //divide the aggregated values ​​by the length of the object (not null values)
    // let { avg_cpma, avg_cpmb } = dataLength;
    // chartData.gr_a = chartData.gr_a / avg_cpma;
    // chartData.gr_b = chartData.gr_b / avg_cpmb;
    chartData.gr_a = 1000 * chartData.gr_a / chartData.gr_a_imp;
    chartData.gr_b = 1000 * chartData.gr_b / chartData.gr_b_imp;

    return chartData;
  }
};

export const setOfAdditionalCalculationsToInfoBoxLineChart = {
  // apply additional calculations to agregated values for fill rate lift
  fillRate: ({ fillRate: agregatedValues }, pct) => {
    let normalizedGrB = pct === 100 ? pct * agregatedValues.total_rows2 : (pct / (100 - pct)) * agregatedValues.total_rows2;
    // let normalizedGrB = ((pct / (100 - pct)) * agregatedValues.total_rows2); //after check on prod build we can remove this
    if (normalizedGrB === 0) {
      return 100;
    };
    let res = 100 * (agregatedValues.total_rows1 / normalizedGrB - 1);
    return res;
  },
};

export const setOfCalculationsToInfoBoxLineChart = {
  revenue: (rawData, pct) => {
    return Math.round(+(rawData.total_cpm1 + rawData.total_cpm2));
  },
  revenueLift: (rawData, pct) => {
    return Math.round(+(rawData.total_cpm1 - rawData.total_cpm2 * pct / (100 - (pct === 100 ? 99 : pct))));
    // return Math.round(+(rawData.total_cpm1 - rawData.total_cpm2 * pct / (100 - pct))); //after check on prod build we can remove this
  },
  impressions: (rawData) => {
    return +rawData.total_rows1 + (+rawData.total_rows2 || 0);
  },
  cpm: (rawData, pct) => {
    // Cost Per Mille
    let sum_avg_cpma = (1000 * (+rawData.total_cpm1)) / +rawData.total_rows1;
    let sum_avg_cpmb = (1000 * (+rawData.total_cpm2)) / +rawData.total_rows2;

    if (isNaN(sum_avg_cpma - sum_avg_cpmb)) return 0; //TODO check the formula if result is NaN
    return sum_avg_cpma - sum_avg_cpmb;
  },
  fillRate: (rawData, pct) => {
    return {
      total_rows1: +rawData.total_rows1,
      total_rows2: +rawData.total_rows2,
    };
  },
};

export const convertedBrowsersIds = {
  0: {
    color: "#E63125",
    cltName: "Unknown",
  },
  1: {
    color: "blue",
    cltName: "Other",
  },
  2: {
    color: "#11D16A",
    cltName: "Chrome",
  },
  3: {
    color: "gold",
    cltName: "Firefox",
  },
  4: {
    color: "green",
    cltName: "IE",
  },
  5: {
    color: "salmon",
    cltName: "Safari",
  },
  6: {
    color: "blue",
    cltName: "Opera",
  },
  7: {
    color: "cyan",
    cltName: "Edge",
  },
  8: {
    color: "silver",
    cltName: "Android Webkit",
  }
};

export const filterBasisCodes = {
  summary: {
    color: "#E63125",
    title: "Summary",
    subtitle: "of Revenue Over Time",
  },
  client_type: {
    color: "#E63125",
    title: "Browser",
    subtitle: "by Browsers Over Time",
  },
  biddercode: {
    color: "#E98175",
    title: "Bidder",
    subtitle: "by Bidders Over Time",
  },
  placement_id: {
    color: "#E98175",
    title: "Placement",
    subtitle: "by placement Over Time",
  },
  site_id: {
    color: "#E98175",
    title: "Site",
    subtitle: "by sites over Time",
  },
}