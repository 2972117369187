import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import { setClutterRemovalData, setSettingsStrictMode } from "../../report/reportSlice";
import { getSummary } from "../../report/reportThunks/getSummary";
import {
  setPctSiteReportsDeselection,
  resetMainTableData,
  resetTimeRangeAndSelectionBase,
  resetVRTData,
} from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { setWarningPctActive } from "../../chartSelector/chartSelectorSlice";
import { getCategories } from "../../core/performanceReports/advancedReport/getCategories";
import { getReport } from "../../report/reportThunks/getReport";
import { getReportPreviousPeriod } from "../../report/reportThunks/getReportPreviousPeriod";
import auth from "../../auth/auth-helper";
import { Switch, alpha } from "@mui/material";
import { blue } from "@mui/material/colors";
import { CONSTANT } from "../../constants/constants";

const stl = {
  card: {
    maxWidth: 600,
    margin: "auto",
    marginTop: "5px",
    paddingBottom: "2px",
  },
  error: {
    verticalAlign: "middle",
  },
  title: {
    marginTop: "2px",
    color: "gray",
  },
  textField: {
    marginLeft: "2px",
    marginRight: "2px",
    width: 100,
  },
  submit: {
    margin: "auto",
    marginBottom: "2px",
    padding: "10px 15px",
  },
  descriptionLine: {
    textAlign: "left",
    color: "rgb(1, 45, 76)",
    fontSize: "14px",
    margin: "10px 0px 16px",
    padding: "10px",
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    borderRadius: "5px",
  },
};

export default function SettingsPopup(props) {
  const dispatch = useDispatch();
  const { clutterRemovalPercentage, filterBase, active_chart, settingsStrictMode } = useSelector(
    (state) => state.report,
  );
  const customDateRange = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange,
  );
  const { partnersSelected } = useSelector((state) => state.role);
  const { pctSiteReportsDeselection } = useSelector((state) => state.advancedReport);
  const { warning_pct_is_active } = useSelector((state) => state.chartSelector);
  const { dateRange } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const active_range_date = dateRange.value;

  const [values, setValues] = useState({
    clutterRemovalPercetageWithIIQ: clutterRemovalPercentage.crpWithIIQ,
    clutterRemovalPercetageWithoutIIQ: "",
    defaultFiltrationDeselection: clutterRemovalPercentage.deselectionPercentage,
    defaultSitesFiltrationDeselection: pctSiteReportsDeselection,
    open: false,
    error: "",
  });

  const jwt = auth.isAuthenticated();
  const handleChange = (name) => (event) => {
    setValues({ ...values, [name]: event.target.value });
  };

  const handleConfirm = (event) => {
    dispatch(setWarningPctActive(!warning_pct_is_active));
    props.closeReportSettingsPopover();
  };

  const clickSubmit = () => {
    //apply, if values changed
    if (
      clutterRemovalPercentage.crpWithIIQ !== +values.clutterRemovalPercetageWithIIQ ||
      clutterRemovalPercentage.deselectionPercentage !== +values.defaultFiltrationDeselection
    ) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      const payload = {
        clutterRemovalPercetageWithIIQ: +values.clutterRemovalPercetageWithIIQ || null,
        // clutterRemovalPercetageWithoutIIQ: values.clutterRemovalPercetageWithoutIIQ || null,
        defaultFiltrationDeselection: +values.defaultFiltrationDeselection || null,
      };

      let payloadReports = {
        id: jwt.data.user.id,
        setFilterBase: filterBase,
        token: jwt.data.accessToken,
        ct: active_chart === 0 ? 1 : active_chart,
        rt: active_range_date,
        customDateRange: customDateRange,
        signal,
      };

      if (active_range_date === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) payload.customDateRange = customDateRange;

      dispatch(setClutterRemovalData(payload));

      if (!!partnersSelected) {
        dispatch(getReport(payloadReports));

        dispatch(getReportPreviousPeriod(payloadReports));
        dispatch(getSummary());
      }
    }

    // apply, if defaultSitesFiltrationDeselection changed
    if (values.defaultSitesFiltrationDeselection !== pctSiteReportsDeselection) {
      if (partnersSelected) {
        dispatch(resetMainTableData());
        dispatch(resetVRTData());
        dispatch(resetTimeRangeAndSelectionBase());
        getCategories(["sites"], partnersSelected, {
          pctSiteReportsDeselection: values.defaultSitesFiltrationDeselection,
        });
      }
      dispatch(setPctSiteReportsDeselection(values.defaultSitesFiltrationDeselection));
    }

    props.closeReportSettingsPopover();
    // props.setAnchorEl(null);
  };

  return (
    <Box sx={stl.card} id="report-settings-popup">
      <span style={{ fontWeight: 700, color: "rgb(1, 45, 76)" }}>Reports overview settings</span>
      <Typography variant="h6" sx={stl.title}>
        <div style={stl.descriptionLine}>
          Please set up clutter removal percentage to remove from charts minor or very low values{" "}
        </div>
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "320px" }}>Clutter Removal Percetage With IIQ, %: </Box>
        <Box>
          <TextField
            id="clutter-removal-percetage-with-iiq-input"
            variant="standard"
            sx={stl.textField}
            value={values.clutterRemovalPercetageWithIIQ}
            onChange={handleChange("clutterRemovalPercetageWithIIQ")}
            margin="normal"
          />
        </Box>
      </Box>
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "320px" }}>Clutter Removal Percetage Without IIQ, %: </Box>
        <Box>
          <TextField id="clutterRemovalPercetageWithoutIIQ" variant="standard" sx={stl.textField} value={values.clutterRemovalPercetageWithoutIIQ} onChange={handleChange('clutterRemovalPercetageWithoutIIQ')} margin="normal" />
        </Box>
      </Box > */}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "320px",
          }}
        >
          Default Filtration Deselection, %:{" "}
        </Box>
        <Box>
          <TextField
            id="default-filtration-deselection-input"
            type="defaultFiltrationDeselection"
            variant="standard"
            sx={stl.textField}
            value={values.defaultFiltrationDeselection}
            onChange={handleChange("defaultFiltrationDeselection")}
            margin="normal"
          />
        </Box>
      </Box>
      {/* performance report settings  */}
      {/* <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "320px",
          }}
        >
          Sites Filtration Deselection, %:
          <Box
            sx={{
              width: "320px",
              fontSize: "12px",
              marginTop: "5px",
            }}
          >
            applied to performance report page
          </Box>
        </Box>

        <Box>
          <TextField
            id="defaultSitesFiltrationDeselection"
            type="defaultSitesFiltrationDeselection"
            variant="standard"
            sx={stl.textField}
            value={values.defaultSitesFiltrationDeselection}
            onChange={handleChange("defaultSitesFiltrationDeselection")}
            margin="normal"
          />
        </Box>
      </Box> */}
      <Box sx={{ mt: "0.75rem", mb: "0.5rem", fontSize: "1rem", textAlign: "left" }}>
        Show multiple percentage/currency mode notification
        <Checkbox
          id="show-multiple-percentage-checkbox"
          sx={{ transform: "scale(0.7)" }}
          checked={warning_pct_is_active}
          onChange={handleConfirm}
        />
      </Box>
      <Box sx={stl.cardItem}>
        Show Settings strictly
        <Switch
          id="show-settings-strictly-switch"
          checked={settingsStrictMode}
          onChange={(e) => {
            dispatch(setSettingsStrictMode(e.target.checked));
          }}
          sx={(theme) => ({
            "& .MuiSwitch-switchBase.Mui-checked": {
              color: blue[600],
              "&:hover": {
                backgroundColor: alpha(blue[600], theme.palette.action.hoverOpacity),
              },
            },
            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
              backgroundColor: blue[600],
            },
          })}
        />
        <Box
          sx={{
            width: "100%",
            fontSize: "12px",
            marginTop: "5px",
          }}
        >
          Strict mode allows to navigate accross different time ranges with selected settings (parameters) for a
          browser, bidder or site for tracking dynamics of revenue lift, impressions, rates and etc.
        </Box>
      </Box>
      <Box sx={{ margin: "25px 0px" }}>
        <Button id="apply-btn" color="primary" variant="contained" onClick={clickSubmit} sx={stl.submit}>
          Apply
        </Button>
      </Box>
    </Box>
  );
}
