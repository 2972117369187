import React, { useEffect, useState } from "react";
import { Box, Typography, ButtonBase } from "@mui/material";
import { geoDropdown } from "./mockData";
import { validateFormData } from "./utils";
import CustomStepper from "../../components/CustomStepper/CustomStepper";
import { ConfigurationFormData } from "./interfaces";
import LabeledTextField from "./LabeledTextField";
import { SimpleDropdown } from "./SimpleDropdown";
import SegmentControl from "../../components/SegmentControl/SegmentControl";
import CustomRadioInputGroup from "./CustomRadioInput";
import CheckboxWrapper from "../../components/CustomCheckbox/Checkbox";
import {
  useCreatePartnersConfiguration,
  useGetAllBidders,
  useUpdatePartnersConfigurationById,
} from "./configurationsApi";

const steps = [
  { id: 0, name: "Metadata" },
  { id: 1, name: "Bidder Config" },
  { id: 2, name: "Traffic Shaping" },
];

interface BidderConfigurationFormProps {
  initialFormData?: ConfigurationFormData | null;
  onSubmit: () => void;
  partnerDummyId: string | null;
}

const defaultFormData: Partial<ConfigurationFormData> = {
  isServiceActive: 1,
  isBidderEnabled: 1,
  isTrafficShapingActive: 1,
  isRateLimitActive: 0,
  geoId: 1,
  partnerDummyId: "",
};

const BidderConfigurationForm: React.FC<BidderConfigurationFormProps> = ({
  initialFormData,
  onSubmit,
  partnerDummyId = "",
}) => {
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState<ConfigurationFormData>(
    initialFormData || ({ ...defaultFormData, partnerDummyId: partnerDummyId } as ConfigurationFormData)
  );
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [createPartnersConfiguration, { isLoading, data, error }] = useCreatePartnersConfiguration();
  const [updatePartnersConfigurationById] = useUpdatePartnersConfigurationById();
  const { data: allBidders } = useGetAllBidders();

  const handleStep = (stepIndex: number) => () => {
    const validationErrors = validateFormData(formData, activeStepIndex);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setActiveStepIndex(stepIndex);
    }
  };

  const handleNext = async () => {
    const validationErrors = validateFormData(formData, activeStepIndex);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      if (activeStepIndex < steps.length - 1) {
        setActiveStepIndex((prev) => prev + 1);
      } else {
        if (!initialFormData)
          createPartnersConfiguration(formData)
            .unwrap()
            .then(() => {
              alert("Configuration created successfully!");
              onSubmit();
            })
            .catch((error) => console.error("Failed to create configuration:", error));
        else {
          console.log(formData);
          updatePartnersConfigurationById({ data: formData, id: formData.id })
            .unwrap()
            .then(() => {
              alert("Configuration updated successfully!");
              onSubmit();
            })
            .catch((error) => console.error("Failed to updated configuration:", error));
        }
      }
    }
  };

  const handleInputChange = (field: keyof ConfigurationFormData, value: any) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [field]: "" })); // Clear error when user updates field
  };

  const handleIsServiceActive = (status: { id: number; name: string }) => {
    setFormData((prev) => ({
      ...prev,
      isServiceActive: status.id,
    }));
  };

  const handleIsBidderEnabledChange = (status: { id: number; name: string }) => {
    setFormData((prev) => ({
      ...prev,
      isBidderEnabled: status.id,
    }));
  };

  const handleTrafficShapingChange = (typeId: number, value: number) => {
    setFormData((prev) => ({
      ...prev,
      trafficShapingTriggerTypeId: typeId,
      trafficShapingValue: value,
    }));
  };

  useEffect(() => {
    setErrors({});
  }, [formData]);

  return (
    <CustomStepper
      steps={steps}
      activeStepIndex={activeStepIndex}
      nextDisabled={Object.keys(errors).length > 0}
      handleStepAction={handleNext}
      handleStep={handleStep}
      finalStepActionButtonTitle="Save"
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {activeStepIndex === 0 && (
          <>
            <SimpleDropdown
              label="Bidder"
              value={formData?.bidderId}
              onChange={(value) => handleInputChange("bidderId", value)}
              options={
                allBidders?.map((b) => {
                  return { id: b.id, name: b.name };
                }) || []
              }
              error={!!errors.bidderId}
              helperText={errors.bidderId}
            />
            <LabeledTextField
              label="Alias"
              value={formData.bidderAlias}
              onChange={(e) => handleInputChange("bidderAlias", e.target.value)}
              error={!!errors.bidderAlias}
              helperText={errors.bidderAlias}
            />
            <LabeledTextField
              label="Seat ID"
              value={formData.seatId}
              onChange={(e) => handleInputChange("seatId", e.target.value)}
              error={!!errors.seatId}
              helperText={errors.seatId}
            />
            <LabeledTextField
              label="Endpoint URL"
              value={formData.endpointUrl}
              onChange={(e) => handleInputChange("endpointUrl", e.target.value)}
              error={!!errors.endpointUrl}
              helperText={errors.endpointUrl}
            />
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography variant="body1Small600">Bidder status</Typography>
              <SegmentControl
                options={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                ]}
                value={{
                  id: formData.isBidderEnabled,
                  name: formData.isBidderEnabled === 1 ? "Active" : "Inactive",
                }}
                onChange={handleIsBidderEnabledChange}
              />
            </Box>
          </>
        )}

        {activeStepIndex === 1 && (
          <>
            <LabeledTextField
              label="Timeout (msec)"
              type="number"
              value={String(formData.timeout)}
              onChange={(e) => handleInputChange("timeout", +e.target.value)}
              error={!!errors.timeout}
              helperText={errors.timeout}
            />
            <LabeledTextField
              label="Floor Price ($CPM)"
              type="number"
              value={String(formData.bidFloorPrice)}
              onChange={(e) => handleInputChange("bidFloorPrice", +e.target.value)}
              error={!!errors.bidFloorPrice}
              helperText={errors.bidFloorPrice}
            />
            <Box
              sx={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
            >
              <Typography variant="body1Small600">Enrich with IDs</Typography>
              <SegmentControl
                options={[
                  { id: 1, name: "Active" },
                  { id: 0, name: "Inactive" },
                ]}
                value={{
                  id: formData.isServiceActive,
                  name: formData.isServiceActive === 1 ? "Active" : "Inactive",
                }}
                onChange={handleIsServiceActive}
              />
            </Box>
            <SimpleDropdown
              label="Geo"
              value={formData?.geoId}
              onChange={(value) => handleInputChange("geoId", value)}
              options={geoDropdown}
            />
          </>
        )}

        {activeStepIndex === 2 && (
          <>
            <LabeledTextField
              label="Rate Limit"
              type="number"
              value={formData.rateLimit?.toString() || ""}
              onChange={(e) => handleInputChange("rateLimit", e.target.value ? +e.target.value : null)}
              error={!!errors.rateLimit}
              helperText={errors.rateLimit}
              disabled={formData.isRateLimitActive === 1}
            />
            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start", gap: "16px" }}>
              <ButtonBase
                onClick={(e) => handleInputChange("isRateLimitActive", formData.isRateLimitActive === 1 ? 0 : 1)}
              >
                <CheckboxWrapper id={`rateLimit-select-all-checkbox`} checked={formData.isRateLimitActive === 1} />
                <Typography variant="body1Small400">No limit</Typography>
              </ButtonBase>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1Small600">Traffic Limit</Typography>

                <SegmentControl
                  options={[
                    { id: 1, name: "Active" },
                    { id: 0, name: "Inactive" },
                  ]}
                  value={{
                    id: formData.isTrafficShapingActive,
                    name: formData.isTrafficShapingActive === 1 ? "Enabled" : "Disabled",
                  }}
                  onChange={(e) => handleInputChange("isTrafficShapingActive", e.id)}
                />
              </Box>

              <CustomRadioInputGroup
                helperText={errors.trafficShapingValue}
                disabled={formData.isTrafficShapingActive === 0}
                items={[
                  {
                    label: "%",
                    value: "percentage",
                    inputValue: formData.trafficShapingValue || 0,
                    onValueChange: (val) => {
                      handleTrafficShapingChange(1, val);
                    },
                  },
                  {
                    label: "Bid responses",
                    value: "bidResponses",
                    inputValue: formData.trafficShapingValue || 0,
                    onValueChange: (val) => {
                      handleTrafficShapingChange(2, val);
                    },
                  },
                ]}
                selectedValue={formData.trafficShapingTriggerTypeId === 1 ? "percentage" : "bidResponses"}
                onRadioChange={(value) => {
                  handleInputChange("trafficShapingTriggerTypeId", value === "percentage" ? 1 : 2);
                  handleInputChange("trafficShapingValue", 0);
                }}
              />
            </Box>
            <Typography variant="body1Small600">Traffic Pause Trigger</Typography>
            <LabeledTextField
              placeholder="sec"
              label="Recovery after"
              value={formData.trafficShapingWindowSec ? String(formData.trafficShapingWindowSec) : ""}
              onChange={(e) =>
                handleInputChange("trafficShapingWindowSec", e.target.value === "" ? null : +e.target.value)
              }
              error={!!errors.trafficShapingWindowSec}
              helperText={errors.trafficShapingWindowSec}
            />
          </>
        )}
      </Box>
    </CustomStepper>
  );
};

export default BidderConfigurationForm;
