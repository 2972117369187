export const separateMatchedAndUnmatched = (arr1, arr2, fieldName) => {
  const matched = {};
  const unmatched = {};

  for (let obj1 of arr1) {
    const found = arr2.find((obj2) => obj1[fieldName] === obj2[fieldName]);
    if (found) {
      matched[obj1[fieldName]] = true;
    } else {
      unmatched[obj1[fieldName]] = false;
    }
  }

  for (let obj2 of arr2) {
    const found = arr1.find((obj1) => obj2[fieldName] === obj1[fieldName]);
    if (!found) {
      unmatched[obj2[fieldName]] = false;
    }
  }

  return { matched, unmatched };
};
