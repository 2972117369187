import React, { useState } from 'react'

export default function useOpenFilters({ elemRef, customId } = false) {
  const [anchorEl, setAnchorEl] = useState(null);
  // const [val, setVal] = useState(null);
  // const [coordinates, setCoordinates] = useState([0, 0]);

  const handleClick = (event) => {
    if (elemRef) {
      setAnchorEl(elemRef.current);
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getValueToEdit = (v) => {
  //   setVal(v)
  // };

  const open = Boolean(anchorEl);
  const id = open ? (customId ? customId : 'popover-filters') : (undefined);
  // const id = open ? 'popover-filters' : undefined;

  return {
    open,
    id,
    handleClick,
    handleClose,
    anchorEl,
    setAnchorEl,
    // getValueToEdit,
    // val
  }
}
