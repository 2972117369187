import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { getStats } from "../getStats";
import { callsFilter, resetStatusesAndErrors, setActiveFilters, setGetStatsBody } from "../advancedReportSlice";
import { useLocation } from "react-router-dom";
import { getVisitorRecognitionData } from "../../visitorRecognition/visitorRecognitionFetches";
import { CONSTANT } from "../../../../constants/constants";
import { Typography } from "@mui/material";
// import BasicModal from '../../../components/BasicModal/BasicModal';

const stl = {
  margin: "10px",
  padding: "2rem",
  width: "150px",
  border: "1px solid grey",
  borderRadius: "8px",
  textAlign: "center",
  fontSize: "1rem",
  cursor: "pointer",
};

export default function WarningConnectionIssue({ activeFilters }) {
  const { partnersSelected } = useSelector((state) => state.role);
  const { dateRange, customDateRange } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const location = useLocation();
  const dispatch = useDispatch();
  const abortController = new AbortController();
  const signal = abortController.signal;

  return (
    <Box sx={{ marginTop: "6rem", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Data is not fetched. Please, rerun the query or try to reconfigure query parameters with filters board.
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            let payload = {
              ...activeFilters,
            };
            if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
              if (activeFilters.platforms.length === 0 && activeFilters.calls.length === 0) {
                payload = { ...payload, [CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base]: [callsFilter[0]] };
                dispatch(setActiveFilters(payload));
              }
              if (activeFilters.platforms.length !== 0) {
                payload = { ...payload, [CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base]: [] };
                dispatch(setActiveFilters(payload));
              }
              dispatch(
                getVisitorRecognitionData({
                  requestData: {
                    ...payload,
                    timeGrouping: dateRange.value,
                    customDateRange: customDateRange,
                    partnersIds: partnersSelected.map((p) => p.dummy_id),
                  },
                  signal,
                }),
              );
            } else {
              dispatch(
                getStats({ ...activeFilters, timeGrouping: dateRange.value, customDateRange: customDateRange }),
              );
            }
          }}
          sx={{ m: 1 }}
        >
          Retry
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            dispatch(resetStatusesAndErrors());
            dispatch(setGetStatsBody(""));
          }}
          sx={{ m: 1 }}
        >
          Got it
        </Button>
      </Box>
    </Box>
  );
}
