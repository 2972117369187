import React, { useState, useEffect } from "react";
import { TextField, Box, Typography, Tabs, Tab, IconButton, InputAdornment } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { dataGridSx, pageContainerStyle } from "./styles";
import UserStatusChip from "../components/Chips/UserStatusChip";
import { useNavigate } from "react-router-dom";
import VirtualAccountChip from "../components/Chips/VirtualAccountChip";
import { CustomPagination } from "../core/performanceReports/advancedReport/table/pagination/CustomPagination";
import ScrollButton from "../components/ScrollButton/ScrollButtton";
import { BasicTooltip } from "../components/BasicTooltip/BasicTooltip";
import moment from "moment";
import { GridCellParams, MuiEvent } from "@mui/x-data-grid";
import {
  columnTablePanelStyle,
  mainTableStyle,
} from "../core/performanceReports/advancedReport/table/mainTableStyle";
import { CustomColumnMenuComponent } from "../core/performanceReports/advancedReport/table/MainTable";
import { tablesRowsAmountPerPageOptions } from "../parametersConstancy/parametersConstancyTypes";
import AccountStatusMenu from "./AccountStatusMenu";
import { useSelector } from "react-redux";
import { CONSTANT } from "../constants/constants";

export const CustomPaginationWrapper: React.FC<{ rowsPerPageOptions: number[] }> = ({ rowsPerPageOptions }) => {
  return (
    <Box sx={{ padding: "10px" }}>
      <CustomPagination rowsPerPageOptions={rowsPerPageOptions} />
    </Box>
  );
};

const defaultColumns = (
  showTrial: boolean,
  onStatusClick: (account: any, status: number, anchorEl: HTMLElement) => void,
  canCreateAccount: boolean,
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: "company",
      headerName: "Name",
      flex: 1.2,
      renderCell: (params: any) => (
        <div>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px", fontWeight: 600, color: "#131330" }}>
            <>{params.row.company}</>
            {params.row.virtualAccount === 1 && <VirtualAccountChip />}
          </Box>
          {!!params.row.parentAccountName ? (
            <Box sx={{ margin: "0px 0rem 5px", cursor: "pointer" }}>
              <BasicTooltip
                tooltipText={`${params.row.company} account is a part of ${params.row.parentAccountName}`}
                delay={1500}
              >
                <Typography
                  variant="span"
                  sx={{
                    padding: "0.1rem 0.275rem",
                    color: "#050921",
                    borderRadius: "6px",
                    background: "#cee2eb",
                    fontSize: "0.625rem",
                  }}
                >
                  {params.row.parentAccountName}
                </Typography>
              </BasicTooltip>
            </Box>
          ) : null}
        </div>
      ),
    },
    {
      field: "partners",
      headerName: "Partners",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lastUserLogin",
      headerName: "Last login",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <Box sx={{ margin: "0px 0rem 5px", cursor: "pointer" }}>
          <BasicTooltip tooltipText={`${params.row.lastUserLogin?.fullName || ""}`} delay={500}>
            <Typography variant="span">
              {params.row.lastUserLogin.date
                ? moment(params.row.lastUserLogin.date).format("DD.MM.YYYY | HH:mm")
                : "-"}
            </Typography>
          </BasicTooltip>
        </Box>
      ),
      sortComparator: (v1, v2) => {
        return new Date(v1.date).getTime() - new Date(v2.date).getTime();
      },
    },
    {
      field: "usersAmount",
      headerName: "Users",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => (
        <Box
          onClick={(event: React.MouseEvent<HTMLElement>) => {
            onStatusClick(params.row, params.row, event.currentTarget);
            if (canCreateAccount) event.stopPropagation();
          }}
        >
          <UserStatusChip status={params.row.status} />
        </Box>
      ),
      sortComparator: (v1, v2) => (v1 > v2 ? 1 : -1),
    },
    {
      field: "creationDate",
      headerName: "Creation date",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params: any) => {
        return moment(params.row.creationDate).format("DD.MM.YYYY | HH:mm");
      },
    },
    {
      field: "createdBy",
      headerName: "Created by",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
  ];
  if (showTrial) {
    columns.push(
      {
        field: "trialStartDate",
        headerName: "Trial Start Date",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params: any) => {
          return moment(params.row.trialStartDate).format("DD.MM.YYYY");
        },
      },
      {
        field: "trialEndDate",
        headerName: "Trial End Date",
        flex: 1,
        headerAlign: "center",
        align: "center",
        renderCell: (params: any) => {
          return moment(params.row.trialEndDate).format("DD.MM.YYYY");
        },
      },
    );
  }
  return columns;
};

interface IAccountsTable {
  filteredAccounts: Array<any>;
  showTrial?: boolean;
}

export const AccountsTable: React.FC<IAccountsTable> = ({ filteredAccounts, showTrial = false }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAccount, setSelectedAccount] = useState<any>(null);
  const [selectedStatus, setSelectedStatus] = useState<number | null>(null);
  const { editedUser, loggedUser } = useSelector((state: any) => state.users);

  const handleRowClick = (params: any) => {
    if (params.field === "usersAmount") {
      navigate(`/accounts/users/${params.row.id}`);
      return;
    }
    if (params.field === "lastUserLogin" && params.row.lastUserLogin.id) {
      navigate(`/accounts/user_details/${params.row.lastUserLogin.id}`);
      return;
    }
    if (params.field === "createdBy" && params.row.lastUserLogin.id) {
      navigate(`/accounts/user_details/${params.row.createdById}`);
      return;
    }
    navigate(`/accounts/${params.row.id}`);
  };
  const handleStatusClick = (account: any, status: number, anchorEl: HTMLElement) => {
    if (loggedUser.canCreateAccount && +account !== CONSTANT.IIQ_ACCOUNT_ID) {
      setSelectedAccount(account);
      setSelectedStatus(status);
      setAnchorEl(anchorEl);
    }
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedAccount(null);
    setSelectedStatus(null);
  };

  return (
    <Box id="table-container" sx={{ ...mainTableStyle(), width: "100%" }}>
      <DataGrid
        key={"userDetailsDataGrid"}
        rowHeight={36}
        rows={filteredAccounts}
        columns={defaultColumns(showTrial, handleStatusClick, loggedUser.canCreateAccount)}
        autoHeight={true}
        hideFooterPagination={false}
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
        }}
        sx={dataGridSx}
        // onRowClick={(params: any) => handleRowClick(params, params.row.id)}
        onCellClick={(params: GridCellParams, event: MuiEvent<React.MouseEvent>) => {
          event.defaultMuiPrevented = true;
          handleRowClick(params);
        }}
        disableRowSelectionOnClick
        slots={{
          footer: () => <CustomPaginationWrapper rowsPerPageOptions={tablesRowsAmountPerPageOptions} />,
          columnMenu: CustomColumnMenuComponent,
        }}
        slotProps={{
          panel: {
            sx: columnTablePanelStyle,
          },
        }}
      />
      <AccountStatusMenu
        account={selectedAccount}
        currentStatus={selectedStatus}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        isVirtual={selectedAccount?.virtualAccount === 1}
      />
      <ScrollButton />
    </Box>
  );
};
