export const defaultTextFieldStyle = {
  paddingLeft: "4px",
  height: "48px",
  "& .MuiInputBase-root": {
    height: "48px",
    border: "none",
    borderRadius: "0",
    "& > fieldset": {
      height: "48px",
      top: "0px",
      border: "none",
    },
    "& .MuiInputBase-root.Mui-focused": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    height: "48px",
  },
  input: {
    height: "48px",
    fontSize: "16px",
    "&::placeholder": {
      opacity: 0.3,
    },
  },
  "& .MuiInputAdornment-root": {
    fontSize: "24px", // Set icon size
  },
};

export const listStl = {
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "1rem",
};

export const listItemStl = (selected) => {
  return {
    padding: "0px",
    color: selected ? "#1BB2FF" : "#000",
    backgroundColor: "white",
    alignItems: "left",
    height: "auto",
    marginBottom: "12px",
  };
};
