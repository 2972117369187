export const handleReset = (setReset) => {
  setReset(current => !current);
};

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1);
}

export function getLastDayOfMonth(year, month) {
  return new Date(year, month + 1, 0);
}

export const monthPickChange = (item, setDateRange) => {
  let fd = getFirstDayOfMonth(item.start.year, item.start.month);
  let ld = getLastDayOfMonth(item.end.year, item.end.month);

  let newVal = [
    {
      startDate: fd,
      endDate: ld,
      key: "selection"
    }
  ];
  setDateRange(newVal);
};