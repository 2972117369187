import React from 'react'

export default function DefaultIcon({ x = 0, y = 0, width = "26", height = "26", text = "A", title = "", fill = "rgba(0, 56, 93, 1)" }) {
  return (
    <svg width={width} height={height} viewBox="-2 0 33 33" fill="none" x={x} y={y}>
      <title>{title}</title>
      <circle cx="14" cy="14" r="13" fill={fill} />
      <text x="42%" y="58%" textAnchor="middle" stroke="#fff" strokeWidth="1" >{text}</text>
    </svg>
  )
}
