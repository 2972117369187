import { Box, Typography, Avatar, ButtonBase } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import UserStatusChip from "../../components/Chips/UserStatusChip";
import ActionItem, { ActionType } from "../../components/ActionItemsEditDelete/ActionItem";
import { IBidderConfigKey } from "./ConfigurationPage";
import moment from "moment";

interface ColumnsModelProps {
  onEdit: (id: number) => void;
  onDelete: (id: number) => void;
  onServiceActiveStatusClick: (
    configId: any,
    status: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => void;
  onBidderenabledStatusClick: (
    configId: any,
    status: number,
    statusKey: IBidderConfigKey,
    anchorEl: HTMLElement
  ) => void;
}

export const columnsModel = ({
  onEdit,
  onDelete,
  onServiceActiveStatusClick,
  onBidderenabledStatusClick,
}: ColumnsModelProps): GridColDef[] => [
  { field: "bidderName", headerName: "Bidder Name", flex: 1 },
  { field: "bidderAlias", headerName: "Alias", flex: 1 },
  { field: "endpointUrl", headerName: "Endpoint URL", flex: 1 },
  { field: "seatId", headerName: "Seat ID", flex: 1 },
  { field: "timeout", headerName: "Timeout", flex: 1 },
  { field: "decorationType", headerName: "Decoration Type", flex: 1 },
  { field: "rateLimit", headerName: "Rate Limit (QPS)", flex: 1, valueFormatter: (v) => v.value || "Don’t limit" },
  {
    field: "isServiceActive",
    headerName: "Enrich with IDs",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <ButtonBase
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          onServiceActiveStatusClick(
            params.row.id,
            params.row.isBidderEnabled,
            "isServiceActive",
            event.currentTarget
          );
          event.stopPropagation();
        }}
      >
        <UserStatusChip status={params.value} />
      </ButtonBase>
    ),
  },
  {
    field: "isBidderEnabled",
    headerName: "Bidder Status",
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <ButtonBase
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          onBidderenabledStatusClick(
            params.row.id,
            params.row.isBidderEnabled,
            "isBidderEnabled",
            event.currentTarget
          );
          event.stopPropagation();
        }}
      >
        <UserStatusChip status={params.value} />
      </ButtonBase>
    ),
  },
  {
    field: "updatedAt",
    headerName: "Last Modified",
    flex: 1.5,
    renderCell: (params: GridRenderCellParams) => (
      <Box display="flex" alignItems="center">
        {params.row.avatarUrl && (
          <Avatar alt="Last Modified By" src={params.row.avatarUrl} sx={{ width: 24, height: 24, mr: 1 }} />
        )}
        {params.value && (
          <Typography variant="body2">{`${moment(params.value).format("DD.MM.YYYY")}, ${
            params.row.updatedBy
          }`}</Typography>
        )}
      </Box>
    ),
  },

  {
    field: "actions",
    headerName: "Actions",
    flex: 1,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => (
      <Box display="flex" alignItems="center">
        <ActionItem actionType={ActionType.Edit} onAction={() => onEdit(params.row.id)} />
        <ActionItem actionType={ActionType.Delete} onAction={() => onDelete(params.row.id)} />
      </Box>
    ),
  },
];
