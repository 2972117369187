import { createSlice } from "@reduxjs/toolkit";
import { CONSTANT } from "../constants/constants";
import { daysAgoSinceStartDate, getMTD } from "../utils";

const RANGE_DATE_YESTERDAY = CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value * 2;
const PRE_LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;
const MTD = getMTD() * 2;
const YTD = daysAgoSinceStartDate() * 2;
const initialState = {
  [RANGE_DATE_YESTERDAY]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  14: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [MTD]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [PRE_LAST_MONTH]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [YTD]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [-1]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  processing: false,
  status: "idle",
};

export const savedRawDataPreviousPeriodSlice = createSlice({
  name: "savedRawDataPreviousPeriod",
  initialState,
  reducers: {
    setSavedRawDataType(state, action) {
      let { activeRangeDate, filterBase, result, rangeTypeConstancy } = action.payload;
      if (rangeTypeConstancy) {
        if (
          (rangeTypeConstancy.type === CONSTANT.DATE_RANGE_TYPES.YTD.type &&
            YTD !== rangeTypeConstancy.value * 2) ||
          (rangeTypeConstancy.type === CONSTANT.DATE_RANGE_TYPES.MTD.type && MTD !== rangeTypeConstancy.value * 2)
        ) {
          const newMTD = getMTD() * 2;
          const newYTD = daysAgoSinceStartDate() * 2;
          state[newMTD] = {
            client_type: [],
            biddercode: [],
            site_id: [],
            placement_id: [],
          };
          state[newYTD] = {
            client_type: [],
            biddercode: [],
            site_id: [],
            placement_id: [],
          };
          delete state[YTD];
          delete state[MTD];
        }
      } else if (!(activeRangeDate in state)) {
        state[activeRangeDate] = {
          client_type: [],
          biddercode: [],
          site_id: [],
          placement_id: [],
        };
      }
      state[activeRangeDate][filterBase] = result.data;
      state.status = "succeeded";
    },
    resetSavedRawDataPreviousPeriodState(state, action) {
      for (let i in state) {
        if (typeof state[i] == "object") {
          state[i].client_type = [];
          state[i].biddercode = [];
          state[i].site_id = [];
          state[i].placement_id = [];
        }
      }
    },
  },
});

export const { setSavedRawDataType, resetSavedRawDataPreviousPeriodState } =
  savedRawDataPreviousPeriodSlice.actions;

export default savedRawDataPreviousPeriodSlice.reducer;
