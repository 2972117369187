import { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./custom-date-range.css"; //custom css file of calendar of "react-date-range"
import { Box, Tooltip, Button, Checkbox } from "@mui/material";
import { setDays, prepareDateFormat } from "../../../../utils";
import { singleBtn, activeBtn, stylesWidget } from "../../../overview/styles";
import { getStats } from "../getStats";
import { MonthPicker } from "../../../../components/DatePicker/MonthPicker";
import { handleReset, monthPickChange } from "../../../../components/DatePicker/monthPicker-utils";
import { resetSavedRawDataState } from "../../../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetSummaryChartRecords } from "../../../../summary/summarySlice";
import { resetMainTableData, resetVRTData } from "../advancedReportSlice";
import { resetRawPctAndRawCurrencyIndicators, resetSummaryCompleteDate } from "../../../../report/reportSlice";
import Fade from "@mui/material/Fade";
import { CONSTANT } from "../../../../constants/constants";
import { useLocation } from "react-router-dom";
import { getVisitorRecognitionData } from "../../visitorRecognition/visitorRecognitionFetches";
import { validateSelection } from "../../../../components/DatePicker/datePickerValidator";
import { setCurrentCommonParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import { adjustForTimezone } from "../../../../components/DatePicker/DatePicker";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  styleDatePicker,
  adaptStyleDatePicker,
  styleDatePickerFooter,
  styleDatePickerBtn,
  styleMonthPickerWrapper,
  adaptColorBtn,
} from "./dateRangeStyle";

export default function ExtendedDatePicker(props) {
  const matches = useMediaQuery("(min-width:1280px)");
  const { filters } = useSelector((state) => state.advancedReport);
  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)]),
  );
  const { customDateRange, dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const timeGrouping = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value,
  );
  const { partnersSelected } = useSelector((state) => state.role);

  const defaultEndDate = new Date();
  const defaultStartDate = setDays(defaultEndDate, -2);
  const contRef = useRef(null);
  const refModal = useRef(null);
  const selectInnerRef = useRef(dateGroupingMode);
  const selectSliceRef = useRef(dateGroupingMode);
  const warnRef = useRef(false);
  const location = useLocation();

  const [openDate, setOpenDate] = useState(false);
  const [reset, setReset] = useState(true);
  const [showWarn, setShowWarn] = useState(false);
  const [warnMessage, setWarnMessage] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: !!customDateRange.customDateStart
        ? adjustForTimezone(customDateRange.customDateStart)
        : defaultStartDate,
      endDate: !!customDateRange.customDateEnd ? adjustForTimezone(customDateRange.customDateEnd) : defaultEndDate,
      key: "selection",
    },
  ]);
  const [checked, setChecked] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    /**
     * close datepicker if clicked on outside of element (on blured bg)
     */

    const handleClickOutside = (event) => {
      if (warnRef.current) {
        setShowWarn(false);
        warnRef.current = false;
      }
      if (
        refModal.current &&
        (!refModal.current.contains(event.target) || !contRef.current.contains(event.target))
      ) {
        if (selectInnerRef.current !== selectSliceRef.current) props.setGroupingMode(selectSliceRef.current);
        setOpenDate(false);
      }
    };

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [refModal]);

  useEffect(() => {
    selectInnerRef.current = dateGroupingMode;
    selectSliceRef.current = dateGroupingMode;
  }, [dateGroupingMode]);

  useEffect(() => {
    if (!matches) {
      setChecked(true);
      setOpenDate(false);
    }
  }, [matches]);

  const handleOpenClick = (savedDatesRange) => {
    setOpenDate(!openDate);
    //if custom date is active - show selected custom dates
    //else set preselected to store
    if (timeGrouping === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) return;

    // const { startDate, endDate } = dateRange[0];

    // let payload = {
    //   customDateRange: {
    //     customDateStart: prepareDateFormat(startDate),
    //     customDateEnd: prepareDateFormat(endDate),
    //   },
    // };
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleApplyClick = () => {
    let { isValid, warnMessage } = validateSelection(dateGroupingMode, dateRange);
    setWarnMessage(warnMessage);
    if (!isValid) {
      warnRef.current = true;
      setShowWarn(true);
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    setOpenDate(false);
    const { startDate, endDate } = dateRange[0];

    const customDateRange = {
      customDateStart: prepareDateFormat(startDate),
      customDateEnd: prepareDateFormat(endDate),
    };

    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("customDateRange", customDateRange)));
    dispatch(
      setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateRange", CONSTANT.DATE_RANGE_TYPES.CUSTOM)),
    );
    if (props.dateGroupingMode !== dateGroupingMode) {
      // apply time group mode if it was changed
      dispatch(
        setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", props.dateGroupingMode)),
      );
    }
    if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
      dispatch(resetVRTData());

      dispatch(
        getVisitorRecognitionData({
          requestData: {
            ...activeFilters,
            timeGrouping: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
            customDateRange: customDateRange,
            partnersIds: partnersSelected.map((p) => p.dummy_id),
          },
          signal,
        }),
      );
    } else {
      //and reset all saved raw datas
      dispatch(resetSummaryCompleteDate());
      dispatch(resetSavedRawDataState());
      dispatch(resetSavedRawDataPreviousPeriodState());
      dispatch(resetRawPctAndRawCurrencyIndicators());
      dispatch(resetSummaryChartRecords());
      dispatch(resetMainTableData());
      // dispatch(resetTimeRangeAndSelectionBase());
      // dispatch(resetSelectionBaseRawData());

      dispatch(
        getStats({
          ...activeFilters,
          timeGrouping: CONSTANT.DATE_RANGE_TYPES.CUSTOM.value,
          customDateRange: customDateRange,
          partnersIds: partnersSelected,
        }),
      );
    }
  };

  return (
    <div className="lsItem" style={{ position: "relative" }}>
      <BasicTooltip tooltipText="Choose a date range…">
        <Button
          id={`${props.id ? props.id : "date-picker"}-btn`}
          style={props.isActiveButton ? activeBtn : null}
          disabled={props.isDisabled}
          sx={{ ...singleBtn, ...adaptColorBtn(openDate) }}
          onClick={() => handleOpenClick(customDateRange)}
        >
          Custom
        </Button>
      </BasicTooltip>
      {openDate && (
        <div style={{ overflow: "hidden" }}>
          <div ref={contRef}>
            <Fade in={true} timeout={1000}>
              <div
                id="date-picker-container"
                style={{
                  ...styleDatePicker,
                  ...adaptStyleDatePicker(checked, matches),
                }}
                ref={refModal}
              >
                <div style={{ display: "flex" }}>
                  <div style={styleMonthPickerWrapper}>
                    <MonthPicker
                      className="MonthPicker"
                      initialRange={{
                        // start: { year: 2023, month: 4 },
                        // end: { year: 2023, month: 6 }
                        start: {
                          year: null,
                          month: null,
                        },
                        end: {
                          year: null,
                          month: null,
                        },
                      }}
                      onChange={(selectedRange) => {
                        monthPickChange(selectedRange, setDateRange);
                      }}
                      onReset={reset}
                      darkMode={false}
                    />
                  </div>
                  <DateRange
                    maxDate={new Date()}
                    editableDateInputs={true}
                    onChange={(item) => {
                      handleReset(setReset);
                      setDateRange([item.selection]);
                    }}
                    moveRangeOnFirstSelection={false}
                    ranges={dateRange}
                    direction={"horizontal"}
                    months={checked ? 1 : 2}
                  />
                  {/* date group mode buttons */}
                </div>
                <div style={styleDatePickerFooter}>
                  <Button id="date-picker-reset-month-btn" type="button" onClick={() => handleReset(setReset)} sx={{ marginRight: "2rem" }}>
                    Reset months
                  </Button>
                  <Button
                    id="date-picker-cancel-btn"
                    sx={{ ...singleBtn, ...styleDatePickerBtn }}
                    type="button"
                    className="btn"
                    onClick={() => setOpenDate(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="date-picker-apply-btn"
                    sx={{ ...singleBtn, ...styleDatePickerBtn }}
                    type="button"
                    className="btn"
                    onClick={handleApplyClick}
                  >
                    Apply
                  </Button>

                  <BasicTooltip tooltipText={checked ? "Expand calendar" : "Show only one month"} delay={1500}>
                    <Box>
                      <Checkbox
                        id="date-picker-expand-checkbox"
                        sx={stylesWidget.tickSize}
                        checked={checked}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Box>
                  </BasicTooltip>
                </div>
                {showWarn ? (
                  <Fade
                    in={true}
                    // style={{ transformOrigin: '0 0 0' }}
                    timeout={1300}
                  >
                    <Box
                      sx={{
                        padding: "15px",
                        fontSize: "1rem",
                        color: "coral",
                        width: "75%",
                      }}
                    >
                      {warnMessage}
                    </Box>
                  </Fade>
                ) : null}
              </div>
            </Fade>
          </div>
        </div>
      )}
    </div>
  );
}
