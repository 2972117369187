import { createAsyncThunk } from "@reduxjs/toolkit";

export const applyPctAndCurrencyToSummaryChart = createAsyncThunk(
  "summary/applyPctAndCurrencyToSummaryChart",
  async (pct, { rejectWithValue, dispatch, getState }) => {
    let currentState = getState();
    let dataRaw = currentState.summary.dataRaw;
    let ct = currentState.report.active_chart;
    let rt =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value;
    let dgm =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode;
    let dataPctCurrency = pct.split("-");

    let data = {
      result: dataRaw,
      activeChart: ct,
      activeRangeDate: rt,
      dateGroupingMode: dgm,
      reportSelectedCurrency: dataPctCurrency[0],
      reportSelectedPct: +dataPctCurrency[1],
      // reportSelectedPct: pct,
      settingsStrictMode: currentState.report.settingsStrictMode,
    };

    // if ("setFilterBase" in report) data.filterBase = currentState.report.setFilterBase;
    if (rt === -1)
      data.customDateRange =
        currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange;

    return data;
  },
);
