import React from "react";
import { Button, Box } from "@mui/material";

interface FileUploadProps {
  handleFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleButtonClick: () => void;
  handleRemovePhoto: () => void;
  selectedFileName: string;
  fileUploadError: string;
}

const FileUploadComponent = React.forwardRef<HTMLInputElement, FileUploadProps>(
  ({ handleFileChange, handleButtonClick, handleRemovePhoto, selectedFileName, fileUploadError }, ref) => {
    return (
      <Box
        id='file-uploader'
        sx={{
          border: selectedFileName !== "" ? null : "1px solid lightblue",
          borderRadius: "4px",
          padding: "12px",
          textAlign: "center",
        }}
      >
        {selectedFileName != "" ? (
          <Box
            id='file-name'
            onClick={handleRemovePhoto}
            sx={{
              cursor: "pointer",
              display: "inline-block",
              border: "1px solid darkgrey",
              padding: "4px 15px",
              borderRadius: "4px",
              background: "#f0f0f0",
              margin: "10px 0",
            }}
          >
            {selectedFileName}
          </Box>
        ) : (
          <Box>
            <input
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: "none" }}
              id="file-input"
              type="file"
              name="photo"
              ref={ref}
            />
            <Button id='upload-logo-btn' onClick={handleButtonClick} component="span">
              Upload logo..
            </Button>
            <span id='selected-file-name' style={{ marginLeft: "10px" }}>{selectedFileName}</span>
            <br />
          </Box>
        )}
        {!!fileUploadError && <Box id='upload-error' sx={{ color: "red" }}>{fileUploadError}</Box>}
      </Box>
    );
  }
);

export default FileUploadComponent;
