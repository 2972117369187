import React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

const mainMessage: string = "Thank you for taking the time to complete our survey!";
const additionalMessage: string = "Your feedback is invaluable to us and helps us improve our services.";
const ConfirmSend = () => {
  return (
    <Box id="confirm-send-modal">
      <Box sx={{ display: "flex", justifyContent: "center", marginBottom: "1.5rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            fontSize: "1rem",
            border: "6px solid #84DAFF",
            borderRadius: "50%",
            width: "57px",
            height: "57px",
            background: "#1BB2FF",
          }}
        >
          <Box sx={{ alignSelf: "center" }}>
            <svg width="23" height="16" viewBox="0 -1 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.53526 14.952C7.35384 15.1344 7.10646 15.2362 6.84937 15.2362C6.59229 15.2362 6.34491 15.1344 6.16349 14.952L0.686138 9.47365C0.117642 8.90516 0.117642 7.98353 0.686138 7.41601L1.37202 6.73012C1.94052 6.16163 2.86117 6.16163 3.42967 6.73012L6.84937 10.1498L16.0899 0.909343C16.6584 0.340847 17.58 0.340847 18.1475 0.909343L18.8334 1.59523C19.4019 2.16372 19.4019 3.08535 18.8334 3.65287L7.53526 14.952Z"
                fill="white"
              />
            </svg>
          </Box>
        </Box>
      </Box>
      <Box sx={{ fontSize: "1.5rem", fontWeight: 600, marginBottom: "1.5rem" }}>
        <Typography id="main-message" variant="h1XLargeDark800">{mainMessage}</Typography>
      </Box>
      <Box sx={{ fontSize: "1rem", fontWeight: 400, marginBottom: "1.5rem" }}>
        <Typography id="additional-message" variant="body1Small400">{additionalMessage}</Typography>
      </Box>
    </Box>
  );
};

export default ConfirmSend;
