import { colors } from "../../theme";

interface IconProps {
  color?: string;
}
const PointIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? colors.brandDefault;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M12 18C10.3286 18 8.91071 17.4179 7.74643 16.2536C6.58214 15.0893 6 13.6714 6 12C6 10.3286 6.58214 8.91071 7.74643 7.74643C8.91071 6.58214 10.3286 6 12 6C13.6714 6 15.0893 6.58214 16.2536 7.74643C17.4179 8.91071 18 10.3286 18 12C18 13.6714 17.4179 15.0893 16.2536 16.2536C15.0893 17.4179 13.6714 18 12 18Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default PointIcon;
