interface IconProps {
  color?: string;
}

const ClearAllFiltersIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color || "#949DB4";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.3 5.70971C18.1131 5.52246 17.8595 5.41723 17.595 5.41723C17.3305 5.41723 17.0768 5.52246 16.89 5.70971L12 10.5897L7.10997 5.69971C6.92314 5.51246 6.66949 5.40723 6.40497 5.40723C6.14045 5.40723 5.8868 5.51246 5.69997 5.69971C5.30997 6.08971 5.30997 6.71971 5.69997 7.10971L10.59 11.9997L5.69997 16.8897C5.30997 17.2797 5.30997 17.9097 5.69997 18.2997C6.08997 18.6897 6.71997 18.6897 7.10997 18.2997L12 13.4097L16.89 18.2997C17.28 18.6897 17.91 18.6897 18.3 18.2997C18.69 17.9097 18.69 17.2797 18.3 16.8897L13.41 11.9997L18.3 7.10971C18.68 6.72971 18.68 6.08971 18.3 5.70971Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default ClearAllFiltersIcon;
