import React from 'react';
import Popover from '@mui/material/Popover';

const BasicPopover = (props) => {
  let {
    transformOriginVertical = "top",
    transformOriginHorizontal = "right",
    popoverWidth = "inherit",
    additionalStyles = {},
    position = {}
  } = props;

  const handleClose = () => {
    props.closePopover();
    // props.setAnchorEl(null);
  };

  const open = Boolean(props.anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Popover
      sx={{
        '& .MuiPopover-paper': {
          width: popoverWidth,
          padding: '1rem',
          borderRadius: '1.4rem',
          boxShadow: '0 .4rem .8rem 0 rgba(0, 56, 93, .1)',
          ...additionalStyles
        }
      }}
      id={id}
      open={open}
      anchorEl={props.anchorEl}
      onClose={handleClose}
      anchorReference={Boolean(Object.keys(position).length) ? "anchorPosition" : null}
      anchorPosition={Boolean(Object.keys(position).length) ? { top: props.position.y || 0, left: props.position.x || 0 } : null}
      // anchorPosition={{ top: props.position.y || 0, left: props.position.x || 0 }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
    // transformOrigin={{
    //   vertical: transformOriginVertical,
    //   horizontal: transformOriginHorizontal,
    // }}
    >
      {props.children}
    </Popover>
  );
}

export default BasicPopover;