import { CONSTANT } from "../../constants/constants";

const { MODULE_CONFIGURATIONS } = CONSTANT;
const MODULE_IDS = {
  IIQ: [MODULE_CONFIGURATIONS.IIQAccountManagerLimit, MODULE_CONFIGURATIONS.IIQUser],
  ConfigurationAdmin: [MODULE_CONFIGURATIONS.IIQAccountManager, MODULE_CONFIGURATIONS.IIQConfigManager],
  Default: [
    MODULE_CONFIGURATIONS.DefaultPartnersUser,
    MODULE_CONFIGURATIONS.DefaultPartnersAccount,
    MODULE_CONFIGURATIONS.DefaultPartnersPartner,
  ],
};

function getModules(loggedUser, targetUser) {
  const isLoggedIIQ = loggedUser.account_id === CONSTANT.IIQ_;
  const isTargetIIQ = targetUser.account_id.id === CONSTANT.IIQ_ACCOUNT_ID;
  const showAllModules = isLoggedIIQ && loggedUser.configurationAdministrator === 1;
  const isLoggedIIQConfigManager = !!loggedUser.configurationAdministrator;
  const isTargetIIQConfigManager = targetUser.modulesConfigurationId === MODULE_CONFIGURATIONS.IIQConfigManager.id;
  const isSameUser = loggedUser.id === targetUser.id;

  if (showAllModules && isTargetIIQ) {
    return { text: "all modules list", modules: Object.values(MODULE_CONFIGURATIONS), allowEdit: 1 };
  }

  if (isSameUser) {
    if (isLoggedIIQConfigManager) {
      return { text: "all modules list", modules: Object.values(MODULE_CONFIGURATIONS), allowEdit: 1 };
    } else {
      return {
        text: "This profile module configuration can not be changed, contact to module configuration manager",
        modules: [],
        allowEdit: 0,
      };
    }
  }

  if (isLoggedIIQ && !isLoggedIIQConfigManager && isTargetIIQConfigManager) {
    return {
      text: "This profile module configuration can not be changed, contact to module configuration manager",
      modules: [],
      allowEdit: 0,
    };
  }

  if (isLoggedIIQ && isTargetIIQ) {
    let modules = [];
    if (loggedUser.canCreateAccount) {
      if (loggedUser.apt == 1) {
        modules = [
          MODULE_CONFIGURATIONS.IIQAccountManagerLimit,
          MODULE_CONFIGURATIONS.IIQAccountManager,
          MODULE_CONFIGURATIONS.IIQUser,
        ];
      } else if (loggedUser.apt == 0) {
        modules = [MODULE_CONFIGURATIONS.IIQAccountManagerLimit, MODULE_CONFIGURATIONS.IIQUser];
      }
      return { text: "equal or less", modules, allowEdit: 1 };
    } else {
      return { text: "equal or less", modules: [MODULE_CONFIGURATIONS.IIQUser], allowEdit: 1 };
    }
  }

  return isLoggedIIQ && isTargetIIQ
    ? { text: "list for iiq", modules: MODULE_IDS.IIQ, allowEdit: 1 }
    : { text: "list for default", modules: MODULE_IDS.Default, allowEdit: 1 };
}

export { getModules };
