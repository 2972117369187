export default function ColumnsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 19.7534C11.1716 19.7534 10.5 19.0818 10.5 18.2534L10.5 5.74683C10.5 4.9184 11.1716 4.24683 12 4.24683C12.8284 4.24683 13.5 4.9184 13.5 5.74683L13.5 18.2534C13.5 19.0818 12.8284 19.7534 12 19.7534Z"
        fill="#101D41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.05469 19.7534C5.22626 19.7534 4.55469 19.0818 4.55469 18.2534L4.55469 5.74683C4.55469 4.9184 5.22626 4.24683 6.05469 4.24683C6.88311 4.24683 7.55469 4.9184 7.55469 5.74683L7.55469 18.2534C7.55469 19.0818 6.88311 19.7534 6.05469 19.7534Z"
        fill="#101D41"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9453 19.7534C17.1169 19.7534 16.4453 19.0818 16.4453 18.2534L16.4453 5.74683C16.4453 4.9184 17.1169 4.24683 17.9453 4.24683C18.7737 4.24683 19.4453 4.9184 19.4453 5.74683L19.4453 18.2534C19.4453 19.0818 18.7737 19.7534 17.9453 19.7534Z"
        fill="#101D41"
      />
    </svg>
  );
}
