import React from "react";
import Table, { ColumnType, ColumnsType, TablePaginationConfig, TableProps } from "antd/es/table";
import { SearchOutlined } from "@mui/icons-material";
import { Input, InputRef, Popover, Tag } from "antd";
import type { FilterConfirmProps, FilterValue, TableRowSelection } from "antd/es/table/interface";
import { Box, Button as ButtonMUI, Typography } from "@mui/material";
import "./billingTableStyle.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { HandleGenerateReportType, TablePropsParamsType } from "./BillingTable";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { CONSTANT } from "../../constants/constants";
import getSymbolFromCurrency from "currency-symbol-map";

export type DataType = {
  key: React.Key;
  partnerId: string;
  partner: string;
  account: string;
  amount: number;
  currency: string;
  pricePlan: string;
  period: string;
  dateOfLastInvoice: Date;
  dateOfRegistration: Date;
};

export type DataIndex = keyof DataType;

export type TablePaginationPosition = NonNullable<TablePaginationConfig["position"]>[number];
export const steps = [5, 10, 25, 50, 100, 200];

const colorsMap: Record<string, string> = {};
function getColorByIndex(index: number): string {
  const colors: Record<number, string> = {
    1: "#E0F7FF",
    2: "#C0C7CF",
    3: "#DCFCEB",
    4: "#FEE0E2",
    5: "#FBE9FF",
    6: "#FFFBE6",
    7: "#FFEFE2",
    8: "#BDF6EC",
  };
  return colors[index] || "darkred";
}

const keyColorMap: Record<string, string> = {
  partner: "#EFF0F2",
  account: "#EFF0F2",
  amount: "#EFF0F2",
  currency: "#EFF0F2",
  pricePlan: "#EFF0F2",
  period: "#EFF0F2",
  dateOfLastInvoice: "#EFF0F2",
  dateOfRegistration: "#EFF0F2",
};

interface TableStructureProps {
  searchInput: React.RefObject<InputRef>;
  handleSearch: (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex,
  ) => void;
  handleReset: (clearFilters: () => void) => void;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  setSearchedColumn: React.Dispatch<React.SetStateAction<string>>;
  searchedColumn: string;
  pricePlans: Set<string>;
  periods: Set<string>;
  currencies: Set<string>;
  currentFilters: Record<string, FilterValue | null>;
}

export const tableProps = ({
  paginationConfig,
  handleGenerateReport,
  currentFilters,
  handleResetFilter,
  expandedSummary,
  setExpandedSummary,
  billingInfoIsLoading,
}: TablePropsParamsType): TableProps<DataType> => ({
  rowKey: (record) => record.partnerId + record.period + record.currency,
  pagination: setPaginationConfig(paginationConfig),
  // bordered: true,
  size: "small",
  // expandable: defaultExpandable,
  // showHeader: true,
  title: () => setTitle(handleGenerateReport, currentFilters, handleResetFilter),
  summary: (pageData) => setSummary(pageData, expandedSummary, setExpandedSummary),
  // footer,
  // rowSelection: defaultRowSelection,
  loading: setLoading(billingInfoIsLoading),
  scroll: { y: 700 },
  style: { minWidth: "1000px" },
});

const setPaginationConfig = (paginationConfig: any): TablePaginationConfig => {
  const pageSizeOptions = determinePageSizeOptions(paginationConfig.dataLength);
  const pageSize = updatePageSize(pageSizeOptions, paginationConfig.currentPageSize);
  return {
    style: { paddingRight: "20px" },
    // current: 1,
    total: paginationConfig.dataLength,
    defaultPageSize: 25,
    // pageSize,
    // hideOnSinglePage: true,
    pageSizeOptions: steps,
    showSizeChanger: true,
  };
};

const determinePageSizeOptions = (dataLength: number): number[] => {
  const options = steps.filter((step) => step <= dataLength);
  if (dataLength < steps[steps.length - 1] && dataLength !== options[options.length - 1]) {
    options.push(dataLength);
  }
  return options;
};
const updatePageSize = (options: number[], currentPageSize: number): number => {
  if (currentPageSize === 0) {
    return options[options.length - 1];
  } else {
    const pageSize = currentPageSize > options[options.length - 1] ? options[options.length - 1] : currentPageSize;
    return pageSize;
  }
};

const defaultExpandable = { expandedRowRender: (record: DataType) => <p>{record.account}</p> };

const defaultRowSelection: TableRowSelection<DataType> = {};

const convertFiltersToTags = (
  filters: Record<string, FilterValue | null>,
  handleResetFilter: (filterKey: string) => void,
) => {
  if (!filters) {
    return;
  }
  return Object.entries(filters).map(([key, value]) => {
    if (value !== null) {
      // Check if the value is an array (assuming FilterValue is an array type)
      const displayValue = Array.isArray(value) ? value.join(", ") : value;
      const tagColor = keyColorMap[key] || "gray";
      return (
        <Tag key={key} color={tagColor} style={{ padding: "10px" }}>
          <Box style={{ width: "100%", display: "flex", flexDirection: "row" }}>
            <Typography style={{ color: "#001738", marginRight: "5px" }}>
              {key}: <strong>{displayValue}</strong>
            </Typography>
            <CloseCircleOutlined
              style={{ color: "black" }}
              onClick={() => {
                handleResetFilter(key);
              }}
            />
          </Box>
        </Tag>
      );
    }
    return null;
  });
};

const setTitle = (
  handleGenerateReport: HandleGenerateReportType,
  currentFilters: Record<string, FilterValue | null>,
  handleResetFilter: (filterKey: string) => void,
) => {
  const searchTags = convertFiltersToTags(currentFilters, handleResetFilter);

  const content = (
    <Box style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
      <ButtonMUI
        sx={{
          // textTransform: "uppercase",
          fontWeight: "bold",
        }}
        color="primary"
        variant="contained"
        onClick={handleGenerateReport.generateReportXLSX}
      >
        Xlsx
      </ButtonMUI>
      <ButtonMUI
        sx={{
          // textTransform: "uppercase",
          fontWeight: "bold",
        }}
        color="primary"
        variant="contained"
        onClick={handleGenerateReport.generateReportPDF}
      >
        Pdf
      </ButtonMUI>
    </Box>
  );
  return (
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
      <div>{searchTags}</div>
      <div>
        <Popover placement="topLeft" content={content}>
          <ButtonMUI
            sx={{
              // textTransform: "uppercase",
              fontWeight: "bold",
            }}
            color="primary"
            variant="contained"
          >
            Generate Report
          </ButtonMUI>
        </Popover>
      </div>
    </div>
  );
};

const setSummary = (
  pageData: readonly DataType[],
  expandedSummary: boolean,
  setExpandedSummary: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  let totalAmount: { [key: string]: number } = {};
  if (pageData.length) {
    pageData.forEach(({ amount, currency }: { amount: number; currency: string }) => {
      totalAmount[currency] = +((totalAmount[currency] || 0) + amount).toFixed(2);
    });
  }

  const summaryCellStyle = {
    fontSize: "1rem",
    fontWeight: "bold",
    color: "#001738",
  };
  return (
    <>
      {/* {expandedSummary ? ( */}
      <Table.Summary.Row
        key="expandedSummary"
        // style={{ border: "2px solid red", borderRadius: "10px" }}
      >
        {/* <Table.Summary.Cell className="expandedSummary" align="center" index={0} colSpan={1}>
              <IconButton onClick={() => setExpandedSummary(!expandedSummary)}>
                <AddIcon />
              </IconButton>
            </Table.Summary.Cell> */}
        <Table.Summary.Cell index={0} colSpan={2} align="start">
          <Tag color="#EFF0F2" style={summaryCellStyle}>
            Total
          </Tag>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2} colSpan={1} align="center">
          <Tag color="#EFF0F2">
            {Object.keys(totalAmount)?.map((currency, index) => (
              <Typography key={currency} sx={{ textAlign: "center" }} style={summaryCellStyle}>{`${
                getSymbolFromCurrency(currency) || currency
              }${addCommasToNumbers(totalAmount[currency])}`}</Typography>
            ))}
          </Tag>
        </Table.Summary.Cell>
        <Table.Summary.Cell index={4} colSpan={5}></Table.Summary.Cell>
      </Table.Summary.Row>
      {/*  ) : (
        <Table.Summary.Row key={"notExpandedSummary"}>
          <Table.Summary.Cell className="expandedSummary" align="center" index={0} colSpan={1}>
            <IconButton onClick={() => setExpandedSummary(!expandedSummary)}>
              <AddIcon />
            </IconButton>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={2} align="center">
            <Tag color="green" style={summaryCellStyle}>
              Total
            </Tag>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={2} align="center">
            <Tag color="green">
              <Typography key={Object.keys(totalAmount)[0]} sx={{ textAlign: "center" }} style={summaryCellStyle}>
                {Object.keys(totalAmount)[0] &&
                  Object.values(totalAmount)[0] &&
                  `${Object.keys(totalAmount)[0]} - ${Object.values(totalAmount)[0]}   ...`}
              </Typography>
            </Tag>
          </Table.Summary.Cell>
        </Table.Summary.Row>
       )} */}
    </>
  );
};

const footer = () => "Here is footer";
const setLoading = (billingInfoIsLoading: boolean) => ({
  indicator: (
    <Box>
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "1000%",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    </Box>
  ),
  spinning: billingInfoIsLoading,
});

function addCommasToNumbers(number: number) {
  // Convert the number to a string and split it into parts before and after the decimal point
  const parts = number.toFixed(2).toString().split(".");
  // Add commas between thousands in the part before the decimal point
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // Join the parts back together with the decimal point
  return parts.join(".");
}

export const columns = (tableColumsProps: TableStructureProps): ColumnsType<DataType> => [
  {
    title: "Partner",
    dataIndex: "partner",
    key: "partner",
    filterSearch: true,
    sorter: (a, b) => a.partner.toLocaleLowerCase().localeCompare(b.partner.toLocaleLowerCase()),
    ...getColumnSearchProps({ ...tableColumsProps }, "partner"),
  },
  {
    title: "Account",
    dataIndex: "account",
    key: "account",
    filterSearch: true,
    sorter: (a, b) => a.account.toLocaleLowerCase().localeCompare(b.account.toLocaleLowerCase()),
    ...getColumnSearchProps({ ...tableColumsProps }, "account"),
  },
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    sorter: (a, b) => a.amount - b.amount,
    ...getColumnSearchProps({ ...tableColumsProps }, "amount"),
    // colSpan: 2,
    render: (_, { amount }) => {
      const amountSring = addCommasToNumbers(amount);
      return (
        <Typography
          style={{
            // fontSize: "14px",
            color: "#001738",
          }}
        >
          {amountSring}
        </Typography>
      );
    },
    align: "center",
  },
  {
    title: "Currency",
    dataIndex: "currency",
    key: "currency",
    // colSpan: 0,
    align: "center",
    filteredValue: tableColumsProps.currentFilters.currency || null,
    filters: Array.from(tableColumsProps.currencies.values()).map((currency: string) => ({
      text: currency,
      value: currency,
    })),
    // ...getColumnSearchProps({ ...tableColumsProps }, "currency"),
    render: (_, { currency }) => {
      Array.from(tableColumsProps.currencies.values()).forEach((currency, index) => {
        colorsMap[currency] = getColorByIndex(index + 1);
      });
      // const tagColor = colorsMap[currency] || "darkred";
      const tagColor = "#EFF0F2";
      return (
        <Tag color={tagColor} key={currency}>
          <Typography style={{ color: "#001738" }}>{getSymbolFromCurrency(currency) || currency}</Typography>
        </Tag>
      );
    },
    onFilter: (value, record) => record["currency"].toString().toLowerCase() === (value as string).toLowerCase(),
    sorter: (a, b) => a.currency.toLocaleLowerCase().localeCompare(b.currency.toLocaleLowerCase()),
  },
  {
    title: "Price plan",
    dataIndex: "pricePlan",
    key: "pricePlan",
    filteredValue: tableColumsProps.currentFilters.pricePlan || null,
    filters: Array.from(tableColumsProps.pricePlans.values()).map((pricePlan: string) => ({
      text: pricePlan,
      value: pricePlan,
    })),
    align: "center",
    // ...getColumnSearchProps({ ...tableColumsProps }, "pricePlan"),
    render: (_, { pricePlan }) => {
      Array.from(tableColumsProps.pricePlans.values()).forEach((pricePlan, index) => {
        colorsMap[pricePlan] = getColorByIndex(index + 1);
      });
      const tagColor = colorsMap[pricePlan] || "darkred";
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Tag
            color={tagColor}
            key={pricePlan}
            style={{
              display: "inline-block",
            }}
          >
            <Typography
              style={{
                // fontSize: "14px",
                color: "#001738",
                maxWidth: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
              }}
            >
              {pricePlan.toUpperCase()}
            </Typography>
          </Tag>
        </Box>
      );
    },

    onFilter: (value, record) => record["pricePlan"].toString().toLowerCase() === (value as string).toLowerCase(),
    sorter: (a, b) => a.pricePlan.toLocaleLowerCase().localeCompare(b.pricePlan.toLocaleLowerCase()),
  },
  {
    title: "Period",
    dataIndex: "period",
    key: "period",
    filteredValue: tableColumsProps.currentFilters.period || null,
    filters: Array.from(tableColumsProps.periods.values()).map((period: string) => ({
      text: period,
      value: period,
    })),
    align: "center",
    // ...getColumnSearchProps({ ...tableColumsProps }, "period"),
    render: (_, { period }) => {
      Array.from(tableColumsProps.periods.values()).forEach((period, index) => {
        colorsMap[period] = getColorByIndex(index + 1);
      });
      // const tagColor = colorsMap[period] || "darkred";
      const tagColor = "#EFF0F2";
      return (
        <Tag color={tagColor} key={period}>
          <Typography
            style={{
              // fontSize: "14px",
              color: "#001738",
            }}
          >
            {period}
          </Typography>
        </Tag>
      );
    },
    onFilter: (value, record) => record["period"].toString().toLowerCase() === (value as string).toLowerCase(),
    sorter: (a, b) => {
      const year = a.period.split(" ")[1].toLocaleLowerCase();
      const monthA = a.period.split(" ")[0].toLocaleLowerCase();
      const monthB = b.period.split(" ")[0].toLocaleLowerCase();
      const dateA = new Date(Date.parse(monthA + ` 1,${year}`));
      const dateB = new Date(Date.parse(monthB + ` 1,${year}`));
      return dateA.getTime() - dateB.getTime();
    },
  },
  {
    title: "Date of last invoice",
    dataIndex: "dateOfLastInvoice",
    key: "dateOfLastInvoice",
    filterSearch: true,
    ...getColumnSearchProps({ ...tableColumsProps }, "dateOfLastInvoice"),
    sorter: (a, b) => new Date(a.dateOfLastInvoice).getTime() - new Date(b.dateOfLastInvoice).getTime(),
    render: (_, { dateOfLastInvoice, period }) => {
      if (CONSTANT.DATE_RANGE_TYPES.MTD.type.toUpperCase() === period.toUpperCase()) {
        return "-";
      }
      return (
        <Typography
          style={{
            // fontSize: "14px",
            color: "#001738",
          }}
        >
          {new Date(dateOfLastInvoice).toISOString().split("T")[0]}
        </Typography>
      );
    },
    align: "center",
  },
  {
    title: "Date of registration",
    dataIndex: "dateOfRegistration",
    key: "dateOfRegistration",
    filterSearch: true,
    ...getColumnSearchProps({ ...tableColumsProps }, "dateOfRegistration"),
    sorter: (a, b) => new Date(a.dateOfRegistration).getTime() - new Date(b.dateOfRegistration).getTime(),
    render: (_, { dateOfRegistration }) => {
      return (
        <Typography
          style={{
            // fontSize: "14px",
            color: "#001738",
          }}
        >
          {new Date(dateOfRegistration).toISOString().split("T")[0]}
        </Typography>
      );
    },
    align: "center",
  },
  // {
  //   title: 'Action',
  //   key: 'action',
  //   sorter: true,
  //   render: () => (
  //     <Space size="middle">
  //       <a>Delete</a>
  //       <a>
  //         <Space>
  //           More actions
  //           <DownloadOutlined />
  //         </Space>
  //       </a>
  //     </Space>
  //   ),
  // },
];

export const getColumnSearchProps = (props: TableStructureProps, dataIndex: DataIndex): ColumnType<DataType> => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Escape") {
        setSelectedKeys([]);
        props.handleSearch([], confirm, dataIndex);
        clearFilters && clearFilters();
        close && close();
      }
    };
    return (
      <div
        style={{
          margin: 0,
          padding: "15px",
          // fontSize: "20px",
          borderRadius: "8px",
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          style={{
            margin: 0,
            padding: "8px",
            width: "20rem",
            borderRadius: "6px",
          }}
          ref={props.searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => {
            e.stopPropagation();
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            props.handleSearch(selectedKeys as string[], () => confirm({ closeDropdown: false }), dataIndex);
          }}
          onKeyDown={handleKeyDown}
          allowClear
        />
      </div>
    );
  },
  filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
  filteredValue: props.currentFilters[dataIndex] || null,
  onFilter: (value, record) =>
    record[dataIndex]
      .toString()
      .toLowerCase()
      .includes((value as string).toLowerCase()),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => props.searchInput.current?.select(), 100);
    }
  },
  onCell: (text, record) => {
    return {
      style: { padding: "6px" },
    };
  },
  render: (text) => (
    <Typography
      style={{
        // fontSize: "14px",
        color: "#001738",
      }}
    >
      {text}
    </Typography>
  ),
});
