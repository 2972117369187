import { useEffect, useState } from "react";
import { Button, Box, FormControl, Select, MenuItem, TextField, CircularProgress } from "@mui/material";
import Loader from "../../components/Loader";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import { boardWrapperStl, stl, titleStl, inputSx } from "./boardStl";
import { INPT_LENGTH, formFieldsValidator, validatorFunctions } from "../helpers/board-helpers";
import SelectAvatarBox from "./SelectAvatarBox";
import { useDispatch, useSelector } from "react-redux";
import { setEditedUser, setLoggedInUser } from "../users/usersSlice";
import { useUpdateUserByIdMutation } from "../accountsApi";
import { CONSTANT } from "../../constants/constants";
import { getModules } from "./editUserBoard-helpers";
import InfoIcon from "@mui/icons-material/Info";
import auth from "../../auth/auth-helper";
import { setReplaced } from "../../report/reportSlice";

export default function EditUserBoardHandler(props) {
  const [updateUserById] = useUpdateUserByIdMutation();
  const [showLoader, setShowLoader] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { editedUser, loggedUser } = useSelector((state) => state.users);
  const { apt } = useSelector((state) => state.report);
  const dispatch = useDispatch();
  const [selectedConfig, setSelectedConfig] = useState("");
  const [modulesList, setModulesList] = useState([]);
  const [loading, setLoading] = useState(true);

  const jwt = auth.isAuthenticated();

  useEffect(() => {
    if (editedUser.avatarUrl !== "" && formValues.avatarUrl.value !== editedUser.avatarUrl)
      setFormValues({
        ...formValues,
        avatarUrl: { ...formValues.avatarUrl, value: editedUser.avatarUrl },
      });
  }, []);

  useEffect(() => {
    dispatch(setReplaced(jwt.data.user.apt));

    setLoading(true);

    const result = getModules({ ...loggedUser, apt }, props.value);
    const ids = result.modules.map((i) => i.id);

    if (ids.includes(props.value.modulesConfigurationId)) {
      setSelectedConfig(props.value.modulesConfigurationId);
    }

    setModulesList(result);
    setLoading(false);
  }, [loggedUser, apt]);

  const [formValues, setFormValues] = useState({
    firstName: {
      value: editedUser.firstName || props.value.firstName,
      error: false,
      errorMessage: "Please, enter user's name (" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.firstName,
    },
    lastName: {
      value: editedUser.lastName || props.value.lastName,
      error: false,
      errorMessage: "Please, enter user's last name (" + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.lastName,
    },
    email: {
      value: editedUser.email || props.value.email,
      error: false,
      errorMessage: "Please, add an email addres in correct format",
      validationFunction: validatorFunctions.email,
    },
    phone: {
      value: editedUser.phone || props.value.phone,
      error: false,
      errorMessage: "Please, add phone num. (+XXXXXXXXXXXX ('+' is optional))",
      validationFunction: validatorFunctions.phone,
    },
    position: {
      value: editedUser.position || props.value.position,
      error: false,
      errorMessage: "Text too long",
      validationFunction: validatorFunctions.position,
    },
    avatarUrl: {
      value: props.value.avatarUrl,
      error: false,
      errorMessage: "Wrong avatar url",
      validationFunction: [(val) => true],
    },
    modulesConfigurationId: {
      value: props.value.modulesConfigurationId,
      error: false,
      errorMessage: "Wrong module configuration",
      validationFunction: [(val) => true],
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "modulesConfigurationId") {
      setSelectedConfig(value);
    }
    setFormValues({
      ...formValues,
      [name]: { ...formValues[name], value, error: false },
    });

    dispatch(setEditedUser({ ...editedUser, [name]: value }));
  };

  const handleRetrieve = () => {
    let { newFormValues, errors } = formFieldsValidator(formValues, isChecked);
    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);
    let data = {
      firstName: formValues.firstName.value,
      lastName: formValues.lastName.value,
      phone: formValues.phone.value,
      email: formValues.email.value,
      position: formValues.position.value,
      avatarUrl: formValues.avatarUrl.value,
      modulesConfigurationId: formValues.modulesConfigurationId.value,
    };

    updateUserById({ id: props.value.id, user: data }).then((data) => {
      setShowLoader(false);
      if (data.error) {
        console.log(data.error);
        alert("Action failed, something went wrong... " + data.error);
      } else {
        props.onClose();
        props.updateDataAndRows(data);
      }
    });
    if (props.value.id === loggedUser.id) {
      dispatch(
        setLoggedInUser({
          ...loggedUser,
          avatarUrl: data.avatarUrl,
          firstName: data.firstName,
          lastName: data.lastName,
        }),
      );
      const jwtData = localStorage.getItem("jwt");
      let newJwtData;
      if (jwtData) {
        try {
          const parsedJwtData = JSON.parse(jwtData);
          parsedJwtData.data.user.avatarUrl = data.avatarUrl;
          newJwtData = parsedJwtData;
        } catch (e) {
          console.error("Error parsing JWT data from localStorage:", e);
        }
      }
      localStorage.setItem("jwt", JSON.stringify(newJwtData));
    }
  };

  let isDisabled =
    !Boolean(formValues.firstName.value) ||
    !Boolean(formValues.lastName.value) ||
    !Boolean(formValues.email.value);
  if (showLoader) return <Loader sx={{ height: "80vh" }} />;

  return (
    <div id='edit-user-popup'>
      <div style={boardWrapperStl}>
        <SelectAvatarBox
          avatarUrl={formValues.avatarUrl.value}
          onReset={() => {
            formValues.avatarUrl.value = CONSTANT.defaultAvatarUrl;
            dispatch(setEditedUser({ ...editedUser, avatarUrl: CONSTANT.defaultAvatarUrl }));
          }}
        ></SelectAvatarBox>
        {/* First name */}
        <Box sx={stl}>
          <Box sx={titleStl}>First name</Box>
          <TextField
            id='first-name-input'
            sx={inputSx}
            onChange={handleChange}
            error={formValues.firstName.error}
            helperText={formValues.firstName.error && formValues.firstName.errorMessage}
            value={formValues.firstName.value}
            placeholder="First name"
            name="firstName"
            autoFocus
          />
        </Box>

        {/* Last name */}
        <Box sx={stl}>
          <Box sx={titleStl}>Last name</Box>
          <TextField
            id='last-name-input'
            sx={inputSx}
            onChange={handleChange}
            error={formValues.lastName.error}
            helperText={formValues.lastName.error && formValues.lastName.errorMessage}
            value={formValues.lastName.value}
            name="lastName"
            placeholder="Last name"
          />
        </Box>

        {/* Phone number */}
        <Box sx={stl}>
          <Box sx={titleStl}>Phone number</Box>
          <TextField
            id='phone-input'
            sx={inputSx}
            placeholder="+XXXXXXXXXXXX ('+' is optional)"
            name="phone"
            value={formValues.phone.value}
            onChange={handleChange}
            error={formValues.phone.error}
            helperText={formValues.phone.error && formValues.phone.errorMessage}
          />
        </Box>

        {/* email */}
        <Box sx={stl}>
          <Box sx={titleStl}>Email</Box>
          <TextField
            id='email-input'
            sx={inputSx}
            placeholder="Enter user's email"
            name="email"
            required
            value={formValues.email.value}
            onChange={handleChange}
            error={formValues.email.error}
            helperText={formValues.email.error && formValues.email.errorMessage}
          />
        </Box>

        {props.value.canAdminAccount && (
          <Box sx={stl}>
            <Box sx={titleStl}>Configuration</Box>
            {!modulesList.allowEdit ? (
              <Box sx={{ ...titleStl, position: "relative" }}>
                {modulesList.text}{" "}
                <span style={{ color: "#f44336", position: "absolute", top: "-1rem", right: "0px" }}>
                  <InfoIcon sx={{ fontSize: "1.2rem" }} />
                </span>
              </Box>
            ) : null}
            <FormControl fullWidth>
              {loading ? (
                <CircularProgress size={24} />
              ) : (
                <Select
                  disabled={!modulesList.allowEdit}
                  labelId="config-select-label"
                  id="configuration-select"
                  value={selectedConfig}
                  // label="Configuration"
                  onChange={handleChange}
                  name="modulesConfigurationId"
                >
                  {!!modulesList.allowEdit &&
                    modulesList.modules.map((config, ind) => {
                      return (
                        <MenuItem id='configuration-menu-item' key={config.id} value={config.id}>
                          {ind + 1}. {config.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
            </FormControl>
          </Box>
        )}

        {/* position */}
        <Box sx={stl}>
          <Box sx={titleStl}>Position</Box>
          <TextField
            id='position-input'
            sx={inputSx}
            placeholder="Enter users' position"
            name="position"
            value={formValues.position.value}
            onChange={handleChange}
            error={formValues.position.error}
            helperText={formValues.position.error && formValues.position.errorMessage}
          />
        </Box>
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id='save-btn' sx={buttonSx} onClick={handleRetrieve} disabled={isDisabled}>
          Save changes
        </Button>
      </div>
    </div>
  );
}
