import { message, notification } from "antd";
import auth from "../../auth/auth-helper";
import { CONSTANT } from "../../constants/constants";
import { RequestPeriodType } from "../../parametersConstancy/parametersConstancyTypes";
const SERVER_URL = CONSTANT.path.host;

export const getBillingInfoFetch = async (periods: RequestPeriodType[], signal: AbortSignal) => {
  try {
    let token = auth.isAuthenticated().data.accessToken;
    const response = await fetch(`${SERVER_URL}/billing`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({ periods }),
      signal,
    });
    if (!response.ok) {
      if (response.status === 401) {
        throw new Error("Unauthorized");
      } else {
        throw new Error("Server can not get the billing info");
      }
    }
    const result = await response.json();
    if (result.statusCode === 503) {
      notification.error({ message: "Server can not get the billing info" });
    }
    return result;
  } catch (error) {
    console.error("Error fetching billing info:", error);
  }
};
