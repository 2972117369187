export const paginationStl = {
  // display: "flex",
  // justifyContent: "center",
  "& .MuiTablePagination-root.pagination-container": {
    position: "relative",
    width: "100%",
  },
  "& .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar": {
    padding: 0,
  },
  "& .MuiTablePagination-selectLabel": {
    position: "absolute",
    right: 40,
    top: 6,
    margin: 0,
  },
  "& .MuiInputBase-root": {
    width: "35px",
    height: "35px",
    color: "#000000d9",
    border: "1px solid #c4c4c4",
    background: "#fff",
    borderRadius: "4px",
    position: "absolute",
    right: 0,
    top: 0,
    marginRight: 0,
    marginLeft: 0,
  },
  "& .MuiPagination-root.MuiPagination-outlined.pagination-pages": {
    position: "absolute",
    left: 0,
    top: 0,
    "& .Mui-selected": {
      color: "#1BB2FF",
      border: "1px solid #1BB2FF",
      background: "#fff",
    },
  },
  "& .MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    p: 0,
  },
  "& .MuiInputBase-root > .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium": {
    display: "none",
  },
  "& .MuiTablePagination-spacer": {
    display: "none",
  },
  "& .MuiTablePagination-displayedRows": {
    display: "none",
  },
};
