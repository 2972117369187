import React, { FC } from "react";
import { ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

interface SegmentControlOption {
  id: number;
  name: string;
}

interface SegmentControlProps {
  options: SegmentControlOption[];
  value: SegmentControlOption;
  onChange: (value: SegmentControlOption) => void;
  disabled?: boolean;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: "#f4f6ff", // Light blue background
  borderRadius: "100px",
  display: "flex",
  alignItems: "center",
  boxShadow: "none",
  height: "40px",
  padding: "4px",
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  height: "32px",
  border: "none", // Remove border for both selected and unselected states
  color: "#67748e",
  "&.Mui-selected": {
    backgroundColor: "#ffffff",
    fontWeight: "bold",
    color: "#000000", // Adjust color for selected state
    borderRadius: "25px",
    boxShadow: "none",
  },
  "&:not(.Mui-selected)": {
    color: "#67748e",
    backgroundColor: "transparent", // Keep background transparent for unselected state
  },
  "&:hover": {
    backgroundColor: "transparent", // Prevent background color change on hover
  },
}));

const SegmentControl: FC<SegmentControlProps> = ({ options, value, onChange, disabled = false }) => {
  const handleAlignment = (_event: React.MouseEvent<HTMLElement>, newId: number) => {
    const selectedOption = options.find((option) => option.id === newId);
    if (selectedOption) {
      onChange(selectedOption);
    }
  };

  return (
    <StyledToggleButtonGroup
      disabled={disabled}
      value={value.id}
      exclusive
      onChange={handleAlignment}
      aria-label="segment control"
    >
      {options.map((option) => (
        <StyledToggleButton key={option.id} value={option.id}>
          {value.id === option.id ? (
            <Typography variant="bodyMediumSemiboldNormal">{option.name}</Typography>
          ) : (
            <Typography variant="body2XSmallMain400" color="#67748e">
              {option.name}
            </Typography>
          )}
        </StyledToggleButton>
      ))}
    </StyledToggleButtonGroup>
  );
};

export default SegmentControl;
