import { useState, useMemo, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import UserDetailsCard from "./userDetailsComponents/UserDetailsCard";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import auth from "../auth/auth-helper";
import ForbiddenResourceNotification from "../components/notifications/ForbiddenResourceNotification";
import { Typography } from "@mui/material";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";
import { dataGridSx } from "./styles";
import {
  useDeleteUserByIdMutation,
  useGetAccountsQuery,
  useGetAllUsersQuery,
  useGetUserByIdQuery,
} from "./accountsApi";
import AdminElement from "../components/ElementWrappers/AdminElement";
import SideBoardWrapper from "./sideBoard/SideBoardWrapper";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { getRoleDetails } from "./api/accounts-api";
import RoleDetailsPopup from "./rolesMangementComponents/RoleDetailsPopup";
import UnassignRole from "./tableComponents/UnassignRole";
import SessionTimeout from "../components/SessionTimeout/SessionTimeout";
import { useSelector } from "react-redux";
import { OriginNames, UsersState } from "./users/interfaces";
import { colors } from "../theme";
import ConfirmationDialog from "../components/Dialogs/ConfirmationDialog";
import {} from "antd";
import { CONSTANT } from "../constants/constants";

let btnStl = {
  width: "32px",
  height: "32px",
  textAlign: "center",
  padding: "3px",
  borderRadius: "0.5rem",
  // border: "1px solid #bebebe",
  fontWeight: 600,
  fontSize: "1rem",
  backgroundColor: "#fff",
  cursor: "pointer",
  color: "secondary.mainDark",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
    border: "1px solid transparent",
  },
};

interface DefaultColumnsProps {
  actionsProps: {
    handleRowClick: (params: any) => void;
    getUserId: () => string | undefined;
    refetchUserById: () => void;
    refetchAccounts: () => void;
    // Add any other methods or properties used in actionsProps
  };
  canAdminAccount?: boolean; // Mark it as optional if it can be undefined
}

const defaultColumns = (props: DefaultColumnsProps): GridColDef[] => [
  {
    field: "name",
    headerName: "Role Name",
    flex: 1.2,
  },
  {
    field: "Account",
    headerName: "Account",
    flex: 1,
    headerAlign: "center",
    align: "center",
    renderCell: (params: any) => params.row.account_id.company,
  },
  {
    field: "Description",
    headerName: "Description",
    flex: 1,
    headerAlign: "center",
    align: "center",
  },
  {
    field: "Actions",
    headerName: "Actions",
    renderCell: (params: any) => {
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box
            onClick={() => props.actionsProps.handleRowClick(params)}
            sx={{ ...btnStl, marginRight: "4px" }}
            id="view-btn"
          >
            <RemoveRedEyeOutlinedIcon sx={{ fontSize: "1rem", marginTop: "5px", color: "grey" }} />
          </Box>
          {props.canAdminAccount && (
            <Box sx={{ ...btnStl, padding: 0 }}>
              <UnassignRole
                {...params}
                userIds={props.actionsProps.getUserId()}
                refetchUserById={props.actionsProps.refetchUserById}
                refetchAccounts={props.actionsProps.refetchAccounts}
              />
            </Box>
          )}
        </Box>
      );
    },
  },
];

export default function UserDetailsPage() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const { loggedUser } = useSelector((state: any) => state.users);
  const origin = useSelector((state: any) => state.users.navigation.origin);

  const {
    data: userData,
    isLoading,
    isFetching,
    isError,
    error,
    refetch: refetchUserById,
  } = useGetUserByIdQuery(id);

  const { refetch: refetchAccounts } = useGetAccountsQuery();

  const conbinedDataRef1 = useRef([]);
  const roleName = useRef("");
  const jwt = auth.isAuthenticated();
  //FIXME
  const handleHistoryEditItemClick = () => {
    navigate(`/accounts/${userData?.account_id.id}`);
  };
  const handleHistoryAccountsItemClick = () => {
    navigate("/accounts");
  };
  const handleHistorUsersItemClick = () => {
    navigate("/users");
  };

  const handleRowClick = (params: any) => {
    getRoleDetails(params.id).then((data) => {
      if (data?.statusCode === 403) {
        alert("Failed to display role details");
      } else {
        conbinedDataRef1.current = data;
        roleName.current = params.row.name;
        setOpen(true);
      }
    });
  };
  const getUserId = () => {
    return id;
  };

  const columns = useMemo(() => {
    if (!userData) return [];
    return defaultColumns({
      actionsProps: {
        handleRowClick,
        getUserId,
        refetchUserById,
        refetchAccounts,
      },
      canAdminAccount: userData.canAdminAccount,
    });
  }, [handleRowClick, getUserId, refetchUserById, refetchAccounts, userData]);

  const bc = () => {
    //REWORK (temporary nav)
    return (
      <Box sx={{ display: "flex", color: colors.lightBlue, cursor: "pointer" }}>
        {(origin?.name === OriginNames.Accounts || origin?.name === OriginNames.Blank) && (
          <Box onClick={handleHistoryAccountsItemClick} sx={{ marginRight: "4px" }}>
            Accounts /
          </Box>
        )}
        {origin?.name === OriginNames.Users && jwt.data.user.acctId === CONSTANT.IIQ_ && (
          <Box sx={{ marginRight: "4px" }} onClick={handleHistorUsersItemClick}>
            Users /
          </Box>
        )}
        {userData?.account_id.id !== undefined && origin?.name === OriginNames.Accounts && (
          <Box onClick={handleHistoryEditItemClick} sx={{ marginRight: "4px" }}>
            Details /
          </Box>
        )}
        <Box sx={{ marginRight: "4px", color: "#1BB2FF" }}>{`${userData?.firstName} ${userData?.lastName}`}</Box>
      </Box>
    );
  };
  if (isError) {
    const castedError = error as any;
    return (
      <Box>
        {castedError?.data?.statusCode === 403 ? <ForbiddenResourceNotification /> : castedError?.data?.message}
      </Box>
    );
  }
  return isLoading || isFetching ? (
    <LoaderWithProgress
      sx={{
        display: "flex",
        width: "100%",
        minHeight: "75vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    />
  ) : (
    userData && (
      <Box sx={{}}>
        <Box
          sx={{
            margin: "0rem 0rem",
            padding: "1rem 1rem",
            display: "flex",
            flexDirection: "column",
            gap: "32px",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ fontSize: "1.5rem" }}>
            <UserDetailsCard
              userData={userData}
              updateDataAndRows={() => {
                refetchUserById();
                refetchAccounts();
              }}
            />
          </Box>

          <Typography id="user-roles-subtitle" variant="h1XLargeDark600">User roles</Typography>
          <AdminElement canAdmin={userData.canAdminAccount}>
            <Box sx={{ width: "256px" }}>
              <SideBoardWrapper
                id="change-role"
                type="role"
                btnIcon={<EditOutlinedIcon />}
                headerTitle="Change role"
                btnTitle="Change role"
                updateDataAndRows={() => {
                  refetchUserById();
                  refetchAccounts();
                }}
                value={{ userData, rls: userData.rls.map((i: any) => i.id) }}
                btnStyles={{}}
                // value={props.userData}
              />
            </Box>
          </AdminElement>
          {userData.rls.length > 0 && (
            <Box>
              <DataGrid
                key={"userDetailsDataGrid"}
                rows={userData.rls}
                columns={columns}
                // columns={defaultColumns()}
                autoHeight={true}
                hideFooterPagination={true}
                sx={{
                  ...dataGridSx,
                  "& .MuiDataGrid-row.Mui-hovered": {
                    backgroundColor: "transparent",
                  },
                  // Take out the hover colour
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                disableRowSelectionOnClick
              />
            </Box>
          )}
        </Box>
        {open ? (
          <RoleDetailsPopup setOpen={setOpen} data={conbinedDataRef1.current} roleName={roleName.current} />
        ) : null}
        <SessionTimeout />
      </Box>
    )
  );
}
