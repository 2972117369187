import React from "react";

export const OverviewIcon = (props) => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.5" d="M3.32438 0.205078H5.81767C7.65366 0.205078 9.14204 1.69346 9.14204 3.52945V6.02274C9.14204 7.85874 7.65366 9.34712 5.81767 9.34712H3.32438C1.48838 9.34716 0 7.85878 0 6.02274V3.52945C0 1.69346 1.48838 0.205078 3.32438 0.205078Z" fill="#1BB2FF" />
      <path d="M14.1286 0.205078H16.6219C18.4579 0.205078 19.9462 1.69346 19.9462 3.52945V6.02274C19.9462 7.85874 18.4579 9.34712 16.6219 9.34712H14.1286C12.2926 9.34712 10.8042 7.85874 10.8042 6.02274V3.52945C10.8042 1.69346 12.2925 0.205078 14.1286 0.205078Z" fill="#1BB2FF" />
      <path opacity="0.5" d="M3.32438 11.0093H5.81767C7.65366 11.0093 9.14204 12.4977 9.14204 14.3337V16.8269C9.14204 18.6629 7.65366 20.1513 5.81767 20.1513H3.32438C1.48838 20.1514 0 18.663 0 16.827V14.3337C0 12.4977 1.48838 11.0093 3.32438 11.0093Z" fill="#1BB2FF" />
      <path opacity="0.5" d="M14.1286 11.0093H16.6219C18.4579 11.0093 19.9462 12.4977 19.9462 14.3337V16.8269C19.9462 18.663 18.4579 20.1514 16.6219 20.1514H14.1286C12.2926 20.1514 10.8042 18.663 10.8042 16.827V14.3337C10.8042 12.4977 12.2925 11.0093 14.1286 11.0093Z" fill="#1BB2FF" />
    </svg>
  );

}

export const OverviewIconHeader = (props) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.26056 0.197266H10.2606C13.2061 0.197266 15.5939 2.58508 15.5939 5.53058V9.53058C15.5939 12.4761 13.2061 14.8639 10.2606 14.8639H6.26056C3.31506 14.864 0.927246 12.4761 0.927246 9.53058V5.53058C0.927246 2.58508 3.31506 0.197266 6.26056 0.197266Z" fill="#001738" />
      <path d="M23.5941 0.197266H27.5941C30.5396 0.197266 32.9274 2.58508 32.9274 5.53058V9.53058C32.9274 12.4761 30.5396 14.8639 27.5941 14.8639H23.5941C20.6486 14.8639 18.2607 12.4761 18.2607 9.53058V5.53058C18.2607 2.58508 20.6485 0.197266 23.5941 0.197266Z" fill="#001738" />
      <path d="M6.26056 17.5306H10.2606C13.2061 17.5306 15.5939 19.9185 15.5939 22.864V26.8639C15.5939 29.8095 13.2061 32.1973 10.2606 32.1973H6.26056C3.31506 32.1973 0.927246 29.8095 0.927246 26.864V22.864C0.927246 19.9185 3.31506 17.5306 6.26056 17.5306Z" fill="#001738" />
      <path d="M23.5941 17.5306H27.5941C30.5396 17.5306 32.9274 19.9185 32.9274 22.864V26.864C32.9274 29.8095 30.5396 32.1973 27.5941 32.1973H23.5941C20.6486 32.1973 18.2607 29.8095 18.2607 26.864V22.864C18.2607 19.9185 20.6485 17.5306 23.5941 17.5306Z" fill="#001738" />
    </svg>

  );

}