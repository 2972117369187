export const btnStl = {
  padding: "0.5rem 0.25rem",
  borderRadius: "0.5rem",
  border: "1px solid #E0E2E7",
  fontWeight: 600,
  fontSize: "1rem",
  backgroundColor: "#fff",
  color: "secondary.mainDark",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15)",
    color: "secondary.mainDark",
    // border: "1px solid transparent",
  },
};

export const stl = {
  margin: "30px 0",
};

export const titleStl = {
  fontSize: "12px",
  fontWeight: 600,
  color: "secondary.mainDark",
  marginBottom: "10.5px",
};

export const inputSx = {
  mt: "0.2rem",
  width: "100%",
  // width: "calc(80% - 10px)",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #E0E2E7",
    outline: "none",
  },
  input: {
    color: "secondary.mainDark",
    padding: "0.75rem",

    fontSize: "1rem",

    "&::placeholder": {
      color: "#8590A0",
    },
  },
};

export const avatarStl = {
  backgroundColor: "#cc89d8",
  width: 80,
  height: 80,
  fontSize: "1.5rem",
  boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.2)",
};

export const cardWrapperStl = {
  border: "1px solid #E0E2E7",
  borderRadius: "0.5rem",
  padding: "0.5rem 0.5rem",
};
