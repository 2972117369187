import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";
const SERVER_URL = CONSTANT.path.host;

const addUserToAccount = async (data, id) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/add_user_to_account/${id}`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

const updateUserRole = async (data) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    let response = await fetch(`${SERVER_URL}/accounts/assign_user_role`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (err) {
    console.log(err);
  }
};

export { addUserToAccount, updateUserRole };
