import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { resetRawPctAndRawCurrencyIndicators, resetSummaryCompleteDate } from "../../../../report/reportSlice";
import { resetSavedRawDataState, resetSavedCustomRawDataState } from "../../../../savedRawData/savedRawDataSlice";
import { resetSavedRawDataPreviousPeriodState } from "../../../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import { resetSummaryChartRecords } from "../../../../summary/summarySlice";
import { resetMainTableData, resetVRTData } from "../advancedReportSlice";
import { getStats } from "../getStats";
import auth from "../../../../auth/auth-helper";
import DateGroupSelection from "./DateGroupSelection";
import { useLocation } from "react-router-dom";
import { getVisitorRecognitionData } from "../../visitorRecognition/visitorRecognitionFetches";
import { CONSTANT } from "../../../../constants/constants";
import { setCurrentCommonParameter } from "../../../../parametersConstancy/platformParametersSlice";
import { setCurrentCommonParameterWrapper } from "../../../../parametersConstancy/parametersConstancyUtils";

export default function ExtendedDateGroupSelection(props) {
  const { dateRange, customDateRange, dateGroupingMode } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const active_range_date = dateRange.value;
  const { filters } = useSelector((state) => state.advancedReport);

  const activeFilters = Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values.filter((unit) => unit.isSelected)]),
  );
  const { partnersSelected } = useSelector((state) => state.role);
  const [mode, setMode] = useState(props.groupingMode);
  const [openDate, setOpenDate] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    setMode(props.groupingMode);
  }, [props.groupingMode]);

  const handleOpenClick = () => {
    setOpenDate(!openDate);
  };

  const handleClick = (group) => {
    setMode(group);
    handleApplyClick(group);
  };

  const handleApplyClick = (group) => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    const jwt = auth.isAuthenticated();
    setOpenDate(false);
    if (group === dateGroupingMode) return;
    // if (mode == dateGroupingMode) return;

    dispatch(setCurrentCommonParameter(setCurrentCommonParameterWrapper("dateGroupingMode", group)));

    if (location.pathname === CONSTANT.PAGES.VISITOR_RECOGNITION.path) {
      dispatch(resetVRTData());

      dispatch(
        getVisitorRecognitionData({
          requestData: {
            ...activeFilters,
            timeGrouping: props.rangeDate,
            customDateRange: customDateRange,
            partnersIds: partnersSelected.map((p) => p.dummy_id),
          },
          signal,
        }),
      );
    } else {
      dispatch(resetSavedCustomRawDataState());
      dispatch(resetSummaryCompleteDate());
      dispatch(resetSavedRawDataState());
      dispatch(resetSavedRawDataPreviousPeriodState());
      dispatch(resetRawPctAndRawCurrencyIndicators());
      dispatch(resetSummaryChartRecords());
      dispatch(resetMainTableData());
      // dispatch(resetTimeRangeAndSelectionBase());
      // dispatch(resetSelectionBaseRawData());
      dispatch(getStats({ ...activeFilters, timeGrouping: props.rangeDate, customDateRange: customDateRange }));
    }
  };
  // if (props.rangeDate == -2) {
  //   return (
  //     <Button id="test_id_hours" disabled={true} sx={{ ...singleBtn, color: "secondary.lightBlue" }}>
  //       Grouping hourly
  //     </Button>
  //   );
  // }

  return (
    <DateGroupSelection
      handleOpenClick={handleOpenClick}
      setOpenDate={setOpenDate}
      handleClick={handleClick}
      isDisabled={props.isDisabled}
      openDate={openDate}
      dateGroupingMode={props.groupingMode}
      active_range_date={active_range_date}
      mode={mode}
      customDateRange={customDateRange}
      showHours={location.pathname !== CONSTANT.PAGES.VISITOR_RECOGNITION.path}
    />
  );
}
