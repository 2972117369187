import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSavedRawDataType } from "../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import auth from "../../auth/auth-helper";
import { calcCustomDateforPreviousReport } from "../../utils";

import { CONSTANT } from "../../constants/constants";
import { getParametersConstancy } from "../../parametersConstancy/parametersContancyFetches";
const host = CONSTANT.path.host;

export const getReportPreviousPeriodByPlacement = createAsyncThunk(
  "report/getReportPreviousPeriodByPlacement",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { token, signal } = report;
    let currentState = getState();
    let jwt = auth.isAuthenticated();
    let ct = currentState.report.active_chart;
    let rt = currentState.report.active_range_date * 2;
    let base = currentState.report.filterBase === "summary" ? "placement_id" : currentState.report.filterBase;
    let target = "profile_placement";
    let pidParam = "";
    let customDateParam = "";
    let rpp = "&rpp=1"; // reports of previous period
    let isAdmin = jwt.data.user.scope.title === "admin" ? true : false;
    let skipSetBaseAsActive =
      "skipSetFilterBaseAsActive" in report && report.skipSetFilterBaseAsActive ? true : false;
    let skipInternalSearch = "skipInternalSearch" in report && report.skipInternalSearch ? true : false;
    let customDateEnd = "";
    let customDateStart = "";

    if (isAdmin) {
      if (!Boolean(currentState.role.partnersSelected)) {
        return {
          result: { data: [] },
          activeChart: ct,
          activeRangeDate: rt * 2,
        };
      }
      target = "profile_placement_observe";
      pidParam = `&pid=${currentState.role.partnersSelected}`;
    }
    if ("ct" in report) ct = report.ct;
    if ("rt" in report) rt = report.rt * 2;
    if ("setFilterBase" in report) base = report.setFilterBase === "summary" ? base : report.setFilterBase;

    if ("customDateRange" in report) {
      rt = -1;
      let res = calcCustomDateforPreviousReport(
        report.customDateRange.customDateStart,
        report.customDateRange.customDateEnd,
      );
      customDateStart = res.customDateStart;
      customDateEnd = res.customDateEnd;
      customDateParam = `&cstm_start=${customDateStart}&cstm_end=${customDateEnd}`;
    }

    if (!skipInternalSearch && currentState.savedRawDataPreviousPeriod[rt][base].length > 0) {
      let result = currentState.savedRawDataPreviousPeriod[rt][base];
      let data = {
        result: { data: result },
        activeChart: ct,
        activeRangeDate: rt,
        skipSetBaseAsActive: skipSetBaseAsActive,
      };
      if ("setFilterBase" in report) data.filterBase = report.setFilterBase;
      if (rt === -1) data.customDateRange = { customDateStart, customDateEnd };
      return data;
    }

    try {
      let response = await fetch(
        `${host}/reports/${target}/${report.id}?rt=${rt}&ct=${ct}&b=${base}${pidParam}${rpp}${customDateParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        },
      );

      if (!response.ok) {
        throw new Error("server can not get report");
      }

      const parametersConstancy = await getParametersConstancy();

      let result = await response.json();
      let data = {
        result,
        activeChart: ct,
        activeRangeDate: rt,
        filterBase: base,
        skipSetBaseAsActive,
        parametersConstancy,
      };

      if ("customDateRange" in report) data.customDateRange = { customDateStart, customDateEnd };
      dispatch(setSavedRawDataType(data));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
