import { prepareDateFormat } from "../utils";
export const sortByDate = (dataset) => {
  return dataset.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });
};

export const prepareTemplate = (start, end) => {
  let arr = new Array();
  let dt = new Date(start);
  let template = {
    date: "",
    extrapolationWithoutIIQ: 0,
    revenueWithIIQAsStackedVal: 0,
    revenueWithIIQ: 0,
  };

  while (dt <= end) {
    // let dateStr = dt.toString();
    arr.push({ ...template, date: prepareDateFormat(dt) });
    dt.setDate(dt.getDate() + 1);
  }
  return arr;
};

export const prepareTemplateMonthlyGrouped = (start, endDate, yr) => {
  let arr = new Array();
  let template = {
    date: "",
    extrapolationWithoutIIQ: 0,
    revenueWithIIQAsStackedVal: 0,
    revenueWithIIQ: 0,
  };

  let dt = new Date(start);
  const endDateObject = new Date(endDate);
  let monthsArray = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

  while (dt <= endDateObject) {
    let monthIndex = dt.getMonth(); // Month index from 0 to 11
    arr.push({
      ...template,
      date: prepareDateFormat(dt),
      nameMonth: monthsArray[monthIndex],
    });
    dt.setMonth(dt.getMonth() + 1);
  }

  return arr;
};

export const prepareTemplateQuarterlyGrouped = (start, endDate, yr) => {
  let arr = [];
  let template = {
    date: "",
    extrapolationWithoutIIQ: 0,
    revenueWithIIQAsStackedVal: 0,
    revenueWithIIQ: 0,
  };

  let dt = new Date(start);
  const endDateObject = new Date(endDate);
  let quartersArray = ["Q1", "Q2", "Q3", "Q4"];

  while (dt <= endDateObject) {
    let quarterIndex = Math.floor(dt.getMonth() / 3); // Determine the quarter index
    arr.push({
      ...template,
      date: prepareDateFormat(dt),
      nameQuarter: quartersArray[quarterIndex], // Reflect the quarter
    });
    dt.setMonth(dt.getMonth() + 3); // Move to the next quarter by adding 3 months
  }
  return arr;
};

export const prepareTemplateAggregatedGrouped = (start, endDate) => {
  let arr = [];
  let template = {
    date: "",
    extrapolationWithoutIIQ: 0,
    revenueWithIIQAsStackedVal: 0,
    revenueWithIIQ: 0,
  };
  let dt = new Date(endDate);
  arr.push({
    ...template,
    date: prepareDateFormat(dt),
  });
  return arr;
};

export const convertToChartData = (template, data, pct = 95) => {
  for (let i = 0; i < data.length; i++) {
    for (let j of template) {
      // let dataObj = new Date(j.date);
      // if (dataObj.setHours(0, 0, 0, 0) == new Date(i.submitted_date).setHours(0, 0, 0, 0)
      if (j.date === data[i].submitted_date) {
        let revenueWithoutIIQ = pct === 100 ? data[i].total_cpm2 * pct : (data[i].total_cpm2 * pct) / (100 - pct);
        // let revenueWithoutIIQ = (data[i].total_cpm2 * pct) / (100 - pct); //after check on prod build we can remove this
        j.date = data[i].submitted_date.toString();
        j.extrapolationWithoutIIQ = revenueWithoutIIQ;
        j.revenueWithIIQAsStackedVal = data[i].total_cpm1 - revenueWithoutIIQ;
        j.revenueWithIIQ = data[i].total_cpm1;
      }
    }
  }

  return sortByDate(template);
};

export const getAllTimeFirstDate = (data) => {
  const firstDate = data.reduce((earliestDateString, current) => {
    const earliestDate = new Date(earliestDateString);
    const currentDate = new Date(current.submitted_date);
    // Compare the dates and return the earliest date string.
    return earliestDate < currentDate ? earliestDateString : current.submitted_date;
  }, Infinity); // Use Infinity to ensure that any date is earlier than this initial value.

  return firstDate;
};

// export const convertToChartData = (data, pct = 95) => {
//   let arr = [];
//   for (let i of data) {
//     let revenueWithoutIIQ = i.total_cpm2 * pct / (100 - pct);
//     arr.push({
//       date: i.submitted_date,
//       extrapolationWithoutIIQ: revenueWithoutIIQ,
//       revenueWithIIQAsStackedVal: i.total_cpm1 - revenueWithoutIIQ,
//       revenueWithIIQ: i.total_cpm1,
//       // revenueIIQ: i.total_cpm1 / 0.95,
//       // liftIIQ: i.total_cpm2 * 19,
//       // liftIIQ: i.total_cpm1 - (i.total_cpm2 * 19).toFixed(2),
//     })
//   }
//   return sortByDate(arr);
// }
