import React from "react";
import { Link } from "@mui/material";
import { colors } from "../../theme";

interface TextWithLinksProps {
  text: string;
}

const isValidUrl = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // optional protocol
      "(" +
      "localhost|" + // localhost
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,})|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3})" + // OR IP (v4) address
      ")" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i",
  );
  return pattern.test(str);
};

const TextWithLinks: React.FC<TextWithLinksProps> = ({ text }) => {
  const words = text.split(/\s+/);
  return (
    <>
      {words.map((word, index) => {
        if (isValidUrl(word)) {
          const href = word.startsWith("http") ? word : `https://${word}`;
          return (
            <Link
              key={index}
              href={href}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ wordBreak: "break-word", color: colors.brandDefault }}
            >
              {word}
            </Link>
          );
        }
        return <span key={index}>{word} </span>;
      })}
    </>
  );
};

export default TextWithLinks;
