import {
  CategoriesType,
  ChangedParameters,
  CommonUserPlatformParameters,
  FilterGroup,
  PerformanceReportParameters,
  UserParametersConstancyPageType,
} from "./parametersConstancyTypes";
import {
  setChangedParameter,
  setParametersEntrySettings,
  setParametersIsFetched,
} from "./platformParametersSlice";
import { CONSTANT } from "../constants/constants";
import { getMTD, daysAgoSinceStartDate } from "../utils";
import { Dispatch } from "redux";
import { NavigateFunction } from "react-router-dom";
import { setChartGroupEntryParametersConstancy } from "../chartSelector/chartSelectorSlice";
import { setAdvancedReportEntryParametersConstancy } from "../core/performanceReports/advancedReport/advancedReportSlice";
import { setReportEntryParametersConstancy } from "../report/reportSlice";
import { setSummaryEntryParametersConstancy } from "../summary/summarySlice";

export const setParametersConstancyWrapper = <
  T extends keyof UserParametersConstancyPageType,
  K extends keyof UserParametersConstancyPageType[T],
>(
  blockType: T,
  parametersKey: K,
  payload: UserParametersConstancyPageType[T][K],
) => {
  return { blockType, parametersKey, payload } as any;
};

export const setCurrentCommonParameterWrapper = <T extends keyof CommonUserPlatformParameters>(
  parametersKey: T,
  payload: CommonUserPlatformParameters[T],
) => {
  return { parametersKey, payload } as any;
};
export const setCurrentReportsParameterWrapper = <T extends keyof PerformanceReportParameters>(
  parametersKey: T,
  payload: PerformanceReportParameters[T],
) => {
  return { parametersKey, payload } as any;
};

export const setupParameters = async (
  parameters: UserParametersConstancyPageType,
  dispatch: Dispatch,
  navigate: NavigateFunction,
) => {
  if (parameters) {
    const validatedParameters = validateParametersConstsancy(parameters, dispatch);

    try {
      await Promise.all([
        dispatch(setReportEntryParametersConstancy(validatedParameters)),
        dispatch(setChartGroupEntryParametersConstancy(validatedParameters.overviewParameters.groupType)),
        dispatch(setSummaryEntryParametersConstancy(validatedParameters)),
        dispatch(setAdvancedReportEntryParametersConstancy(validatedParameters)),
        dispatch(setParametersEntrySettings(validatedParameters)),
      ]);
      dispatch(setParametersIsFetched(true));
    } catch (error) {
      console.error("Error setting up parameters", error);
    }
  }
};

export const validateParametersConstsancy = (parameters: ChangedParameters, dispatch: Dispatch): any => {
  const rangeType = parameters?.commonUserPlatformParameters?.dateRange ?? CONSTANT.DATE_RANGE_TYPES.LAST_WEEK;
  const MTD = getMTD();
  const YTD = daysAgoSinceStartDate();
  const todayDayOfMonth = new Date().getDate();
  const todayMonth = new Date().getMonth() + 1;
  if (rangeType.type === CONSTANT.DATE_RANGE_TYPES.YTD.type) {
    if (todayDayOfMonth === 1 && todayMonth === 1) {
      rangeType.value = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value;
      rangeType.type = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type;
      dispatch(
        setChangedParameter(
          setParametersConstancyWrapper("commonUserPlatformParameters", "dateRange", {
            type: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type,
            value: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value,
          }),
        ),
      );
    } else {
      if (YTD !== rangeType.value) {
        dispatch(
          setChangedParameter(
            setParametersConstancyWrapper("commonUserPlatformParameters", "dateRange", {
              type: CONSTANT.DATE_RANGE_TYPES.YTD.type,
              value: YTD,
            }),
          ),
        );
        rangeType.value = YTD;
        rangeType.type = CONSTANT.DATE_RANGE_TYPES.YTD.type;
      }
    }
  } else if (rangeType.type === CONSTANT.DATE_RANGE_TYPES.MTD.type) {
    if (todayDayOfMonth === 1) {
      rangeType.value = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value;
      rangeType.type = CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type;
      dispatch(
        setChangedParameter(
          setParametersConstancyWrapper("commonUserPlatformParameters", "dateRange", {
            type: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type,
            value: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value,
          }),
        ),
      );
    } else {
      if (MTD !== rangeType.value) {
        dispatch(
          setChangedParameter(
            setParametersConstancyWrapper("commonUserPlatformParameters", "dateRange", {
              type: CONSTANT.DATE_RANGE_TYPES.MTD.type,
              value: MTD,
            }),
          ),
        );
        rangeType.value = MTD;
        rangeType.type = CONSTANT.DATE_RANGE_TYPES.MTD.type;
      }
    }
  }
  parameters.commonUserPlatformParameters.dateRange = rangeType;
  return parameters;
};

export const mappingFiltersToFilterGroup = (
  groupName: string,
  filtersValues: CategoriesType,
  filtersOptions: CategoriesType,
  partnersValues: CategoriesType,
  type: string,
  groupId?: number,
): FilterGroup => {
  const filterGroup: FilterGroup = {
    name: groupName,
    filters: Object.fromEntries(
      Object.entries(filtersValues).map(([key, values]) => {
        if (filtersOptions[key] && values?.length > 0 && filtersOptions[key].length === 0) {
          return [
            key,
            {
              ids: [CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id],
              names: [CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name],
            },
          ];
        } else {
          return [
            key,
            {
              ids: filtersValues[key].map((item) => item.id),
              names: filtersValues[key].map((item) => item.name),
            },
          ];
        }
      }),
    ),
    partners: Object.values(partnersValues)[0],
    type,
  };
  if (groupId) {
    filterGroup.id = groupId;
  }
  return filterGroup;
};
