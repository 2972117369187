import { colors } from "../../../theme";

export const stlBox = {
  width: "350px", //height is set with ListboxProps
  backgroundColor: "#fff",
  border: "1px solid #E3E3E3",
  borderRadius: "8px",
  position: "absolute",
  paddingRight: "10px",
};

export const selectAllStyle = (checkAll) => {
  return {
    display: "flex",
    height: "48px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    color: checkAll ? colors.lightBlue : colors.text01,
    alignItems: "center",
    cursor: "pointer",
    marginTop: "8px",
    marginBottom: "4px",
    gap: "12px",
    backgroundColor: colors.foreground01,
    "&:hover": {
      backgroundColor: colors.gray75,
    },
    "&:active": {
      backgroundColor: colors.gray200,
    },
    "&.Mui-focused": {
      backgroundColor: colors.foreground01,
      border: `1px solid ${colors.borderPressed}`,
    },
    "&.Mui-disabled": {
      backgroundColor: colors.foreground01,
      color: colors.gray300,
    },
  };
};

export const styleAutocomplete = {
  "& .MuiAutocomplete-input": {
    borderRadius: "0px",
    height: "30px",
    padding: "0px 0px 0px 12px !important",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: "0px",
    padding: "0px !important",
  },
  "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
    borderRadius: "5px",
    border: "1px solid #E3E3E3",
  },
  // "& .MuiInputBase-input-MuiOutlinedInput-input": {
  //   fontSize: "1rem",
  // },
  "& .MuiFormControl-root": {
    backgroundColor: "#fff",
    // zIndex: 1301,
  },
  "& .MuiInputBase-root": {
    padding: "0px",
  },
  "& .MuiFormLabel-root": {
    color: "red",
    lineHeight: "1rem",
    top: "-3px",
  },
};

export const Icon2 = () => (
  <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 11.6775C0 11.5119 0.134315 11.3775 0.3 11.3775H2.3C2.46569 11.3775 2.6 11.5119 2.6 11.6775V13.6775C2.6 13.8432 2.46569 13.9775 2.3 13.9775H0.3C0.134315 13.9775 0 13.8432 0 13.6775V11.6775Z"
      fill="#67838E"
    />
    <path
      d="M0 6.47754C0 6.31185 0.134315 6.17754 0.3 6.17754H2.3C2.46569 6.17754 2.6 6.31185 2.6 6.47754V8.47754C2.6 8.64322 2.46569 8.77754 2.3 8.77754H0.3C0.134315 8.77754 0 8.64322 0 8.47754V6.47754Z"
      fill="#67838E"
    />
    <path
      d="M0 1.27754C0 1.11185 0.134315 0.977539 0.3 0.977539H2.3C2.46569 0.977539 2.6 1.11185 2.6 1.27754V3.27754C2.6 3.44322 2.46569 3.57754 2.3 3.57754H0.3C0.134315 3.57754 0 3.44322 0 3.27754V1.27754Z"
      fill="#67838E"
    />
    <path
      d="M5.2 11.6775C5.2 11.5119 5.33431 11.3775 5.5 11.3775H7.5C7.66569 11.3775 7.8 11.5119 7.8 11.6775V13.6775C7.8 13.8432 7.66569 13.9775 7.5 13.9775H5.5C5.33431 13.9775 5.2 13.8432 5.2 13.6775V11.6775Z"
      fill="#67838E"
    />
    <path
      d="M5.2 6.47754C5.2 6.31185 5.33431 6.17754 5.5 6.17754H7.5C7.66569 6.17754 7.8 6.31185 7.8 6.47754V8.47754C7.8 8.64322 7.66569 8.77754 7.5 8.77754H5.5C5.33431 8.77754 5.2 8.64322 5.2 8.47754V6.47754Z"
      fill="#67838E"
    />
    <path
      d="M5.2 1.27754C5.2 1.11185 5.33431 0.977539 5.5 0.977539H7.5C7.66569 0.977539 7.8 1.11185 7.8 1.27754V3.27754C7.8 3.44322 7.66569 3.57754 7.5 3.57754H5.5C5.33431 3.57754 5.2 3.44322 5.2 3.27754V1.27754Z"
      fill="#67838E"
    />
  </svg>
);
