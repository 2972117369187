import moment from "moment";
import { Typography, Box } from "@mui/material";
import { convertLocal, formatDate } from "../../../utils";
import { GridColDef } from "@mui/x-data-grid";
import { CONSTANT } from "../../../constants/constants";
import { colors } from "../../../theme";
import states from "states-us";
import { getRenderPeriod } from "../advancedReport/table/table-helpers";
import { BasicTooltip } from "../../../components/BasicTooltip/BasicTooltip";

const nameToColor = (str: string) => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, "0");
  }
  return color;
};

function addAlpha(color: string, opacity: number) {
  // set values between 0 and 1.
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + _opacity.toString(16).toUpperCase();
}

export interface VRTableProps {
  isPresentationModeEnabled: boolean;
  totalRecordsSummary: number;
  dgm: number;
}

export let defaultVRColumns = (columnsProps: VRTableProps): GridColDef[] => [
  {
    field: "dateCreated",
    headerName: "Date",
    align: "left",
    headerAlign: "left",
    flex: 1,
    type: "date",
    valueFormatter: (props) => {
      // valueFormatter: ({ value }) => {

      return formatDate(new Date(props.value)).fullFormat;
    },
    renderCell: (props) => {
      let text = "";
      let st = "";
      if ("groupedByHours" in props.colDef && props.colDef.groupedByHours === true) {
        text = moment(props.value).format("DD.MM-HH:mm");
        let str = text.split("-");
        text = `${str[0]}`;
        st = `${str[1]}`;
      } else {
        text = getRenderPeriod(props.value, columnsProps.dgm);
      }
      return (
        <Box
          sx={
            {
              // width: "100%",
              // margin: "0px auto",
              // padding: "0 8px",
              // display: "flex",
              // justifyContent: "left",
              // // backgroundColor: "#F0FAFF",
              // borderRadius: "5px",
            }
          }
        >
          <Typography variant="body3XXSmall">
            {text} {Boolean(st) ? " -" : null}
          </Typography>
          {Boolean(st) ? (
            <Typography variant="body3XXSmall" sx={{ fontWeight: 700, marginLeft: "0.5rem" }}>
              {st}
            </Typography>
          ) : null}
        </Box>
      );
    },
  },
  {
    field: CONSTANT.REPORTS.PARTNERS.base,
    headerName: "Partner",
    flex: 1,
    align: "center",
    headerAlign: "center",
    type: "item",
    valueGetter: ({ value }) => {
      return columnsProps.isPresentationModeEnabled ? value?.id : value?.name;
    },
    valueFormatter: ({ value }) => {
      return columnsProps.isPresentationModeEnabled ? Number(value) : value;
    },
    sortComparator: (a, b) => {
      return columnsProps.isPresentationModeEnabled
        ? Number(a) - Number(b)
        : a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
    },
    renderCell: ({ row, value }) => {
      return (
        <Box
          sx={{
            width: "100%",
            // margin: "10px auto 20px",
            padding: "2px 0",
            display: "flex",
            // textAlign: "center",
            justifyContent: "center",
            background: addAlpha(
              nameToColor(
                columnsProps.isPresentationModeEnabled
                  ? row[CONSTANT.REPORTS.PARTNERS.base]?.name
                  : value || "noname"
              ),
              0.3
            ),
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="body3XXSmall"
            sx={{
              marginLeft: "4px",
              fontWeight: 400,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {value}
          </Typography>
        </Box>
      );
    },
  },
  {
    field: "totalRecords",
    headerName: "Total records",
    flex: 1,
    align: "right",
    headerAlign: "right",
    type: "number",
    valueFormatter: ({ value }: { value: number }) => Number(value),
    renderCell: ({ row }) => {
      const percent = Number((row.totalRecords / columnsProps.totalRecordsSummary) * 100);
      return (
        <Box>
          <Typography variant="body3XXSmall">{convertLocal(Number(row.totalRecords))}</Typography>
          {row.actionTerminations && (
            <Typography variant="body3XXSmall">
              {" "}
              <Typography
                variant="body3XXSmall600"
                sx={{
                  color: `${percent < 26 ? colors.secRed100 : colors.secGreen100}`,
                }}
              >
                {`[${percent.toFixed(0)}]%`}
              </Typography>
            </Typography>
          )}
        </Box>
      );
    },
  },
  // {
  //   field: "chart",
  //   headerName: "Chart",
  //   flex: 1,
  //   align: "left",
  //   headerAlign: "left",
  //   // type: "number",
  //   // unit: "currency",
  //   // valueGetter: (param) => convertLocal(Number(param.value).toFixed(2)) + " " + param.row.currency
  //   renderCell: ({ row: { total_cpm2, currency } }: { row: { total_cpm2: number; currency: string } }) => {
  //     return (
  //       <Box
  //         sx={{
  //           width: "100%",
  //           margin: "0px auto",
  //           display: "flex",
  //           justifyContent: "flex-start",
  //           borderRadius: "5px",
  //         }}
  //       >
  //         <AreaChart
  //           width={125}
  //           height={50}
  //           data={data}
  //           margin={{
  //             top: 0,
  //             right: 0,
  //             bottom: 0,
  //             left: -30,
  //           }}
  //         >
  //           <Area dataKey="temperature" stroke="#8884d8" fill="#8884d8" />
  //           <Tooltip />
  //         </AreaChart>
  //       </Box>
  //     );
  //   },
  // },
];
// = (columnsProps: VRTableProps): GridColDef[] =>
export const optionalVRColumns = (columnsProps: VRTableProps): { [key: string]: GridColDef } => {
  return {
    dataSendTotalRecords: {
      field: "dataSendTotalRecords",
      headerName: "Data sent",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ row }) => {
        const total = row.totalRecords && Number(row.totalRecords) !== 0 ? Number(row.totalRecords) : 1;
        const percent = Number((row.dataSendTotalRecords / total) * 100);
        return (
          <Box>
            <Typography variant="body3XXSmall">{convertLocal(Number(row.dataSendTotalRecords))}</Typography>
            <Typography variant="body3XXSmall">
              {" "}
              <Typography
                variant="body3XXSmall600"
                sx={{
                  color: `${percent < 26 ? colors.secRed100 : colors.secGreen100}`,
                }}
              >
                {`[${percent.toFixed(0)}]%`}
              </Typography>
            </Typography>
          </Box>
        );
      },
    },
    totalOnlineRecords: {
      field: "totalOnlineRecords",
      headerName: "Online records",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ value }) => <Typography variant="body3XXSmall">{convertLocal(Number(value))}</Typography>,
    },
    totalUniqueClusters: {
      field: "totalUniqueClusters",
      headerName: "Unique Clusters",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ value }) => <Typography variant="body3XXSmall">{convertLocal(Number(value))}</Typography>,
    },
    totalUniqueCookies: {
      field: "totalUniqueCookies",
      headerName: "Unique Cookies",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ value }) => <Typography variant="body3XXSmall">{convertLocal(Number(value))}</Typography>,
    },
    totalTransactionsWith3rdParty: {
      field: "totalTransactionsWith3rdParty",
      headerName: "Platform ID's",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ value }) => <Typography variant="body3XXSmall">{convertLocal(Number(value))}</Typography>,
    },
    transactionsCoverage: {
      field: "transactionsCoverage",
      headerName: "Platform ID Coverage, %",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "percent",
      sortComparator: (a, b) => a - b,
      valueFormatter: ({ value }: { value: number }) => Number(value).toFixed(0) + "%",
      renderCell: ({ row, value }) => {
        return (
          <Typography
            variant="body3XXSmall600"
            sx={{
              color: `${value < 26 ? colors.secRed100 : colors.secGreen100}`,
            }}
          >
            {Number(value).toFixed(0) + "%"}
          </Typography>
        );
      },
    },
    totalRequests: {
      field: "totalRequests",
      headerName: "Requests",
      flex: 1,
      align: "right",
      headerAlign: "right",
      type: "number",
      valueFormatter: ({ value }: { value: number }) => Number(value),
      renderCell: ({ value }) => <Typography variant="body3XXSmall">{convertLocal(Number(value))}</Typography>,
    },
    deviceTypes: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.DEVICE_TYPES.base,
      headerName: "Device",
      align: "center",
      headerAlign: "center",
      headerClassName: "customHeader_item device_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
    countries: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.COUNTRIES.base,
      headerName: "Country",
      align: "left",
      headerAlign: "left",
      headerClassName: "customHeader_item country_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        let height = 22;
        let width = 24;
        let translateX = 0;
        let name = value?.trim().replace(/ /g, "_").toLowerCase();
        let stateExists = states.some((state) => {
          return state.name.toLowerCase() === value?.trim().toLowerCase();
        });
        try {
          const Icon = stateExists
            ? require(`../../../assets/svgIcons/united_states.svg`).ReactComponent
            : require(`../../../assets/svgIcons/${name}.svg`).ReactComponent;
          return (
            <Box
              sx={{
                // padding: "0px",
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                // margin: "0px",
                gap: "10px",
                textAlign: "center",
              }}
            >
              <Icon id={`${name}-column-icon`} height={height} width={width} />
              <Typography sx={{ paddingTop: "2px" }} variant="body3XXSmall">
                {value}
              </Typography>
            </Box>
          );
        } catch (err) {
          return <Typography variant="body3XXSmall">{value}</Typography>;
        }
      },
    },
    biddercodes: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.BIDDERS.base,
      headerName: "Bidder code",
      headerClassName: "customHeader_item biddercode_customHeader_item",
      align: "center",
      headerAlign: "center",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
    browsers: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.BROWSERS.base,
      headerName: "Browser",
      headerClassName: "customHeader_item browser_customHeader_item",
      flex: 1,
      align: "center",
      headerAlign: "center",
      type: "item",
      cellClassName: "name-column--cell",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        let height = 20;
        let name = value?.trim().toLowerCase();
        try {
          const Icon = require(`../../../assets/svgIcons/${name}.svg`).ReactComponent;
          return <Icon id={`${name}-column-icon`} height={height} />;
        } catch (err) {
          return <Typography variant="body3XXSmall">{value}</Typography>;
        }
      },
    },
    sites: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.SITES.base,
      align: "center",
      headerAlign: "center",
      headerName: "Site",
      headerClassName: "customHeader_item site_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return columnsProps.isPresentationModeEnabled ? value?.id : value?.name;
      },
      valueFormatter: ({ value }) => {
        return columnsProps.isPresentationModeEnabled ? Number(value) : value;
      },
      sortComparator: (a, b) => {
        return columnsProps.isPresentationModeEnabled
          ? Number(a) - Number(b)
          : a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
      },
      renderCell: ({ value }) => {
        return (
          <BasicTooltip tooltipText={value}>
            <Typography variant="body3XXSmall">{value}</Typography>
          </BasicTooltip>
        );
      },
    },
    jsVersions: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.JS_VERSIONS.base,
      align: "center",
      headerAlign: "center",
      headerName: "JS Version",
      headerClassName: "customHeader_item js_version_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => Number(a) - Number(b),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
    actionTerminations: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base,
      align: "center",
      headerAlign: "center",
      headerName: "Termination cause",
      headerClassName: "customHeader_item action_termination_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
    platforms: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base,
      align: "center",
      headerAlign: "center",
      headerName: "Platform",
      headerClassName: "customHeader_item platform_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
    integrations: {
      field: CONSTANT.REPORTS.FILTERS_KEYS.INTEGRATIONS.base,
      align: "center",
      headerAlign: "center",
      headerName: "Integration",
      headerClassName: "customHeader_item platform_customHeader_item",
      flex: 1,
      type: "item",
      valueGetter: ({ value }) => {
        return value?.name;
      },
      sortComparator: (a, b) => a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase()),
      renderCell: ({ value }) => {
        return <Typography variant="body3XXSmall">{value}</Typography>;
      },
    },
  };
};

export const summaryVRColumns: { [key: string]: GridColDef } = {
  firstColumn: {
    field: "total",
    headerName: "Total",
    align: "left",
    headerAlign: "left",
    flex: 1,
    renderCell: () => {
      return <Typography variant="body2XSmallMain600">Total:</Typography>;
    },
  },
  dateCreated: {
    field: "dateCreated",
    headerName: "Date",
    align: "left",
    headerAlign: "left",
    flex: 1,
    type: "date",
    renderCell: ({ value }) => {
      return (
        <Box
          sx={{
            width: "100%",
            margin: "0px auto",
            padding: "0 18px",
            display: "flex",
            justifyContent: "left",
          }}
        >
          {/* <Typography variant="body2XSmallMain600">Total:</Typography> */}
          <Typography variant="body3XXSmall600">{value}</Typography>
        </Box>
      );
    },
  },
  partners: {
    field: CONSTANT.REPORTS.PARTNERS.base,
    headerName: "Partner",
    flex: 1,
    align: "center",
    headerAlign: "center",
    type: "item",
    valueGetter: ({ value }) => {
      return value?.name;
    },
    renderCell: ({ row, value }) => {
      return <Typography variant="body3XXSmall600">{value}</Typography>;
    },
  },
};
