import { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import PercentIcon from "@mui/icons-material/Percent";
import BasicPopover from "../../Popover/BasicPopover";
import SettingsPopup from "../../settings/SettingsPopup";
import Grid from "@mui/material/Grid";
import {
  navbarHeader,
  iconSxHeader,
  headerAvatarDataWrapper,
  headerAvatarItemText,
  headerAvatarItem,
  headerNotificationItem,
  headerAvatarDataWrapperOnTop,
  imgHeaderStls,
} from "./styles";
import BellIcon from "../../IconComponents/BellIcon";
import auth from "../../../auth/auth-helper";
import Loader from "../../Loader";
import useMediaQuery from "@mui/material/useMediaQuery";
import SelectMultiLabelsWrapper from "../../SelectLabels/SelectMultiLabelsWrapper";
import { useTogglePopovers } from "../../../hooks/useTogglePopovers";
import SettingsWrapper from "../../settings/SettingsWrapper";
import VersionInfoPopup from "../../settings/VersionInfoPopup";
import Selection from "../../Selection/Selection";
import { fetchAccountImg } from "../../../accounts/api/accounts-api";
import Fade from "@mui/material/Fade";
import FeedbackForm from "../../Feedbackform/Feedbackform";
import { Badge, Typography } from "@mui/material";
import { CONSTANT } from "../../../constants/constants";
import ReceivedNotifications from "../../../core/notifications/ReceivedNotifications";
import { useGetAccountsQuery, useGetUserByIdQuery } from "../../../accounts/accountsApi";
import { useGetSidebarData } from "../../Sidebar/useGetSidebarData";
import SelectSinglePartner from "../../SelectSinglePartner/SelectSinglePartner";
// import { Badge } from "antd";

const Header = ({ feedbackOpen, setFeedbackOpen, setLastNotice, isOpenByBannerClick, setIsOpenByBannerClick }) => {
  const SidebarData = useGetSidebarData();
  const spanRef = useRef();
  const matches = useMediaQuery("(min-width:1520px)");
  let location = useLocation();
  const { prtnrName, partnersSelected } = useSelector((state) => state.role);
  const { currentPage, isPresentationModeEnabled } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters
  );
  const { data: accounts } = useGetAccountsQuery();

  const navigate = useNavigate();
  const { reminder_is_activated } = useSelector((state) => state.chartSelector);
  const { uniqPairs, selectedPct, selectedCurrency } = useSelector((state) => state.report);
  const [img, setImg] = useState(null);
  const { loggedUser } = useSelector((state) => state.users);
  const { openMenu: setOpenPopover, closeMenu: closePopover, anchorEl: anchorElPopover } = useTogglePopovers();
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);

  const {
    openMenu: setOpenReportSettingsPopover,
    closeMenu: closeReportSettingsPopover,
    anchorEl: anchorElReportSettingsPopover,
  } = useTogglePopovers({ elemRef: spanRef });

  const {
    openMenu: setOpenVersionInfoPopover,
    closeMenu: closeVersionInfoPopover,
    anchorEl: anchorElVersionInfoPopover,
  } = useTogglePopovers({ elemRef: spanRef });

  useEffect(() => {
    fetchImage();
  }, [partnersSelected]);

  const fetchImage = async () => {
    let jwt = auth.isAuthenticated();
    let id = jwt.data?.user?.acctId;
    if (!id || id === jwt.data?.user.acctId) {
      if (partnersSelected?.length === 0 || partnersSelected?.length > 1) {
        setImg(null);
        return;
      }
      const res = await fetchAccountImg(partnersSelected[0]?.acnt_id);
      setImg(res);
      return;
    }

    const res = await fetchAccountImg(id);
    setImg(res);
  };

  const openReportSettingsPopover = (e) => {
    closePopover();
    setOpenReportSettingsPopover(e);
  };

  const openVersionInfoPopover = (e) => {
    closePopover();
    setOpenVersionInfoPopover(e);
  };

  const openProfileInfoPopover = (e) => {
    let jwt = auth.isAuthenticated();
    let id = jwt.data?.user?.id;
    navigate(`/accounts/user_details/${id}`);
    closePopover();
  };

  const isActive = (location, path) => {
    if (location.pathname === path) return true;
  };
  const arr = location.pathname.split("/");
  const userId =
    !isNaN(arr[arr.length - 1]) && arr[1] === "accounts" && arr[2] === "user_details" ? arr[arr.length - 1] : null;
  const accountId =
    !isNaN(arr[arr.length - 1]) && arr[1] === "accounts" && arr[2] !== "user_details" ? arr[arr.length - 1] : null;
  let selectedAccount;
  if (accountId && accounts?.list)
    selectedAccount = accounts.list.find((i) => {
      return i.id === +accountId;
    });

  const { data: userDataById } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });
  let title;
  setTimeout(() => {}, 500);
  if (userId && userDataById) {
    title = (
      <Box sx={{ display: "flex", alignItems: "center" }} key={"user_details_"}>
        {/* <span style={iconSxHeader}>{currentChild.iconHeader}</span> */}
        <Typography id="page-title" variant="h1XXLargeDark800">
          {userDataById.account_id.company}
        </Typography>
      </Box>
    );
  } else {
    if (accountId && selectedAccount) {
      title = (
        <Box sx={{ display: "flex", alignItems: "center" }} key={"user_details_"}>
          {/* <span style={iconSxHeader}>{currentChild.iconHeader}</span> */}
          <Typography id="page-title" variant="h1XXLargeDark800">
            {selectedAccount.company}
          </Typography>
        </Box>
      );
    } else {
      title = SidebarData.map((i, ind) => {
        if (i.children) {
          const currentChild = i.children.find((item) => item.path === location.pathname);
          if (currentChild) {
            return (
              <Fade key={currentChild.path + "_" + ind} in={true} timeout={1000}>
                <Box sx={{ display: "flex", alignItems: "center" }} key={ind + "_" + currentChild.title}>
                  <span style={iconSxHeader}>{currentChild.iconHeader}</span>
                  <Typography id="page-title" variant="h1XXLargeDark800">
                    {currentChild.title}
                  </Typography>
                </Box>
              </Fade>
            );
          }
        }
        if (isActive(location, i.path)) {
          return (
            <Fade key={i.path + "_" + ind} in={true} timeout={1000}>
              <Box sx={{ display: "flex", alignItems: "center" }} key={ind + "_" + i.title}>
                <span style={iconSxHeader}>{i.iconHeader}</span>
                <Typography id="page-title" variant="h1XXLargeDark800">
                  {i.title}
                </Typography>
              </Box>
            </Fade>
          );
        }
      });
    }
  }

  let shouldHideHeaderContent = (location) => {
    let item = SidebarData.find((i) => {
      if (i.children) {
        return i.children.some((child) => child.path.includes(location));
      }
      return i.path.includes(location);
    });
    return item ? item.hideHeaderContent : true;
  };

  let renderActionsBlock = () => {
    if (shouldHideHeaderContent(location.pathname)) return null;
    return (
      <Grid container direction="row" sx={{ justifyContent: "flex-end" }}>
        <Grid item sx={{ display: "flex", paddingRight: "45px" }}>
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              // marginLeft: "7%",
            }}
            sx={{ alignItems: "flex-end" }}
          >
            <Box
              sx={{
                marginRight: "12px",
                marginBottom: "3px",
              }}
            >
              {currentPage.id === CONSTANT.PAGES.CONFIGURATIONS.id ? (
                <SelectSinglePartner />
              ) : (
                <SelectMultiLabelsWrapper />
              )}
            </Box>
          </Box>

          {selectedCurrency && currentPage.id === CONSTANT.PAGES.OVERVIEW.id ? (
            <Box id="currency-button">
              <Box
                sx={{
                  ...headerNotificationItem,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1Small600">{selectedCurrency}</Typography>
              </Box>
            </Box>
          ) : null}
          {/* Notification icon not in usage */}
          {false ? (
            <Box sx={{ margin: "0px 5px" }}>
              <Box sx={headerNotificationItem}>
                <BellIcon />
              </Box>
            </Box>
          ) : null}

          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                ...headerNotificationItem,
                maxWidth: "100px",
                border: "none",
                padding: "0px",
                paddingLeft: "5px",
              }}
            >
              {currentPage.id === CONSTANT.PAGES.OVERVIEW.id && (
                <>
                  {uniqPairs.length > 1 ? (
                    <span style={{ fontSize: "16px", fontWeight: 700 }}>
                      <Box
                        id="percentage-select"
                        sx={{
                          ...headerNotificationItem,
                          minWidth: "120px",
                          padding: "7px 11px",
                        }}
                      >
                        <Selection />
                      </Box>
                    </span>
                  ) : (
                    <span id="selected-persent">
                      <PercentIcon style={{ color: "grey", fontSize: "18px" }} />{" "}
                      <span style={{ fontSize: "16px", fontWeight: 700 }}>
                        {Boolean(selectedPct) ? selectedPct : ""}
                      </span>
                    </span>
                  )}
                </>
              )}
            </Box>
            {prtnrName ? (
              <Box
                sx={{
                  borderRadius: "5px",
                  border: "1px solid #E3E3E3",
                  padding: "14px 12px",
                  fontSize: "1rem",
                  fontWeight: 700,
                  color: "secondary.mainDark",
                  position: "absolute",
                  bottom: 0,
                  right: -150,
                }}
              >
                {prtnrName}
              </Box>
            ) : null}
          </Box>
        </Grid>
      </Grid>
    );
  };

  if (!auth.isAuthenticated()) return <Loader sx={{ background: "rgb(20,48,80)" }} />;

  let userData = auth.isAuthenticated();
  const imageSrc = loggedUser.avatarUrl || CONSTANT.defaultAvatarUrl;

  return (
    <>
      <Grid container direction="row" style={{ paddingBottom: "12px" }}>
        <Grid item sx={{ ...navbarHeader, position: "relative", minWidth: !!img ? "145px" : "auto" }}>
          {shouldHideHeaderContent(location.pathname) ? (
            title
          ) : !!img && !isPresentationModeEnabled ? (
            <Fade in={true} timeout={1000}>
              <Box
                sx={{
                  ...imgHeaderStls,
                  backgroundImage: `url(${img})`,
                }}
              ></Box>
            </Fade>
          ) : (
            <Box sx={{ height: "75px" }}>{title}</Box>
          )}
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          {renderActionsBlock()}
        </Grid>
        <Grid
          item
          className="headerAvatarDataWrapper"
          sx={matches ? headerAvatarDataWrapper : headerAvatarDataWrapperOnTop}
        >
          <Grid container direction="row" id="avatar-container" sx={{ alignItems: "center" }}>
            <Grid item sx={{ marginRight: "10px" }}>
              <Badge
                color="error"
                badgeContent={unreadMessagesCount}
                max={CONSTANT.OVERFLOW_UNREAD_MESSAGES}
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "11px",
                  },
                }}
              >
                <ReceivedNotifications
                  unreadMessagesCount={unreadMessagesCount}
                  setUnreadMessagesCount={setUnreadMessagesCount}
                  setLastNotice={setLastNotice}
                  isOpenByBannerClick={isOpenByBannerClick}
                  setIsOpenByBannerClick={setIsOpenByBannerClick}
                />
              </Badge>
            </Grid>
            <Grid item sx={{ ...headerAvatarItemText, marginLeft: "16px" }} id="greeting">
              {matches && !reminder_is_activated ? <Box sx={{ fontSize: "1.5rem" }}>Hello,</Box> : null}
              {matches && userData ? (
                // {matches && !reminder_is_activated && userData ? (
                <>
                  <Box sx={{ fontSize: "1.5rem", fontWeight: 700 }}>{loggedUser.firstName}</Box>
                  <Box sx={{ fontSize: "1.5rem", fontWeight: 700 }}>{loggedUser.lastName}</Box>
                </>
              ) : null}
            </Grid>
            <Grid item sx={{ cursor: "pointer" }}>
              <ListItemAvatar ref={spanRef} id="avatar-icon" onClick={setOpenPopover}>
                {imageSrc ? (
                  <Avatar
                    sx={headerAvatarItem(matches, 1)}
                    alt={`${userData?.data?.user?.firstName[0] ?? "A"} ${userData?.data?.user?.lastName[0] ?? ""}`}
                    src={imageSrc}
                  />
                ) : (
                  <Avatar sx={headerAvatarItem(matches)}>
                    {userData?.data?.user?.firstName[0] ?? "A"} {userData?.data?.user?.lastName[0] ?? ""}
                  </Avatar>
                )}
              </ListItemAvatar>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* All Options popover */}
      <BasicPopover
        anchorEl={anchorElPopover}
        setAnchorEl={setOpenPopover}
        closePopover={closePopover}
        popoverWidth={"300px"}
      >
        <SettingsWrapper
          setAnchorEl={setOpenPopover}
          closePopover={closePopover}
          openReportSettingsPopover={openReportSettingsPopover}
          openVersionInfoPopover={openVersionInfoPopover}
          openProfileInfoPopover={openProfileInfoPopover}
        />
      </BasicPopover>

      {/* Report settings popover */}
      <BasicPopover
        anchorEl={anchorElReportSettingsPopover}
        setAnchorEl={setOpenReportSettingsPopover}
        closePopover={closeReportSettingsPopover}
        popoverWidth={"700px"}
      >
        <SettingsPopup closeReportSettingsPopover={closeReportSettingsPopover} />
      </BasicPopover>

      {/* Version info popover */}
      <BasicPopover
        anchorEl={anchorElVersionInfoPopover}
        setAnchorEl={setOpenVersionInfoPopover}
        closePopover={closeVersionInfoPopover}
        popoverWidth={"500px"}
      >
        <VersionInfoPopup />
      </BasicPopover>

      {/* Feedback popup */}
      <FeedbackForm feedbackOpen={feedbackOpen} setFeedbackOpen={setFeedbackOpen} />
    </>
  );
};

export default Header;
