import React from "react";
import { Box, Button, ListItemButton, TextField, Typography, Checkbox } from "@mui/material";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import SearchIcon from "@mui/icons-material/Search";
import { partnerDropdownTextFieldStyle } from "../SelectLabels/SelectMultiLabelsWrapper";

export interface DropdownFilterProps {
  options: { id: string; name: string }[];
  selectedItem: { id: string; name: string } | null;
  onSelectItem: (id: string) => void;
  onSearch: (value: string) => void;
}

const LIST_ITEM_HEIGHT = 40;
const ITEMS_LIST_MAX_HEIGHT = 278;

export const SimpleDropdown: React.FC<DropdownFilterProps> = ({
  options,
  selectedItem,
  onSelectItem,
  onSearch,
}) => {
  const Row: React.FC<ListChildComponentProps> = ({ index, style }) => {
    const option = options[index];
    const isSelected = selectedItem?.id === option.id;

    return (
      <ListItemButton
        key={option.id}
        onClick={() => onSelectItem(option.id)}
        style={{
          ...style,
          padding: "8px ",
          gap: "12px",
          backgroundColor: isSelected ? "#E3F2FD" : "transparent", // Light blue for selected background
        }}
      >
        <Typography variant="body1Small400" sx={{ fontWeight: isSelected ? "bold" : "normal" }}>
          {option.name}
        </Typography>
      </ListItemButton>
    );
  };

  return (
    <Box sx={{ bgcolor: "background.paper", borderRadius: "16px", padding: "6px" }}>
      <TextField
        variant="outlined"
        fullWidth
        placeholder="Search"
        onChange={(e) => onSearch(e.target.value)}
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        sx={partnerDropdownTextFieldStyle}
      />
      <Box sx={{}}>
        <FixedSizeList
          height={ITEMS_LIST_MAX_HEIGHT}
          itemCount={options.length}
          itemSize={LIST_ITEM_HEIGHT}
          width="100%"
        >
          {Row}
        </FixedSizeList>
      </Box>
    </Box>
  );
};
