import React, { CSSProperties, useState } from "react";
import type { UploadFile, UploadProps } from "antd";
import { message, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { alpha, Box, Typography } from "@mui/material";
import { colors } from "../../theme";
import UploadIcon from "../IconComponents/UploadIcon";
import { CONSTANT } from "../../constants/constants";
const { Dragger } = Upload;

interface UploadFormProps {
  fileList: UploadFile[];
  setFileList: React.Dispatch<React.SetStateAction<UploadFile[]>>;
  disabled: boolean;
}

const UploadForm: React.FC<UploadFormProps> = ({ fileList = [], setFileList, disabled }) => {
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const MAX_FILES = 3;
  const MAX_SIZE = 25 * 1024 * 1024; // 25MB in bytes
  const ALLOWED_TYPES = CONSTANT.ALLOWED_FILE_IMAGE_TYPES;

  const customRequest = async ({ file, onSuccess, onError, onProgress }: any) => {
    const reader = new FileReader();

    reader.onloadstart = () => {
      setUploadProgress(0);
    };

    reader.onprogress = (event) => {
      if (event.lengthComputable) {
        const progress = (event.loaded / event.total) * 100;
        setUploadProgress(Math.round(progress));
        onProgress({ percent: progress });
      }
    };

    reader.onload = async () => {
      setUploadProgress(100);
      console.log("File read completed");
      try {
        // Here you would typically send the file to your server
        // This is a placeholder for your actual upload logic
        // await uploadFileToServer(file);
        console.log("Upload completed");
        onSuccess("ok");
      } catch (error) {
        console.error("Upload failed", error);
        onError({ error });
      }
    };
    reader.onerror = () => {
      console.error("File reading failed");
      onError({ error: new Error("File reading failed") });
    };
    reader.readAsArrayBuffer(file as Blob);
  };

  const isDuplicateFile = (file: RcFile) => {
    return (
      fileList.length > 0 &&
      fileList.some((existingFile) => existingFile.name === file.name && existingFile.size === file.size)
    );
  };

  const beforeUpload = (file: RcFile) => {
    const isAllowedType = ALLOWED_TYPES.includes(file.type);
    if (!isAllowedType) {
      message.error(`${file.name} is not a jpg, jpeg, or png file`);
      return Upload.LIST_IGNORE;
    }
    const isLt25M = file.size <= MAX_SIZE;
    if (!isLt25M) {
      message.error("File must be smaller than 25MB!");
      return Upload.LIST_IGNORE;
    }
    const totalSize = fileList.reduce((acc, file) => acc + (file?.size ?? 0), 0) + file.size;
    if (totalSize > MAX_SIZE) {
      message.error("Total file size exceeds 25MB!");
      return Upload.LIST_IGNORE;
    }
    if (fileList.length >= MAX_FILES) {
      message.error(`You can only upload up to ${MAX_FILES} files.`);
      return Upload.LIST_IGNORE;
    }
    if (isDuplicateFile(file)) {
      message.error(`${file.name} is already in the list.`);
      return Upload.LIST_IGNORE;
    }
    return true;
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    customRequest,
    fileList,
    beforeUpload,
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        // console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      // Additional check for total file size
      const totalSize = info.fileList.reduce((acc, file) => acc + (file.size || 0), 0);
      if (totalSize > MAX_SIZE) {
        message.error("Total file size exceeds 25MB!");
        const newFileList = info.fileList.slice(0, -1); // Remove the last added file
        setFileList(newFileList);
      } else if (info.fileList.length <= MAX_FILES) {
        setFileList(info.fileList);
      } else {
        message.error(`You can only upload up to ${MAX_FILES} files.`);
        const newFileList = info.fileList.slice(0, MAX_FILES);
        setFileList(newFileList);
      }
    },
    onDrop(e) {
      //   console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const draggerStyle: CSSProperties = {
    display: "flex",
    padding: "24px",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    borderRadius: "12px",
    border: `1px ${colors.primaryCta40}`,
    background: `${colors.primaryCta5}`,
    borderStyle: "dashed",
  };

  return (
    <Box
      id="upload-form"
      sx={{
        "& .ant-upload-wrapper": {
          display: "flex !important",
          flexDirection: "column !important",
        },
        "& .ant-upload-wrapper .ant-upload-list": {
          order: "1 !important",
          marginBottom: "16px !important",
        },
        "& .ant-upload-wrapper .ant-upload": {
          order: "2 !important",
        },
        "& .ant-upload-wrapper .ant-upload-drag .ant-upload-drag-container": {
          padding: "0 !important",
          width: "383px",
          height: "80px",
        },
        ".css-dev-only-do-not-override-gzal6t.ant-upload-wrapper .ant-upload-drag": {
          order: "2 !important",
          padding: "0 !important",
        },
      }}
    >
      <Dragger
        {...props}
        listType="picture"
        style={{
          ...draggerStyle,
          background: disabled ? alpha(colors.backgroudDisabled, 1) : colors.primaryCta5,
        }}
        disabled={disabled}
        id="file-upload-input"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <Typography
            className="ant-upload-drag-icon"
            variant="body2XSmall"
            sx={{ margin: "0", width: "24px", height: "24px" }}
          >
            <UploadIcon />
          </Typography>
          <Typography
            className="ant-upload-text"
            variant="body2XSmall"
            sx={{ color: colors.primaryDarkBlue9main }}
          >
            <strong>Click to upload</strong> or drag and drop
          </Typography>
          <Typography className="ant-upload-hint" variant="bodySmallRegular">
            (Max. File size: 25 MB)
          </Typography>
        </Box>
      </Dragger>
      {uploadProgress > 0 && uploadProgress < 100 && <div>Upload Progress: {uploadProgress}%</div>}
    </Box>
  );
};

export default UploadForm;
