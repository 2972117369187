export const navbarHeader = {
  marginTop: "4.0625rem",
  paddingLeft: "2.5rem",
};

export const headerTitle = {
  fontWeight: 700,
  fontSize: "3rem",
  color: "#001738",
};

export const iconSxHeader = {
  left: "-0.75rem",
  position: "relative",
  top: 0,
};

export const imgHeaderStls = {
  width: "140px",
  height: "95px",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  backgroundPosition: "center",
  position: "absolute",
  top: "-20px",
  left: "11px",
};

export const headerAvatarDataWrapper = {
  // minWidth: "340px",
  marginTop: "3.125rem",
};

export const headerAvatarDataWrapperOnTop = {
  // minWidth: "340px",
  marginTop: null,
  position: "absolute",
  top: "10px",
  right: "0px",
};

export const headerAvatarItemText = {
  // minWidth: "230px",
  paddingRight: "12px",
  paddingTop: "0.875rem",
  // textAlign: "right"
};

export const headerAvatarItem = (isOnTop, hideBackground = 0) => {
  return {
    backgroundColor: hideBackground ? "none" : "#131330",
    width: isOnTop ? 75 : 45,
    height: isOnTop ? 75 : 45,
    fontSize: isOnTop ? "1.5rem" : "1rem",
    boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.2)",
  };
};

export const headerNotificationItem = {
  fontSize: "1.5rem",
  marginTop: "3.875rem",
  borderRadius: "5px",
  border: "1px solid #E3E3E3",
  padding: "6px 10px",
  height: "46px",
};

export const btnGroupWrapper = {
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
  borderRadius: "5px",
  "& .MuiButton-root": {
    height: "46px",
    minWidth: "130px",
    border: "1px solid #E5E5E5",
    padding: "6px 10px",
    fontSize: "1.125rem",
    backgroundColor: "#FAFAFA",
    borderBottomRightRadius: "5px",
    cursor: "pointer",
    fontWeight: "600",
    color: "secondary.main",
    "&:first-of-type": {
      borderTopLeftRadius: "5px",
      borderRight: "none",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "0px",
      borderTopRightRadius: "0px",
      backgroundColor: "rgba(27, 178, 255, 0.15)",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderLeft: "none",
    },
  },
};
