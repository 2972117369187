import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { PieChart, Pie, Cell, Label, Tooltip } from "recharts";
import {
  COLORS,
  renderListLegend,
  styleLegend,
} from "../../advancedReport/reportCharts/PieChart/pieChartWidget-utils";
import { convertLocal } from "../../../../utils";
import { ChartData } from "../../types";
import { ISelectableItem } from "../../../../components/SelectItems/interfaces";
import { CONSTANT } from "../../../../constants/constants";
import { VRChartCustomTooltip } from "./VRChartCustomTooltip";

const nameToColor = (str: string): string => {
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });

  let color = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, "0");
  }
  return color;
};

function addAlpha(color: string, opacity: number) {
  // set values between 0 and 1.
  let _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);

  return color + _opacity.toString(16).toUpperCase();
}
const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: any;
  cy: any;
  midAngle: any;
  innerRadius: number;
  outerRadius: number;
  percent: any;
  index: any;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text style={{ fontSize: "0.75rem" }} x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central">
      {percent * 100 >= 5 ? ` ${(percent * 100).toFixed(0)}%` : ""}
    </text>
  );
};

export const VRPieChart = (props: any) => {
  const title =
    props.filtersType === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base
      ? "ActionTermination"
      : props.filtersType === CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base
        ? "Platform ID's"
        : "";
  let dataWithColors = props.data
    .slice()
    .sort((a: ChartData, b: ChartData) => Number(b.value) - Number(a.value))
    .map((item: ChartData, index: number) => {
      return { ...item, color: COLORS[index % COLORS.length] };
      // return { ...item, color: addAlpha(nameToColor(item.name), 0.7) };
    });

  return (
    <>
      <Box sx={{ color: "secondary.mainDark", position: "relative" }}>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Box id="vr-pie-chart-title">{title}</Box>
          {props.filtersType === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base && (
            <FormControl sx={{ width: 120, height: "24px" }} size="small">
              <InputLabel id="vr-pie-chart-select-label">Records</InputLabel>
              <Select
                MenuProps={{
                  slotProps: {
                    paper: {
                      style: {
                        padding: "0",
                        border: "1px solid #BEBEBE",
                        borderRadius: "8x !important",
                        boxShadow: "0px 5px 10px #E9EBF1",
                        marginTop: "6px",
                      },
                    },
                  },
                }}
                sx={{ padding: "0", borderRadius: "8x !important" }}
                labelId="vr-pie-chart-select-label"
                id="vr-pie-chart-select"
                value={props.selectedChartItem}
                label="Records"
                onChange={props.handleChartDataSelection}
              >
                {props.pieChartSelectionItems.map((item: ISelectableItem) => (
                  <MenuItem id="vr-pie-chart-select-item" sx={{ height: "30px" }} key={item.id} value={item.selectionName}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        </Box>
        <Box id="vr-pie-chart-summary" sx={{ fontSize: "2rem", fontWeight: 700 }}>
          {/* {convertLocal(props.healthStatusRateTotalRecordsBySelection)}{" "} */}
          {convertLocal(props.totalSummaryByType)}
          {props.filtersType === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base && (
            <Typography sx={{ color: "silver", fontWeight: 400, fontSize: "0.825rem" }}>records</Typography>
          )}
        </Box>
      </Box>
      <Grid container sx={{ height: "180px" }}>
        <Grid item sx={{ width: "50%", height: "180px" }}>
          <PieChart id="vr-pie-chart" width={180} height={180}>
            <Pie
              id="vr-pie-chart-pie"
              data={dataWithColors}
              cx={80}
              cy={80}
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={85}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              <Label position="center" />
              {dataWithColors.map((entry: { color: string | undefined }, index: any) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip content={VRChartCustomTooltip} />
          </PieChart>
        </Grid>

        <Grid item sx={{ width: "50%" }}>
          <Box id="vr-pie-chart-legend" sx={styleLegend}>{renderListLegend(dataWithColors, "name", "value", false)}</Box>
        </Grid>
      </Grid>
    </>
  );
};
