import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  COMPOSITE_SETTINGS_KEYS,
  ParametersTypes,
  ChartBaseUnits,
  CommonUserPlatformParameters,
  OverviewParameters,
  UserParametersConstancyPageType,
  PlatformParameters,
  ChangedParameters,
  PerformanceReportParameters,
  AccountsParameters,
  FilterGroup,
  ResponseParametersDto,
  tablesRowsAmountPerPageOptions,
  ConfigurationParameters,
} from "./parametersConstancyTypes";
import { storeParametersConstancy } from "./parametersContancyFetches";
import { CONSTANT } from "../constants/constants";

const initCommonPlatformParameters: CommonUserPlatformParameters = {
  currentPage: CONSTANT.PAGES.OVERVIEW,
  selectedPartnersDummyIds: [],
  currentMultiselectGroupId: null,
  customDateRange: { customDateStart: null, customDateEnd: null },
  dateGroupingMode: CONSTANT.DATE_GROUP_MODES.DAY,
  dateRange: { type: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.type, value: CONSTANT.DATE_RANGE_TYPES.LAST_WEEK.value },
  isPresentationModeEnabled: false,
  tablesRowsAmountPerPage: tablesRowsAmountPerPageOptions[0],
};
const initPerformanceReportParameters: PerformanceReportParameters = {
  activeAreaChartType: "",
  areaChartCurrenciesToDisplay: {},
  filters: {},
  activeFilterGroupId: null,
  columnVisibilityModel: {},
  columnVisibilityModelVR: {},
};
const initOverviewParameters: OverviewParameters = {
  groupType: "",
  chartType: 0,
  chartBase: "",
  chartBaseUnits: {},
  reporterType: 0,
  selectedPct: null,
  selectedCurrency: null,
  settingsStrictMode: false,
};
const initAccountsParameters: AccountsParameters = {
  accountsTableView: false,
};

const initConfigurationParameters: ConfigurationParameters = {
  selectedPartnerId: null,
};
const initPlatformParameters: ParametersTypes = {
  commonUserPlatformParameters: initCommonPlatformParameters,
  performanceReportParameters: initPerformanceReportParameters,
  overviewParameters: initOverviewParameters,
  accountsParameters: initAccountsParameters,
  configurationParameters: initConfigurationParameters,
};
const initChangedParameters: ChangedParameters = {
  commonUserPlatformParameters: {},
  performanceReportParameters: {},
  overviewParameters: {},
  accountsParameters: {},
  configurationParameters: {},
};

const initialState: PlatformParameters = {
  currentPlatformParameters: initPlatformParameters,
  changedParameters: initChangedParameters,
  currentTemplate: {
    id: CONSTANT.DEFAULT_TEMPLATE_PLATFORM_PARAMETRS.id,
    name: CONSTANT.DEFAULT_TEMPLATE_PLATFORM_PARAMETRS.name,
  },
  isStoring: false,
  isFetched: false,
  storeError: null,
};

export const platformParametersSlice = createSlice({
  name: "platformParameters",
  initialState,
  reducers: {
    setChangedParameter<
      T extends keyof UserParametersConstancyPageType,
      K extends keyof UserParametersConstancyPageType[T]
    >(
      state: PlatformParameters,
      action: PayloadAction<{
        blockType: T;
        parametersKey: K;
        payload: UserParametersConstancyPageType[T][K];
      }>
    ) {
      const { blockType, parametersKey, payload } = action.payload;

      if (parametersKey === COMPOSITE_SETTINGS_KEYS.OVERVIEW) {
        const chartBase = Object.keys(payload as ChartBaseUnits)[0];
        const overviewParameters = state.changedParameters.overviewParameters as OverviewParameters;

        if (overviewParameters.chartBaseUnits) {
          overviewParameters.chartBaseUnits[chartBase] = Object.values(payload as ChartBaseUnits)[0];
        } else {
          (state.changedParameters[blockType] as { [key in K]: UserParametersConstancyPageType[T][K] })[
            parametersKey
          ] = payload;
        }
      } else {
        (state.changedParameters[blockType] as { [key in K]: UserParametersConstancyPageType[T][K] })[
          parametersKey
        ] = payload;
      }
    },
    setCurrentCommonParameter<T extends keyof CommonUserPlatformParameters>(
      state: PlatformParameters,
      action: PayloadAction<{
        parametersKey: T;
        payload: CommonUserPlatformParameters[T];
      }>
    ) {
      const { parametersKey, payload } = action.payload;
      state.currentPlatformParameters.commonUserPlatformParameters[parametersKey] = payload;
    },
    setCurrentPlatformParameter<T extends keyof ParametersTypes, K extends keyof ParametersTypes[T]>(
      state: PlatformParameters,
      action: PayloadAction<{
        blockType: T;
        parametersKey: K;
        payload: ParametersTypes[T][K];
      }>
    ) {
      const { blockType, parametersKey, payload } = action.payload;
      state.currentPlatformParameters[blockType][parametersKey] = payload;
    },
    setCurrentTemplate(state, action: PayloadAction<number>) {
      state.currentTemplate = { id: action.payload, name: CONSTANT.DEFAULT_TEMPLATE_PLATFORM_PARAMETRS.name };
    },
    setPresentationMode(state, action: PayloadAction<boolean>) {
      state.currentPlatformParameters.commonUserPlatformParameters.isPresentationModeEnabled = action.payload;
      state.changedParameters.commonUserPlatformParameters.isPresentationModeEnabled = action.payload;
    },
    setAccountsTableView(state, action: PayloadAction<boolean>) {
      state.currentPlatformParameters.accountsParameters.accountsTableView = action.payload;
      state.changedParameters.accountsParameters.accountsTableView = action.payload;
    },
    resetAllParameters: () => initialState,
    resetChangedParameters: (state) => {
      state.changedParameters = initChangedParameters;
    },
    setParametersIsFetched: (state, action: PayloadAction<boolean>) => {
      state.isFetched = action.payload;
    },
    setSelectedPartnersDummyIds: (state, action: PayloadAction<any[]>) => {
      state.currentPlatformParameters.commonUserPlatformParameters.selectedPartnersDummyIds = action.payload;
    },
    setCurrentMultiselectGroupId: (state, action: PayloadAction<number | null>) => {
      state.currentPlatformParameters.commonUserPlatformParameters.currentMultiselectGroupId = action.payload;
    },
    addFilterGroup: (state, action: PayloadAction<FilterGroup>) => {
      state.currentPlatformParameters.performanceReportParameters.activeFilterGroupId = action.payload.id ?? null;
      state.changedParameters.performanceReportParameters.activeFilterGroupId = action.payload.id;
    },
    setParametersEntrySettings(state, action: PayloadAction<ResponseParametersDto>) {
      state.currentPlatformParameters.commonUserPlatformParameters.dateRange =
        action.payload.commonUserPlatformParameters.dateRange;
      state.currentPlatformParameters.commonUserPlatformParameters.customDateRange =
        action.payload.commonUserPlatformParameters.customDateRange;
      state.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode =
        action.payload.commonUserPlatformParameters.dateGroupingMode;
      state.currentPlatformParameters.commonUserPlatformParameters.tablesRowsAmountPerPage =
        action.payload.commonUserPlatformParameters.tablesRowsAmountPerPage ?? tablesRowsAmountPerPageOptions[0];
      state.currentTemplate = {
        id: action.payload.currentTemplate.id,
        name: action.payload.currentTemplate.name,
      };
      state.currentPlatformParameters.performanceReportParameters.columnVisibilityModel =
        action.payload.performanceReportParameters.columnVisibilityModel ?? {};
      state.currentPlatformParameters.performanceReportParameters.columnVisibilityModelVR =
        action.payload.performanceReportParameters.columnVisibilityModelVR ?? {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(storeParametersConstancy.pending, (state) => {
        state.isStoring = true;
        state.storeError = null;
      })
      .addCase(storeParametersConstancy.fulfilled, (state) => {
        state.isStoring = false;
      })
      .addCase(storeParametersConstancy.rejected, (state, action) => {
        state.isStoring = false;
        state.storeError = action.error.message || "Store failed";
      });
  },
});

export const {
  setChangedParameter,
  resetAllParameters,
  resetChangedParameters,
  setPresentationMode,
  setAccountsTableView,
  setParametersIsFetched,
  setSelectedPartnersDummyIds,
  setCurrentMultiselectGroupId,
  setCurrentCommonParameter,
  setCurrentPlatformParameter,
  addFilterGroup,
  setParametersEntrySettings,
  setCurrentTemplate,
} = platformParametersSlice.actions;

export default platformParametersSlice.reducer;
