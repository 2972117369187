import React, { useEffect } from "react";
import PaginationItem from "@mui/material/PaginationItem";
import { Box, Pagination as PaginationBase, TablePagination } from "@mui/material";
import {
  gridPageCountSelector,
  gridPageSelector,
  gridRowCountSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid";
import { gridPageSizeSelector } from "@mui/x-data-grid/hooks/features/pagination/gridPaginationSelector";
import { paginationStl } from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCommonParameterWrapper } from "../../../../../parametersConstancy/parametersConstancyUtils";
import { setCurrentCommonParameter } from "../../../../../parametersConstancy/platformParametersSlice";
import { CONSTANT } from "../../../../../constants/constants";

function CustomLiItemWithId({ props2, testingId }) {
  return (
    <div id={`pagination-item-${testingId}`}>
      <PaginationItem
        {...props2}
        // disableRipple
      />
    </div>
  );
}

export function CustomPagination({ rowsPerPageOptions, hidden = false }) {
  const tablesRowsAmountPerPage = useSelector(
    (state) =>
      state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.tablesRowsAmountPerPage ??
      rowsPerPageOptions[0],
  );

  const dispatch = useDispatch();

  const gridApiContext = useGridApiContext();

  const page = useGridSelector(gridApiContext, gridPageSelector);
  const rowCount = useGridSelector(gridApiContext, gridRowCountSelector);
  const pageCount = useGridSelector(gridApiContext, gridPageCountSelector);
  const pageSize = useGridSelector(gridApiContext, gridPageSizeSelector);

  // useEffect(() => {
  //   if (hidden) {
  //     gridApiContext.current.setPageSize(parseInt(rowsPerPageOptions[0], 10));
  //     gridApiContext.current.setPage(0);
  //   }
  // }, []);

  useEffect(() => {
    if (gridApiContext) {
      gridApiContext.current.setPageSize(parseInt(tablesRowsAmountPerPage, 10));
      //gridApiContext.current.setPage(0);
    }
  }, [tablesRowsAmountPerPage]);

  const handleChangePage = (event, newPage) => {
    gridApiContext.current.setPage(newPage);
  };

  const handleChangePageSize = (event) => {
    gridApiContext.current.setPageSize(parseInt(event.target.value, 10));
    dispatch(
      setCurrentCommonParameter(
        setCurrentCommonParameterWrapper("tablesRowsAmountPerPage", parseInt(event.target.value, 10)),
      ),
    );
    gridApiContext.current.setPage(0);
  };

  return (
    <Box sx={{ ...paginationStl, zIndex: 1000 }}>
      <TablePagination
        id="pagination-container"
        slotProps={{
          select: {
            id: "per-page-select"
          }
        }}
        className="pagination-container"
        // labelRowsPerPage={"Number of rows"}
        component="div"
        count={rowCount}
        hidden={hidden}
        page={page}
        rowsPerPage={pageSize}
        onRowsPerPageChange={handleChangePageSize}
        onPageChange={handleChangePage}
        rowsPerPageOptions={rowsPerPageOptions}
        ActionsComponent={() => (
          <PaginationBase
            className="pagination-pages"
            color="primary"
            variant="outlined"
            shape="rounded"
            page={page + 1}
            count={pageCount}
            onChange={(event, page) => handleChangePage(event, page - 1)}
            renderItem={(props2) => {
              if (props2.type.toLowerCase() === "next" || props2.type.toLowerCase() === "previous") {
                //for testing purpose - add id to next/prev btn, to be removed after testing
                return <CustomLiItemWithId props2={props2} testingId={props2.type.toLowerCase()} />;
              } else {
                return (
                  <PaginationItem
                    id={`page-${props2.page}-btn`}
                    // slots={{ previous: () => <ArrowBackIcon />, next: ArrowForwardIcon }}
                    {...props2}
                    // disableRipple
                  />
                );
              }
            }}
          />
        )}
      />
    </Box>
  );
}
