import { CONSTANT } from "../../constants/constants";
import { RequestPeriodType } from "../../parametersConstancy/parametersConstancyTypes";

export const createMTDPeriod = (): RequestPeriodType[] => {
  const today = new Date();
  const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  const from = new Date(Date.UTC(todayUTC.getFullYear(), todayUTC.getMonth(), 1));
  const to = todayUTC;

  return [{ period: "MTD", from, to }];
};

export const createLastMonthPeriod = (): RequestPeriodType[] => {
  const today = new Date();
  const to = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 0));
  today.setDate(0);
  const from = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), 1));
  const month = from.toLocaleString("default", { month: "long" });
  return [{ period: `${month} ${today.getUTCFullYear()}`, from, to }];
};

export const getCustomFetchPeriod = (chosenMonths: string[]): RequestPeriodType[] => {
  const sortedMonths = [...chosenMonths].sort((a, b) => {
    const months = Object.values(CONSTANT.MONTHS);
    return months.indexOf(a) - months.indexOf(b);
  });
  const currentYear = new Date().getFullYear() - 1; //FIXME change to dinamic year
  return sortedMonths.map((month) => getFirstAndLastDayOfMonth(month, currentYear));
};

const getFirstAndLastDayOfMonth = (month: string, year: number): RequestPeriodType => {
  const monthIndex = Object.keys(CONSTANT.MONTHS).indexOf(month.toLowerCase()) + 1;
  // Get the local first day of the month
  const localFrom = new Date(year, monthIndex, 1);
  // Convert local first day to UTC
  const from = new Date(Date.UTC(localFrom.getUTCFullYear(), localFrom.getUTCMonth(), 1));
  // Get the local last day of the month
  const localTo = new Date(year, monthIndex + 1, 0);
  // Convert local last day to UTC and set the time to the end of the day
  const to = new Date(Date.UTC(localTo.getUTCFullYear(), localTo.getUTCMonth(), 0));

  return {
    period: `${month} 2023`,
    from,
    to,
  };
};
