import moment from "moment";
import { convertLocal, nFormatter } from "../../utils";
import { CONSTANT } from "../../constants/constants";

export const renderColorfulLegendTextAreaChart = (value) => {
  let valStr = "";

  switch (value) {
    case "extrapolationWithoutIIQ":
      valStr = "Extrapolation revenue without IIQ";
      break;

    case "revenueWithIIQAsStackedVal":
      valStr = "Revenue with IIQ";
      break;
  }
  return <span style={{ color: "#00385D", fontSize: "1rem" }}>{valStr}</span>;
};

export const CustomizedAxisTick = (props) => {
  let { x, y, payload } = props; // "stroke" is argument as well
  let { dx, dy } = 0;
  let monthsArray = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  let textAnchor = "middle";
  let verticalAnchor = "middle";
  let ticksText = "";
  let ticksTextYPos = 0;
  let st = "";
  if (props.className.includes("xAxis")) {
    const d = new Date(payload.value);
    let day = d.getUTCDate();
    let hour = d.getHours();
    let month = d.getUTCMonth() + 1;

    verticalAnchor = "end";
    switch (props.dgm) {
      case CONSTANT.DATE_GROUP_MODES.MONTH: {
        ticksText = monthsArray[month - 1];
        break;
      }
      case CONSTANT.DATE_GROUP_MODES.QUARTER: {
        ticksText = monthsArray[month - 1];
        break;
      }
      case CONSTANT.DATE_GROUP_MODES.HOUR: {
        ticksText = moment(payload.value).format("DD.MM-HH:mm");
        let str = ticksText.split("-");
        ticksText = `${str[0]}`;
        st = `${str[1]}`;
        break;
      }
      default: {
        ticksText = `${day}/${month}`;
      }
    }
    ticksTextYPos = 15;
  } else if (props.className.includes("yAxis")) {
    dx = -20;
    const tickDigits = 4;
    textAnchor = "end";
    ticksText = payload.value === 0 ? 0 : nFormatter(payload.value, tickDigits);
  }

  return (
    <g transform={`translate(${x},${y + ticksTextYPos})`}>
      <title>{payload.value}</title>
      {st ? (
        <text
          x={0}
          y={0}
          dx={dx}
          dy={dy}
          textAnchor={textAnchor}
          vertical-anchor={verticalAnchor}
          // transform="translate(0 3.5)"
          // transform="rotate(45)"
          fill="#001738"
          style={{ fontSize: "0.75rem" }}
        >
          {st}
        </text>
      ) : null}
      <text
        x={0}
        y={st ? 15 : 0}
        dx={dx}
        dy={dy}
        textAnchor={textAnchor}
        vertical-anchor={verticalAnchor}
        // transform="translate(0 3.5)"
        // transform="rotate(45)"
        fill="#67838E"
        style={{ fontSize: st ? "0.65rem" : "0.75rem" }}
      >
        {ticksText}
        <b>{st}</b>
      </text>
    </g>
  );
};

export const CustomTooltipSummaryLineChart = (props) => {
  let { active, payload } = props;
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          minWidth: 170,
          padding: 10,
          borderRadius: 8,
          border: "1px solid rgba(0, 56, 93, 0.075)",
          color: "#00385D",
          fontSize: "0.9rem",
        }}
      >
        <p
          style={{
            color: "#001738",
            marginTop: 0,
            marginBottom: "20px",
            fontWeight: "600",
          }}
        >
          {payload[0].payload.date}
        </p>
        <div style={{ display: "flex", fontSize: "1rem", marginBottom: "7px" }}>
          {/* <p style={{ marginTop: 0, marginBottom: 4 }}> */}
          <p
            style={{
              color: payload[1].color,
              paddingRight: 5,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            &#11044;
          </p>
          <p style={{ marginTop: 0, marginBottom: 0 }}>With IIQ:</p>
          {/* </p> */}
          <p
            style={{
              fontWeight: "600",
              marginLeft: "5px",
              marginTop: 0,
              marginBottom: 0,
            }}
          >{`${convertLocal(payload[1].value.toFixed(1))}`}</p>
        </div>
        <div style={{ display: "flex", fontSize: "1rem" }}>
          <p
            style={{
              color: payload[0].color,
              paddingRight: 5,
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            &#11044;
          </p>
          <p style={{ marginTop: 0, marginBottom: 0 }}>Without IIQ: </p>
          <p
            style={{
              fontWeight: "600",
              marginLeft: "5px",
              marginTop: 0,
              marginBottom: 0,
            }}
          >{`${convertLocal(payload[0].value.toFixed(1))}`}</p>
        </div>
      </div>
    );
  }
  return null;
};
