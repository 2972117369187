import React, { useState, useEffect } from "react";
import { UsersTable } from "./tableComponents/UsersTable";
import { setOrigin } from "./users/usersSlice";
import { useDispatch } from "react-redux";
import { useGetAccountsQuery } from "./accountsApi";
import LoaderWithProgress from "../components/LoaderWithProgress/LoaderWithProgress";

export default function AccountUsersPage(props) {
  const filteredUsers = props.usersData.filter((user) => {
    return (
      user.firstName.toLowerCase().includes(props.searchInput.toLowerCase()) ||
      user.lastName.toLowerCase().includes(props.searchInput.toLowerCase()) ||
      user.email.toLowerCase().includes(props.searchInput.toLowerCase())
    );
  });
  const { data: accounts, isLoading, isFetching, refetch } = useGetAccountsQuery();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setOrigin("accounts"));
  }, []);
  if (isLoading || isFetching) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }
  return (
    <div>
      {filteredUsers && <UsersTable disabledColumns={["partnerAccount"]} users={filteredUsers}></UsersTable>}
    </div>
  );
}
