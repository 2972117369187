import React from "react";
import Box from "@mui/material/Box";
import DoDisturbOnIcon from "@mui/icons-material/DoDisturbOn";

export default function ForbiddenResourceNotification() {
  return (
    <Box sx={{ padding: "2rem", textAlign: "center", fontSize: "1.25rem" }}>
      <DoDisturbOnIcon sx={{ fontSize: "3rem", color: "salmon" }} />
      <Box sx={{ padding: "1.5rem" }}>
        {" "}
        Seems access to the area is restricted , please contact administrator of the account
      </Box>
    </Box>
  );
}
