import { formatDate, getDateAgo, getPreviousMonthName } from "../../../utils";
import { CONSTANT } from "../../../constants/constants";
const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;

export function useRenderSelectedDate(
  active_range_date: number,
  customDateRange: { customDateStart: any; customDateEnd: any },
  timeSelection = null,
) {
  // const { active_range_date, customDateRange } = useSelector(state => state.report);
  function renderSelectedDate({
    rangeTimeParam = null,
    isShortFormat = false,
  }: { rangeTimeParam?: number | null; isShortFormat?: boolean } = {}): string {
    // function renderSelectedDate({ rangeTimeParam = null, isShortFormat = false} = {}) {
    let date = new Date();
    let rangeTime = !!rangeTimeParam ? rangeTimeParam : active_range_date;
    if (!Boolean(rangeTime)) return "--";
    if (rangeTime === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value) {
      let today = new Date();
      today.setDate(today.getDate() - 1);
      return "Until " + formatDate(today).fullFormat;
    }

    if (rangeTime !== CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      if (rangeTime === LAST_MONTH) {
        if (!!isShortFormat) return `${getPreviousMonthName()}`;
        const lastMonthLastDate = new Date(new Date().getUTCFullYear(), new Date().getUTCMonth(), 0);
        return `${getPreviousMonthName({ fullMonthName: true })} ${lastMonthLastDate.getFullYear()}`;
        // return getPreviousMonthName({ fullMonthName: true }) + " " + new Date().getFullYear();
      }

      let reportDateStart = getDateAgo(date, rangeTime);

      let startDate = formatDate(reportDateStart.startDate);
      let endDate = formatDate(new Date(date.setDate(date.getDate() - 1)));

      if (rangeTime === CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value) {
        if (!!isShortFormat) return `${startDate.shortFormat}`;
        return `${startDate.fullFormat}`;
      }

      if (!!isShortFormat) return `${startDate.shortFormat} - ${endDate.shortFormat}`;
      return `${startDate.fullFormat} - ${endDate.fullFormat}`;
    }

    let { customDateStart, customDateEnd } = customDateRange || { customDateStart: null, customDateEnd: null };
    let startDate = formatDate(new Date(customDateStart));
    let endDate = formatDate(new Date(customDateEnd));

    if (!!isShortFormat) return `${startDate.shortFormat} - ${endDate.shortFormat}`;
    return `${startDate.fullFormat} - ${endDate.fullFormat}`;
  }

  return [renderSelectedDate];
}
