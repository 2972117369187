import { Box } from "@mui/material";
import { useState } from "react";
import BillingTable from "./BillingTable";
import PeriodPanel from "./PeriodPanel";
import { createLastMonthPeriod, createMTDPeriod } from "./utils";
import { RequestPeriodType } from "../../parametersConstancy/parametersConstancyTypes";

const BillingPage = () => {
  let today = new Date().getDate();
  const mtd: RequestPeriodType[] = createMTDPeriod();
  const lastMonth: RequestPeriodType[] = createLastMonthPeriod();
  const [fetchPeriod, setFetchPeriod] = useState<RequestPeriodType[]>(today !== 1 ? mtd : lastMonth);

  return (
    <Box display="grid">
      <PeriodPanel setFetchPeriod={setFetchPeriod}></PeriodPanel>
      {/* <ActionMenu></ActionMenu> */}
      <BillingTable fetchPeriod={fetchPeriod}></BillingTable>
    </Box>
  );
};

export default BillingPage;
