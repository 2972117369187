import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../store/store"; // Adjust this import based on your store setup
import { getParametersConstancy, storeParametersConstancy } from "./parametersContancyFetches";
import { useLocation, useNavigate } from "react-router-dom";
import { CONSTANT } from "../constants/constants";
import { UserParametersConstancyPageType } from "./parametersConstancyTypes";
import { setupParameters } from "./parametersConstancyUtils";

// Adjust the import based on your file structure

const ParametersStorer: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [shouldStore, setShouldStore] = useState<boolean>(false);

  const { commonUserPlatformParameters, performanceReportParameters, overviewParameters, accountsParameters } =
    useSelector((state: RootState) => state.platformParameters.changedParameters);
  const { isFetched } = useSelector((state: RootState) => state.platformParameters);

  useEffect(() => {
    const shouldStore =
      location.pathname !== CONSTANT.PAGES.LOGIN.path &&
      (Object.keys(commonUserPlatformParameters).length > 0 ||
        Object.keys(performanceReportParameters).length > 0 ||
        Object.keys(overviewParameters).length > 0 ||
        Object.keys(accountsParameters).length > 0);
    setShouldStore(shouldStore);
  }, [
    commonUserPlatformParameters,
    performanceReportParameters,
    overviewParameters,
    accountsParameters,
    location.pathname,
  ]);

  useEffect(() => {
    if (!isFetched) {
      fetchParameters();
    }
  }, [isFetched, dispatch]);

  const fetchParameters = async () => {
    try {
      const parameters: UserParametersConstancyPageType =
        (await getParametersConstancy()) as UserParametersConstancyPageType;

      await setupParameters(parameters, dispatch, navigate);
    } catch (error) {
      console.error("Error fetching constancy parameters", error);
    }
  };

  useEffect(() => {
    if (shouldStore) {
      const timeout = setTimeout(() => {
        dispatch(storeParametersConstancy());
      }, 60000);

      return () => clearTimeout(timeout);
    }
  }, [shouldStore, dispatch]);

  const handleBeforeUnload = async () => {
    if (shouldStore) {
      await dispatch(storeParametersConstancy());
    }
    //tricky approach to ensure that parameters was stored before unload page and fetching was newly stored parameters.
    // Because "beforeunload" event doesn't suport async execution
    const start = new Date().getTime();
    while (new Date().getTime() < start + 1500);
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [shouldStore, dispatch]);

  return null;
};

export default ParametersStorer;
