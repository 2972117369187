import { createAsyncThunk } from "@reduxjs/toolkit";
import { CONSTANT } from "../../constants/constants";

const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;

// retrieving saved raw data for widgets
export const getSummary = createAsyncThunk(
  "report/getSummary",
  async (_, { rejectWithValue, dispatch, getState }) => {
    let currentState = getState();

    let ct = currentState.report.active_chart;
    let rt =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateRange.value;
    let customDateRange =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange;
    let dgm =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode;
    let filterBase =
      currentState.report.filterBase === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY
        ? CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE
        : currentState.report.filterBase;

    //get raw data, exclude selected unit, recalculate
    let data = {};
    if (currentState.savedRawData[rt][filterBase].length > 0) {
      let rtPrevPeriod = rt === LAST_MONTH ? rt : rt * 2;
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        rtPrevPeriod = CONSTANT.DATE_RANGE_TYPES.CUSTOM.value;
      }
      let result = currentState.savedRawData[rt][filterBase];
      let resultPreviousPeriod = [];
      if (rt !== CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value)
        resultPreviousPeriod = currentState.savedRawDataPreviousPeriod[rtPrevPeriod][filterBase];

      data = {
        result: { data: result, resultPreviousPeriod },
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
      };
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        data.customDateRange = customDateRange;
      }
      return data;
    } else {
      return (data = {
        result: { data: [], resultPreviousPeriod: [] },
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
      });
    }
  },
);
