import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";
import { ConfigurationFormData, IBidderAdapter, IBidderConfig, IPartnerConfig } from "./interfaces";

const SERVER_URL = CONSTANT.path.host;

export const configurationsApi = createApi({
  reducerPath: "configurationsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/bidders-config`,
    prepareHeaders: (headers) => {
      let token = auth.isAuthenticated().data.accessToken;
      if (token) headers.set("Authorization", `Bearer ${token}`);
    },
  }),
  tagTypes: ["Partners", "BidderConfig"],
  keepUnusedDataFor: 300,
  endpoints: (builder) => ({
    getAllPartnersConfiguration: builder.query<IPartnerConfig[], void>({
      query: () => `partners`,
      providesTags: ["Partners"],
    }),
    getAllConfiguration: builder.query<IBidderConfig[], void>({
      query: () => ``,
      providesTags: ["BidderConfig"],
    }),
    getAllBidders: builder.query<IBidderAdapter[], void>({
      query: () => `bidders`,
    }),
    createPartnersConfiguration: builder.mutation<void, ConfigurationFormData>({
      query: (data) => {
        return {
          url: "",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    deletePartnersConfigurationById: builder.mutation<void, string>({
      query: (id) => {
        return {
          url: `${id}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    updatePartnersConfigurationById: builder.mutation<void, { data: Partial<ConfigurationFormData>; id: number }>({
      query: ({ id, data }) => {
        return {
          url: `${id}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
    updatePartnersResolutionById: builder.mutation<void, { data: { isServiceActive: number }; pdid: string }>({
      query: ({ pdid, data }) => {
        return {
          url: `resolution/${pdid}`,
          method: "PUT",
          body: data,
        };
      },
      invalidatesTags: ["BidderConfig"],
    }),
  }),
});

export const useGetAllPartnersConfiguration = configurationsApi.endpoints.getAllPartnersConfiguration.useQuery;
export const useGetAllConfiguration = configurationsApi.endpoints.getAllConfiguration.useQuery;
export const useCreatePartnersConfiguration = configurationsApi.endpoints.createPartnersConfiguration.useMutation;
export const useDeletePartnersConfigurationById =
  configurationsApi.endpoints.deletePartnersConfigurationById.useMutation;
export const useUpdatePartnersConfigurationById =
  configurationsApi.endpoints.updatePartnersConfigurationById.useMutation;
export const useUpdatePartnersResolutionById =
  configurationsApi.endpoints.updatePartnersResolutionById.useMutation;
export const useGetAllBidders = configurationsApi.endpoints.getAllBidders.useQuery;
