import { Box, Button, Menu, MenuItem, Typography, useMediaQuery } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { BasicTooltip } from "../../components/BasicTooltip/BasicTooltip";
import { activeBtn, btnWrapper, dateContainer, singleBtn } from "../overview/styles";
import { CONSTANT } from "../../constants/constants";
import { useRenderSelectedDate } from "../overview/helpers/useRenderSelectedDate";
import CheckboxWrapper from "../../components/CustomCheckbox/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import { createLastMonthPeriod, createMTDPeriod, getCustomFetchPeriod } from "./utils";
import { RequestPeriodType } from "../../parametersConstancy/parametersConstancyTypes";

const LAST_MONTH: number = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;
const CUSTOM: number = CONSTANT.DATE_RANGE_TYPES.CUSTOM.value;

interface PeriodPanelProps {
  setFetchPeriod: Dispatch<SetStateAction<RequestPeriodType[]>>;
}

const PeriodPanel: React.FC<PeriodPanelProps> = ({ setFetchPeriod }) => {
  let mtd = new Date().getDate() - 1;
  const matches = useMediaQuery("(min-width:1520px)");
  const [isActivePeriod, setIsActivePeriod] = useState<number>(mtd !== 0 ? mtd : LAST_MONTH);
  const [chosenMonths, setChosenMonths] = useState<string[]>([]);
  const [chosenMonthsStr, setChosenMonthsStr] = useState<string>("");
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  const isActiveButton = (type: number) => {
    return isActivePeriod === type ? activeBtn : {};
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMonthsMenu = Boolean(anchorEl);
  const setOpenMonthsMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMonthMenu = () => {
    setAnchorEl(null);
    setTooltipOpen(false);
  };

  const [renderSelectedDate] = useRenderSelectedDate(
    isActivePeriod,
    { customDateStart: null, customDateEnd: null },
    null,
  );

  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    defineStringOfChosenMonths();
    // const newTimeoutId = setTimeout(() => {
    //   handleCloseMonthMenu();
    // }, 2000); // Close menu after 2 seconds

    // return () => clearTimeout(newTimeoutId); // Clear the new timeout
  }, [chosenMonths]);

  const handleMenuItemClick = (monthValue: string) => {
    setChosenMonths((prevChosenMonths) => {
      const index = prevChosenMonths.indexOf(monthValue);
      if (index !== -1) {
        // Month is already selected, remove it
        return [...prevChosenMonths.slice(0, index), ...prevChosenMonths.slice(index + 1)];
      } else {
        // Month is not selected, add it
        return [...prevChosenMonths, monthValue];
      }
    });

    //For choosing a multiple periods at time
    // if (timeoutId) {
    //   clearTimeout(timeoutId); // Clear the existing timeout
    // }
    // const newTimeoutId = setTimeout(() => {
    handleCloseMonthMenu();
    // }, 1500); // Set a new timeout
    // setTimeoutId(newTimeoutId); // Update the state variable with the new timeout ID
  };

  const handleSetActivePeriod = (period: number) => {
    setIsActivePeriod(period);
    if (period !== CUSTOM && isActivePeriod !== period) {
      const fetchPeriod: RequestPeriodType[] = getFetchPeriod(period);
      if (fetchPeriod.length) {
        setFetchPeriod(fetchPeriod);
      }
    } else if (period === CUSTOM && isActivePeriod !== CUSTOM) {
      if (chosenMonths.length) {
        const fetchPeriod: RequestPeriodType[] = getCustomFetchPeriod(chosenMonths);
        setFetchPeriod(fetchPeriod);
      } else {
        setChosenMonthsStr("");
      }
    }
  };

  const getFetchPeriod = (period: number) => {
    if (period === CONSTANT.DATE_RANGE_TYPES.MTD.value) {
      return createMTDPeriod();
    }
    if (period === LAST_MONTH) {
      return createLastMonthPeriod();
    } else {
      return [];
    }
  };

  const defineStringOfChosenMonths = () => {
    if (chosenMonths.length) {
      const sortedMonths = [...chosenMonths].sort((a, b) => {
        const months = Object.values(CONSTANT.MONTHS);
        return months.indexOf(a) - months.indexOf(b);
      });
      setChosenMonthsStr(sortedMonths.join(", "));
      const fetchPeriod: RequestPeriodType[] = getCustomFetchPeriod(chosenMonths);
      setFetchPeriod(fetchPeriod);
    }
  };

  return (
    <Box sx={btnWrapper}>
      <BasicTooltip
        tooltipText={
          mtd === 0
            ? "Option is unavailable - zero completed days from the beginning of the current month"
            : `${renderSelectedDate({ rangeTimeParam: mtd })}`
        }
      >
        <Typography>
          <Button
            disabled={mtd === 0}
            sx={singleBtn}
            style={isActiveButton(mtd)}
            onClick={() => handleSetActivePeriod(mtd)}
          >
            MTD
          </Button>
        </Typography>
      </BasicTooltip>
      <BasicTooltip
        tooltipText={`${renderSelectedDate({
          rangeTimeParam: LAST_MONTH,
          isShortFormat: undefined,
        })}`}
      >
        <Typography>
          <Button
            sx={singleBtn}
            style={isActiveButton(LAST_MONTH)}
            onClick={() => handleSetActivePeriod(LAST_MONTH)}
          >
            Last Month
          </Button>
        </Typography>
      </BasicTooltip>
      <Tooltip
        disableFocusListener
        disableTouchListener
        open={tooltipOpen}
        enterDelay={1100}
        placement="top"
        title={chosenMonths.length > 0 ? <span style={{ fontSize: "16px" }}>{chosenMonthsStr}</span> : null}
      >
        <Typography>
          <Button
            onMouseLeave={() => setTooltipOpen(false)}
            onMouseOver={() => setTooltipOpen(true)}
            sx={singleBtn}
            style={isActiveButton(CUSTOM)}
            onClick={(event) => {
              setOpenMonthsMenu(event);
              handleSetActivePeriod(CUSTOM);
            }}
          >
            Custom
          </Button>
          <Menu
            id="months-menu"
            anchorEl={anchorEl}
            open={openMonthsMenu}
            onClose={handleCloseMonthMenu}
            MenuListProps={{
              onMouseLeave: handleCloseMonthMenu,
            }}
            PaperProps={{
              style: {
                marginTop: "5px",
                borderRadius: "5px 5px 5px 5px",
                // border: "1px solid #84DAFF",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 12px 40px 0px",
                backgroundColor: "rgb(255, 255, 255)",
                zIndex: 10000,
              },
            }}
          >
            {Object.entries(CONSTANT.MONTHS).map(([key, label]) => (
              <MenuItem style={{ padding: "0px 16px" }} key={key} onClick={() => handleMenuItemClick(label)}>
                <CheckboxWrapper
                  checked={chosenMonths.includes(label)}
                  style={{ marginRight: "8px", background: "#fff" }}
                />
                {label + " 2023"}
              </MenuItem>
            ))}
          </Menu>
        </Typography>
      </Tooltip>
      <Box
        style={{
          flexGrow: 1,
          textAlign: "right",
        }}
      >
        <Typography sx={dateContainer}>
          {isActivePeriod !== CUSTOM ? renderSelectedDate({ isShortFormat: !matches }) : chosenMonthsStr}
        </Typography>
      </Box>
    </Box>
  );
};

export default PeriodPanel;
