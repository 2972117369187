import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

function Loader({ isTable = false, sx, cpSize = "70px", color = "#BEBEBE" }) {
  return (
    <Box
      sx={{
        height: "100%",
        marginTop: isTable ? 190 : 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <CircularProgress style={{ height: cpSize, width: cpSize, color }} />
    </Box>
  );
}

export default Loader;
