import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../constants/constants";
import auth from "../auth/auth-helper";

const SERVER_URL = CONSTANT.path.host;

export const partnersApi = createApi({
  reducerPath: "partnersApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/partners`,
    prepareHeaders: (headers) => {
      const token = auth.isAuthenticated().data.accessToken;
      if (token) headers.set("Authorization", `Bearer ${token}`);
      return headers;
    },
  }),
  tagTypes: ["Partner"],
  endpoints: (builder) => ({
    updatePartner: builder.mutation<any, { pdid: any; updateData: any }>({
      query: ({ pdid, updateData }) => ({
        url: `${pdid}`,
        method: "PATCH",
        body: updateData,
      }),
    }),
  }),
});

// Export hooks for usage in functional components
export const { useUpdatePartnerMutation } = partnersApi;
