import { useState, useRef, useEffect } from "react";
import { Button, Box } from "@mui/material";
import TextField from "@mui/material/TextField";
import Loader from "../../components/Loader";
import { updateAccount } from "../api/accounts-api";
import { formFieldsValidator } from "../helpers/board-helpers";
import { boardWrapperStl, stl, titleStl, inputSx } from "./boardStl";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import { fetchAccountImgData } from "../api/accounts-api";
import { useGetAccountsQuery } from "../accountsApi";
import FileUploadComponent from "./FileUploadComponent";
import { CONSTANT } from "../../constants/constants";

export default function EditAccountBoardHandler(props) {
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const [isChecked, setIsChecked] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedFileName, setSelectedFileName] = useState("");
  const { refetch: refetchGetAccounts } = useGetAccountsQuery();
  const [formValues, setFormValues] = useState({
    company: {
      required: 1,
      value: props.value.company ? props.value.company : "",
      error: false,
      errorMessage: "Please, enter company name (will be used in this account name)",
      validationFunction: [(val) => !!val.trim().length],
    },
    photo: {
      required: 1,
      value: "",
      error: false,
      errorMessage: "---",
      validationFunction: [(v) => 1],
    },
  });

  const shouldUpdateAccountPhotoRef = useRef(0);

  useEffect(() => {
    fetchImage();
  }, []);

  const fetchImage = async () => {
    setShowLoader(true);
    const res = await fetchAccountImgData(props.value.id);

    setFormValues({
      ...formValues,
      ["photo"]: { ...formValues["photo"], value: res, error: false },
    });
    setSelectedFileName(res);
    setShowLoader(false);
  };

  const handleChange = (e) => {
    // const { name, value } = e.target;
    const val = e.target.name === "photo" ? e.target.files[0] : e.target.value;

    if (e.target.name === "photo") {
      setFormValues({
        ...formValues,
        ["photo"]: { ...formValues["photo"], value: "", error: "" },
      });

      if (!val) return;
      const fileSizeBytes = val?.size;
      if (!CONSTANT.ALLOWED_FILE_IMAGE_TYPES.includes(val?.type)) {
        setFormValues({
          ...formValues,
          ["photo"]: {
            ...formValues["photo"],
            value: "",
            error: "File is not added, only JPEG, JPG, PNG, SVG images are allowed.",
          },
        });
        return;
      }

      if (fileSizeBytes > CONSTANT.MAX_FILE_SIZE) {
        const bytes = CONSTANT.MAX_FILE_SIZE;
        const megabytes = (bytes / (1024 * 1024)).toFixed(2) + "MB";
        let str = "File is not added, size is greater than maximum limit, max: " + megabytes;
        setFormValues({
          ...formValues,
          ["photo"]: { ...formValues["photo"], value: "", error: str },
        });
        return;
      }

      if (!!val) setSelectedFileName(e.target.files[0].name);
      if (!shouldUpdateAccountPhotoRef.current) {
        shouldUpdateAccountPhotoRef.current = 1;
      }
    }

    setFormValues({
      ...formValues,
      [e.target.name]: { ...formValues[e.target.name], value: val, error: false },
    });
  };

  const hanldleRemovePhoto = () => {
    setFormValues({
      ...formValues,
      ["photo"]: { ...formValues["photo"], value: "", error: false },
    });
    setSelectedFileName("");
    if (!shouldUpdateAccountPhotoRef.current) {
      shouldUpdateAccountPhotoRef.current = 1;
    }
  };

  const handleRetrieve = () => {
    let { newFormValues, errors } = formFieldsValidator(formValues, isChecked);
    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);

    let data = new FormData();
    data.append("company", formValues.company.value);
    data.append("photo", formValues.photo.value);
    data.append("shouldUpdateAccountPhoto", shouldUpdateAccountPhotoRef.current);

    updateAccount(data, props.value.id).then((data) => {
      setShowLoader(false);
      if (data.error) {
        console.log(data.error);
        alert("Action failed, something went wrong...");
      } else {
        // props.updateDataAndRows(data);
        refetchGetAccounts();
        props.onClose();
      }
    });
  };

  if (showLoader) return <Loader sx={{ height: "80vh" }} />;

  return (
    <div  id='edit-account-popup'>
      <div style={boardWrapperStl}>
        {/* Account name */}
        <Box sx={stl}>
          <Box id='title' sx={titleStl}>Account name</Box>
          <TextField
            id='partner-name-input'
            sx={inputSx}
            onChange={handleChange}
            error={formValues.company.error}
            helperText={formValues.company.error && formValues.company.errorMessage}
            value={formValues.company.value}
            placeholder="Partner name"
            name="company"
            autoFocus
          />
        </Box>

        <Box sx={stl}>
          <Box
            id='company-logo'
            sx={{
              ...titleStl,
            }}
          >
            Company logo
          </Box>
          <FileUploadComponent
            handleFileChange={handleChange}
            handleButtonClick={handleButtonClick}
            selectedFileName={selectedFileName}
            fileUploadError={formValues.photo.error}
            handleRemovePhoto={hanldleRemovePhoto}
            ref={fileInputRef}
          />
        </Box>
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id='save-btn' sx={buttonSx} onClick={handleRetrieve}>
          Save changes
        </Button>
      </div>
    </div>
  );
}
