import React, { useEffect, useRef, useState } from "react";
import { ConfigProvider, Table } from "antd";
import type { TableProps } from "antd/es/table";
import type { FilterValue } from "antd/es/table/interface";
import { DataIndex, DataType, columns, tableProps } from "./tableStructure";
import type { InputRef } from "antd";
import type { FilterConfirmProps } from "antd/es/table/interface";
import { getBillingInfoFetch } from "./billingFetches";
import { handleGenerateReportPDF, handleGenerateReportXLSX } from "./generateReport";
import { Box } from "@mui/material";
import "./billingTableStyle.css";
import { RequestPeriodType } from "../../parametersConstancy/parametersConstancyTypes";

interface BillingTableProps {
  fetchPeriod: RequestPeriodType[];
}

export type HandleGenerateReportType = {
  generateReportPDF: () => void;
  generateReportXLSX: () => void;
};

export type TablePropsParamsType = {
  paginationConfig: Object;
  handleGenerateReport: HandleGenerateReportType;
  currentFilters: any;
  handleResetFilter: (filterKey: string) => void;
  expandedSummary: boolean;
  setExpandedSummary: React.Dispatch<React.SetStateAction<boolean>>;
  billingInfoIsLoading: boolean;
};

const BillingTable: React.FC<BillingTableProps> = ({ fetchPeriod }) => {
  const [billingInfoIsLoading, setBillingInfoIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<DataType[]>([]);
  const [searchingData, setSearchingData] = useState<DataType[]>([]);
  const [paginationConfig, setPaginationConfig] = useState({});
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [pricePlans, setPricePlans] = useState<Set<string>>(new Set());
  const [periods, setPeriods] = useState<Set<string>>(new Set());
  const [currencies, setCurrencies] = useState<Set<string>>(new Set());
  const [currentFilters, setCurrentFilters] = useState<Record<string, FilterValue | null>>({});
  const [expandedSummary, setExpandedSummary] = useState<boolean>(false);

  useEffect(() => {
    const abortController = new AbortController();
    const signal = abortController.signal;
    let mounted = true;
    const fetchData = async () => {
      try {
        if (mounted) {
          setBillingInfoIsLoading(true);
        }
        if (fetchPeriod.length) {
          const result = await getBillingInfoFetch(fetchPeriod, signal);
          if (result && mounted) {
            const { billingPartnersInfo, uniquePricePlans } = result;
            const resPeriods = new Set<string>();
            const resCurrencies = new Set<string>();
            for (const info of billingPartnersInfo) {
              resPeriods.add(info.period);
              resCurrencies.add(info.currency);
            }
            setPeriods(resPeriods);
            setCurrencies(resCurrencies);
            setData(billingPartnersInfo);
            setSearchingData(billingPartnersInfo);
            setPaginationConfig({ dataLength: billingPartnersInfo.length, currentPageSize: 25 });
            setPricePlans(uniquePricePlans);
          }
        }
      } catch (error) {
        console.error("Error loading billing data: ", error);
      } finally {
        if (mounted) {
          setBillingInfoIsLoading(false);
        }
      }
    };
    fetchData();
    return function cleanup() {
      mounted = false;
      abortController.abort();
    };
  }, [fetchPeriod]); //fetchPeriod

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: DataIndex
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  const handleResetFilter = (filterKey: string) => {
    const updatedFilters = { ...currentFilters, [filterKey]: null };
    handleSearch([], () => {}, filterKey as keyof DataType);
    setCurrentFilters(updatedFilters);
    const filteredData = filterData(updatedFilters);
    setSearchingData(filteredData);
    setPaginationConfig((prevConfig) => ({ ...prevConfig, dataLength: filteredData.length }));
  };

  const filterData = (filters: Record<string, FilterValue | null>): DataType[] => {
    return data.filter((item) => {
      return Object.entries(filters).every(([key, filterValue]) => {
        if (filterValue === null || filterValue === undefined) {
          return true; // Skip filtering for null values
        }
        if (Array.isArray(filterValue)) {
          // Check if the property value includes any of the filter values
          return filterValue.some((value) =>
            String(item[key as keyof DataType])
              .toLowerCase()
              .includes(String(value).toLowerCase())
          );
        } else {
          // Check if the property value is equal to the filter value
          return String(item[key as keyof DataType]).toLowerCase() === String(filterValue).toLowerCase();
        }
      });
    });
  };

  const handleGenerateReport: HandleGenerateReportType = {
    generateReportPDF: () => handleGenerateReportPDF(searchingData),
    generateReportXLSX: () => handleGenerateReportXLSX(searchingData),
  };

  const onChange: TableProps<DataType>["onChange"] = (pagination, filters, sorter, extra) => {
    setCurrentFilters(filters);
    setPaginationConfig({ dataLength: extra.currentDataSource.length, currentPageSize: pagination.pageSize });
    setSearchingData(extra.currentDataSource);
  };

  const tableColumsProps = {
    searchInput,
    handleSearch,
    handleReset,
    setSearchText,
    setSearchedColumn,
    searchedColumn,
    pricePlans,
    periods,
    currencies,
    currentFilters,
  };

  const tablePropsParams: TablePropsParamsType = {
    paginationConfig,
    handleGenerateReport,
    currentFilters,
    handleResetFilter,
    expandedSummary,
    setExpandedSummary,
    billingInfoIsLoading,
  };

  return (
    <Box
      style={{
        overflowX: "auto",
        border: "1px solid #f0f0f0",
        borderRadius: "8px",
        marginBottom: "20px",
      }}
    >
      <ConfigProvider
        theme={{
          token: {
            fontFamily: ["Inter", "Source Sans Pro", "Helvetica", "Arial", "sans-serif"].join(","),
          },
        }}
      >
        <Table
          {...tableProps(tablePropsParams)}
          onChange={onChange}
          columns={columns(tableColumsProps)}
          dataSource={data}
          style={{ fontSize: "1rem" }}
        />
      </ConfigProvider>
    </Box>
  );
};

export default BillingTable;
