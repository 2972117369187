import * as React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../theme";

const VirtualAccountChip: React.FC = () => {
  return (
    <Chip
      label={"Multi"} // Replace with actual logic if needed
      size="medium"
      sx={{
        bgcolor: colors.secPp40,
        color: "white",
      }}
    />
  );
};

export default VirtualAccountChip;
