import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";
import { UploadFile } from "antd";
const SERVER_URL = CONSTANT.path.host;

export interface FeedbackFormRequest {
  topic: string;
  message: string;
  fileList: UploadFile[];
}

export const sendFeedback = async (feedbackRequestDto: FeedbackFormRequest) => {
  let token = auth.isAuthenticated().data.accessToken;
  try {
    const formData = new FormData();
    formData.append("topic", feedbackRequestDto.topic);
    formData.append("message", feedbackRequestDto.message);

    feedbackRequestDto.fileList.forEach((file, index) => {
      if (file.originFileObj) {
        formData.append(`files`, file.originFileObj);
      }
    });
    const response = await fetch(`${SERVER_URL}/feedbacks`, {
      method: "POST",
      headers: {
        authorization: "Bearer " + token,
      },
      body: formData,
    });
    if (!response.ok) {
      throw new Error(`Failed to send feedback. Server responded with status: ${response.status}`);
    }
    return response.status;
  } catch (error) {
    console.error("Error:", error);
    throw new Error("Failed to send feedback. Please try again later.");
  }
};
