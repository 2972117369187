import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Box } from "@mui/material";
import AccountMenu from "./AccountMenu";
import { setTargetAccount } from "../users/usersSlice";
import TextField from "@mui/material/TextField";
import { boardWrapperStl, stl, titleStl, titleStlAsterics, inputSx } from "../sideBoard/boardStl";

export default function AssignRoleToAccount(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setTargetAccount(props.selectedAccount));
  }, []);

  const handleChange = (e) => {
    const val = e.target.value;
    props.setFormData({ ...props.formData, [e.target.name]: val });
  };

  const handleleRoleSelection = (target) => {
    props.setFormData({
      ...props.formData,
      target: target?.id,
      accounts: [],
      partners: [],
      permissions: {},
    });
    dispatch(setTargetAccount(target));
  };
  const handleleClearSelection = (target) => {
    props.setFormData({ ...props.formData, target: null, accounts: [], partners: [], permissions: {} });
    dispatch(setTargetAccount(null));
  };

  return (
    <div id='assign-role-to-account-popup'>
      <div style={boardWrapperStl}>
        <Box sx={{ ...stl, borderRadius: "4px" }}>
          <AccountMenu
            selectedAccount={props.selectedAccount}
            setSelectedAccount={props.setSelectedAccount}
            onSelect={handleleRoleSelection}
            onClear={handleleClearSelection}
            availiableAccountsList={props.availiableAccountsList}
          />
        </Box>

        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Role name
          </Box>
          <TextField
            id='role-name-input'
            sx={inputSx}
            onChange={handleChange}
            value={props.formData.roleName}
            placeholder="Role name"
            name="roleName"
          />
        </Box>
        {/* Last name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
            }}
          >
            Role description
          </Box>
          <TextField
            id='role-description-input'
            sx={inputSx}
            onChange={handleChange}
            value={props.formData.roleDescription}
            name="roleDescription"
            placeholder="Role description"
          />
        </Box>
      </div>
    </div>
  );
}
