import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { CONSTANT } from "../../constants/constants";
import {
  columnTablePanelStyle,
  mainTableStyle,
} from "../../core/performanceReports/advancedReport/table/mainTableStyle";
import { defaultColumns } from "./columnsModel";
import { CustomColumnMenuComponent } from "../../core/performanceReports/advancedReport/table/MainTable";
import auth from "../../auth/auth-helper";
import { dataGridSx } from "../styles";
import LoaderWithProgress from "../../components/LoaderWithProgress/LoaderWithProgress";
import { tablesRowsAmountPerPageOptions } from "../../parametersConstancy/parametersConstancyTypes";
import { CustomPaginationWrapper } from "../AccountsTable";

export default function RolesManagementMainTable(props) {
  const jwt = auth.isAuthenticated();
  const navigate = useNavigate();
  useEffect(() => {
    //setCurrencyArray(rawCurrency);
  }, []);

  const handleViewDetailsClick = (params) => {
    props.onHandleRowClick(params);
  };

  const handleAccountCellClick = (params) => {
    navigate(`/accounts/${params.row.accountId}`);
  };
  const handleAmountOfUsersCellClick = (params) => {
    if (jwt.data.user.acctId === CONSTANT.IIQ_) navigate(`/users`, { state: { role: params.row.name } });
  };

  const columns = useMemo(() => {
    return defaultColumns({
      actionsProps: {
        handleViewDetailsClick,
        handleAccountCellClick,
        handleAmountOfUsersCellClick,
        // refetchUserById,
        // onDelete
      },
    });
  }, [
    handleViewDetailsClick,
    handleAccountCellClick,
    handleAmountOfUsersCellClick,
    // refetchUserById,
    // onDelete
  ]);

  if (!props?.rolesList) {
    return (
      <LoaderWithProgress
        sx={{
          display: "flex",
          width: "100%",
          minHeight: "75vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
    );
  }

  // let rowsAmountPerPage = [25, 50, 100];
  // if (props.rolesList.length < MAX_ROWS_PER_PAGE) rowsAmountPerPage.push({ label: "All", value: -1 });

  return (
    <Box id="table-container" sx={{ ...mainTableStyle(), width: "100%" }}>
      <DataGrid
        // onRowClick={props.onHandleRowClick}
        autoHeight={true}
        rowHeight={36}
        rows={props.rolesList}
        columns={columns} //represent column name and key to use
        getRowClassName={(params) => {
          return params.indexRelativeToCurrentPage % 2 === 0 ? "Mui-even" : "Mui-odd";
        }}
        sx={dataGridSx}
        initialState={{
          sorting: {
            sortModel: [{ field: "submitted_date", sort: "desc" }],
          },
          // pagination: {
          //   paginationModel: {
          //     pageSize: rowsAmountPerPage[0],
          //   },
          // },
        }}
        slots={{
          footer: () => <CustomPaginationWrapper rowsPerPageOptions={tablesRowsAmountPerPageOptions} />,
          columnMenu: CustomColumnMenuComponent,
        }}
        slotProps={{
          panel: {
            sx: columnTablePanelStyle,
          },
        }}
        hideFooterPagination={false}
        checkboxSelection={false}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
