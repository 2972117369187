import React from "react";
import { Box, IconButton, SxProps } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
export enum ActionType {
  Edit = "edit",
  Delete = "delete",
}

interface ActionItemProps {
  actionType: ActionType;
  onAction: () => void;
  sx?: SxProps; // Optional styling prop for customization
}

const ActionItem: React.FC<ActionItemProps> = ({ actionType, onAction, sx }) => {
  const renderIcon = () => {
    switch (actionType) {
      case ActionType.Edit:
        return <EditOutlinedIcon />;
      case ActionType.Delete:
        return <DeleteOutlineIcon />;
      default:
        return null;
    }
  };

  return (
    <IconButton onClick={onAction} aria-label={actionType} sx={{ color: "#8c8c8c", ...sx }}>
      {renderIcon()}
    </IconButton>
  );
};

export default ActionItem;
