// StyledDataGrid.tsx
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import { BorderRight } from "@mui/icons-material";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  // Root styles for the DataGrid container
  width: "100%",
  border: "1px solid #DDE3EA",
  borderRadius: "16px",
  background: "var(--Main-White, #FFF)",

  "& .MuiDataGrid-columnHeaders": {
    // Full-width header styling
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #E9EEF4",
  },

  "& .MuiDataGrid-columnHeaderTitle": {
    // Font and text styles for header text
    color: "#647091",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "20px",
    textAlign: "center",
  },
  "& .MuiDataGrid-columnHeaderTitleContainer": {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  "& .MuiDataGrid-columnHeader": {
    borderLeft: "1px solid #E9EEF4",
    "&:first-of-type": {
      borderLeft: "none",
    },
  },

  "& .MuiDataGrid-cell": {
    // Cell styling
    width: "132px",
    height: "48px",
    padding: "0px 8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderBottom: "1px solid #E9EEF4",
    color: "#101D41",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "20px",
  },

  "& .MuiDataGrid-cellContent": {
    // Centers content within the cell
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  "& .MuiDataGrid-row:nth-of-type(even)": {
    backgroundColor: "#F5F7FA", // Slightly darker background for odd rows
  },
}));

export default function StyledDataGridWrapper(props: DataGridProps) {
  return <StyledDataGrid {...props} />;
}
