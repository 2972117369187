import { Box } from "@mui/material";
import { NoticeSwitchSelectorWrapperProps } from "./noticeTypes";
import NoticeSwitchSelector from "./NoticeSwitchSelector";

const NoticeSwitchSelectorWrapper: React.FC<NoticeSwitchSelectorWrapperProps> = ({
  types,
  selectedType,
  handleChangeType,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: types.length > 1 ? "space-between" : "flex-start",
        gap: "4px",
        alignSelf: types.length > 1 ? "stretch" : "flex-start",
        width: types.length === 1 ? "50%" : "100%",
        // padding: "8px",
      }}
    >
      {types.map((noticeType) => (
        <NoticeSwitchSelector
          key={noticeType.id}
          id={noticeType.id}
          title={noticeType.title}
          isChecked={selectedType.id === noticeType.id}
          onChange={() => handleChangeType(noticeType)}
        />
      ))}
    </Box>
  );
};

export default NoticeSwitchSelectorWrapper;
