import { useDispatch, useSelector } from "react-redux";
import { getReport } from "../../../report/reportThunks/getReport";
import { getReportPreviousPeriod } from "../../../report/reportThunks/getReportPreviousPeriod";
import { getSummaryReport } from "../../../summary/summaryThunks/getSummaryReport";
import { setSummaryCompleteDate } from "../../../report/reportSlice";
import { CONSTANT } from "../../../constants/constants";

export function useSendRequests() {
  const { filterbase: stateBase } = useSelector((state) => state.report);
  const customDateRange = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange,
  );
  const { dateRange } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const active_range_date = dateRange.value;
  const dispatch = useDispatch();

  function handleCustomDatePeriodAsActive() {
    if (active_range_date === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      return {
        rangeType: -1,
        customDate: {
          customDateStart: customDateRange.customDateStart,
          customDateEnd: customDateRange.customDateEnd,
        },
      };
    }
    return {
      rangeType: undefined,
      customDate: false,
    };
  }

  function request(
    jwt,
    signal,
    rangeType,
    filterBase = undefined,
    skipSetFilterBaseAsActive = false,
    customDateRange = null,
    skipInternalSearch = false,
  ) {
    let payload = {
      id: jwt.data.user.id,
      token: jwt.data.accessToken,
      signal,
    };

    if (rangeType) payload.rt = rangeType;
    if (filterBase) payload.setFilterBase = filterBase;
    if (skipSetFilterBaseAsActive) payload.skipSetFilterBaseAsActive = skipSetFilterBaseAsActive;
    if (customDateRange) payload.customDateRange = customDateRange;
    if (skipInternalSearch) payload.skipInternalSearch = skipInternalSearch;

    let summaryPayload = {};
    if (customDateRange) {
      summaryPayload.customDateRange = customDateRange;
    }
    dispatch(getSummaryReport(payload));
    dispatch(getReport(payload))
      .unwrap()
      .then(() => {
        dispatch(getReportPreviousPeriod(payload));
      })
      .then(() => {
        if (stateBase === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
          dispatch(setSummaryCompleteDate());
        }
      });
  }

  return [request, handleCustomDatePeriodAsActive];
}
