import { styled } from '@mui/system';

//styling for calendar and month picker
export const WrapperMonthPicker = styled('div')({
  // fontSize: '14px',
  // "& .MonthPicker": {
  //   width: "100%",
  //   height: "313px",
  //   marginTop: "73px",
  // },
  // "& ._PEIKD": {
  //   backgroundColor: "lightgreen !important",
  //   borderTopLeftRadius: "21% !important",
  //   borderBottomLeftRadius: "20% !important",
  // },
  // "& ._1ynpH": {
  //   backgroundColor: "#3d91ff !important",
  //   borderTopRightRadius: "21% !important",
  //   borderBottomRightRadius: "20% !important",
  // },
  // "& ._2z_SO": {
  //   boxShadow: "none !important",
  //   // borderTopLeftRadius: "21% !important",
  //   // borderBottomLeftRadius: "20% !important",
  // },
  "& .rdrDateDisplayWrapper": {
    background: "#fff !important",
    // display: "none !important",
  },
  // "& .rdrMonthAndYearWrapper": {
  //   height: "40px !important",
  //   width: "50px",
  //   isplay: "flex",
  //   justifyContent: "center",
  //   alignSelf: "center",
  // },
  // "& .rdrMonthAndYearWrapper > button ": {
  //   backgroundColor: "transparent !important",
  //   "&:hover": {
  //     backgroundColor: "#fff !important",
  //   }
  // },
  // "& .rdrMonthPicker": {
  //   margin: 0
  // },
  // "& .rdrMonthPicker > select": {
  //   padding: "10px 20px 10px 10px"
  // },
  // "& .rdrYearPicker": {
  //   margin: 0
  // },
  // "& .rdrYearPicker > select": {
  //   padding: "10px 25px 10px 10px"
  // },
});