import { colors } from "../../../theme";

export const btnGroupWrapper = {
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  borderRadius: "5px",
  "& button": {
    width: "50%",
    border: "1px solid #E5E5E5",
    padding: "14px 12px",
    fontSize: "1.125rem",
    backgroundColor: "#FAFAFA",
    borderBottomRightRadius: "5px",
    cursor: "pointer",
    fontWeight: "600",
    color: "secondary.main",
    "&:first-of-type": {
      borderTopLeftRadius: "5px",
      borderRight: "none",
      borderBottomLeftRadius: "5px",
      borderBottomRightRadius: "0px",
      borderTopRightRadius: "0px",
      backgroundColor: "rgba(27, 178, 255, 0.15)",
    },
    "&:last-child": {
      borderTopRightRadius: "5px",
      borderLeft: "none",
    },
  },
};

export const singleBtn = {
  cursor: "pointer",
  minWidth: "7.1875rem",
  padding: "0.875rem 1rem",
  fontSize: "14px",
  lineHeight: "22px",
  borderRadius: "8px",
  borderLeft: "none",
  borderRight: "none",
  fontWeight: 400,
  backgroundColor: "#fff",
  color: `${colors.text02}`,
  "&:hover": {
    // borderBottom: "1px solid #F0F0F0",
    // backgroundColor: "#F5F5F5",
    transition: "100ms ease-in",
  },
};

export const buttonSx = {
  width: {
    xs: "calc(80% - 10px)",
    sm: "calc(50% - 10px)",
    lg: "calc(70% - 10px)",
    xl: "15.3125rem",
  },
  padding: {
    xs: "0.25rem",
    sm: "0.5rem",
    md: "0.625rem",
    lg: "0.7rem",
    xl: "1rem",
  },
  // mt: {
  //   xs: ".75rem",
  //   sm: "1.5rem",
  //   md: "2rem",
  //   lg: "2.825rem",
  //   xl: "3.5625rem",
  // },
  borderRadius: "0.5rem",
  fontWeight: 600,
  fontSize: {
    xs: "0.625rem",
    sm: "0.625rem",
    md: "0.625rem",
    lg: "0.825rem",
    xl: "1.125rem",
  },
  backgroundColor: "secondary.lightBlue",
  color: "#FFFFFF",
  "&:hover": {
    backgroundColor: "rgba(27, 178, 255, 0.15);",
    color: "secondary.mainDark",
  },
};

export const loaderStl = {
  background: "#FFFFFF",
  border: "1px solid #E0E2E7",
  boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
  borderRadius: "8px",
};

export const filterHandlerStl = {
  // width: "calc(100% - 0px)",
  border: `1px solid ${colors.border}`,
  borderRadius: "16px 16px 0 0",
  borderBotton: "none",
  padding: "12px 16px",
};
