import { Box, Typography } from "@mui/material";
import { NoticeSwitchSelectorProps } from "./noticeTypes";
import { colors } from "../../theme";
import CheckboxWrapper from "../../components/CustomCheckbox/Checkbox";

const NoticeSwitchSelector: React.FC<NoticeSwitchSelectorProps> = ({ id, title, isChecked, onChange }) => {
  return (
    <Box
      key={id}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flex: "1 0 0",
        padding: "12px 16px",
        border: `1px solid ${colors.borderDefault}`,
        borderRadius: "16px",
      }}
    >
      <Typography variant="body1Small600">{title}</Typography>
      <CheckboxWrapper checked={isChecked} onChange={onChange} />
    </Box>
  );
};

export default NoticeSwitchSelector;
