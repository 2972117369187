import React from 'react'

export default function SearchIcon() {
  return (
    <svg width="374" height="260" viewBox="0 0 374 260" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M373.832 245.054H0.287109V245.24H373.832V245.054Z" fill="#E3E3E3" />
      <path d="M64.2221 257.074H39.4785V257.261H64.2221V257.074Z" fill="#E3E3E3" />
      <path d="M134.636 259.106H101.391V259.293H134.636V259.106Z" fill="#E3E3E3" />
      <path d="M117.579 250.141H64.9688V250.328H117.579V250.141Z" fill="#E3E3E3" />
      <path d="M336.402 251.396H326.922V251.583H336.402V251.396Z" fill="#E3E3E3" />
      <path d="M320.265 251.396H292.75V251.583H320.265V251.396Z" fill="#E3E3E3" />
      <path d="M230.636 254.549H207.387V254.736H230.636V254.549Z" fill="#E3E3E3" />
      <path d="M177.348 211.734H33.0924C31.9623 211.732 30.8792 211.281 30.0808 210.481C29.2824 209.682 28.834 208.598 28.834 207.468V4.68531C28.8438 3.56171 29.2966 2.48736 30.0939 1.69562C30.8912 0.903889 31.9687 0.458712 33.0924 0.456787H177.348C178.479 0.456787 179.564 0.906227 180.364 1.70623C181.164 2.50624 181.614 3.59128 181.614 4.72266V207.468C181.614 208.599 181.164 209.684 180.364 210.484C179.564 211.284 178.479 211.734 177.348 211.734ZM33.0924 0.606205C32.0118 0.608184 30.9762 1.03882 30.2129 1.80358C29.4495 2.56835 29.0208 3.60476 29.0208 4.68531V207.468C29.0208 208.548 29.4495 209.585 30.2129 210.349C30.9762 211.114 32.0118 211.545 33.0924 211.547H177.348C178.429 211.545 179.465 211.114 180.23 210.35C180.995 209.585 181.425 208.549 181.427 207.468V4.68531C181.425 3.60407 180.995 2.56768 180.23 1.80313C179.465 1.03858 178.429 0.60818 177.348 0.606205H33.0924Z" fill="#E3E3E3" />
      <path d="M338.951 211.734H194.688C193.557 211.732 192.473 211.281 191.674 210.482C190.874 209.682 190.424 208.598 190.422 207.468V4.68531C190.434 3.56103 190.888 2.48669 191.687 1.69516C192.485 0.903627 193.563 0.458691 194.688 0.456787H338.951C340.073 0.460675 341.149 0.906721 341.944 1.69825C342.74 2.48977 343.192 3.563 343.202 4.68531V207.468C343.202 208.596 342.754 209.679 341.957 210.479C341.161 211.278 340.079 211.73 338.951 211.734ZM194.688 0.606205C193.607 0.60818 192.57 1.03858 191.806 1.80313C191.041 2.56768 190.611 3.60407 190.609 4.68531V207.468C190.611 208.549 191.041 209.585 191.806 210.35C192.57 211.114 193.607 211.545 194.688 211.547H338.951C340.032 211.545 341.068 211.114 341.833 210.35C342.597 209.585 343.028 208.549 343.03 207.468V4.68531C343.028 3.60407 342.597 2.56768 341.833 1.80313C341.068 1.03858 340.032 0.60818 338.951 0.606205H194.688Z" fill="#E3E3E3" />
      <path opacity="0.6" d="M237.525 170.801V10.6993L40.7123 10.6993V170.801H237.525Z" fill="#E3E3E3" />
      <path d="M238.713 170.8V10.6993H237.525V170.8H238.713Z" fill="#67838E" fillOpacity="0.2" />
      <path d="M50.4169 161.096L227.828 161.096L227.828 20.4039L50.4169 20.4039L50.4169 161.096Z" fill="#F5F5F5" />
      <path d="M131.222 161.096H155.04L115.362 20.4041H91.5371L131.222 161.096Z" fill="white" />
      <path d="M112.096 161.096H125.872L86.1943 20.4041H72.418L112.096 161.096Z" fill="white" />
      <path d="M188.442 161.096H194.149L154.471 20.4041H148.764L188.442 161.096Z" fill="white" />
      <path d="M318.457 238.913H110.766C110.052 238.916 109.345 238.775 108.686 238.498C108.028 238.221 107.433 237.814 106.936 237.301C106.439 236.788 106.051 236.18 105.795 235.514C105.539 234.847 105.421 234.135 105.447 233.422L110.363 84.9152C110.424 83.4456 111.047 82.0556 112.104 81.0324C113.161 80.0091 114.57 79.4307 116.041 79.4167H323.776C324.491 79.4142 325.198 79.556 325.857 79.8338C326.515 80.1115 327.11 80.5194 327.607 81.0329C328.104 81.5464 328.492 82.155 328.748 82.8222C329.003 83.4893 329.122 84.2012 329.095 84.9152L324.18 233.422C324.114 234.897 323.483 236.291 322.418 237.313C321.352 238.336 319.934 238.908 318.457 238.913Z" fill="#67838E" fillOpacity="0.2" />
      <g opacity="0.6">
        <path opacity="0.3" d="M316.762 238.913H109.071C108.357 238.916 107.649 238.775 106.991 238.498C106.333 238.221 105.737 237.814 105.24 237.301C104.744 236.788 104.355 236.18 104.1 235.514C103.844 234.847 103.725 234.135 103.752 233.422L108.615 84.9152C108.676 83.4456 109.3 82.0556 110.356 81.0324C111.413 80.0091 112.822 79.4307 114.293 79.4167H321.984C322.698 79.4142 323.406 79.556 324.064 79.8338C324.722 80.1115 325.318 80.5194 325.815 81.0329C326.311 81.5464 326.699 82.155 326.955 82.8222C327.211 83.4893 327.329 84.2012 327.303 84.9152L322.387 233.422C322.323 234.88 321.706 236.26 320.661 237.28C319.616 238.3 318.222 238.884 316.762 238.913Z" fill="#FAFAFA" />
      </g>
      <path d="M314.064 238.644H63.2214C61.9811 238.644 60.7662 238.995 59.7168 239.656C58.6673 240.317 57.8259 241.261 57.2895 242.379C57.153 242.67 57.0925 242.99 57.1134 243.31C57.1344 243.63 57.2362 243.94 57.4093 244.21C57.5825 244.48 57.8213 244.702 58.1034 244.855C58.3856 245.008 58.7019 245.086 59.0227 245.084H318.263C318.584 245.088 318.901 245.01 319.184 244.858C319.468 244.705 319.707 244.484 319.881 244.213C320.055 243.943 320.158 243.633 320.179 243.312C320.201 242.991 320.14 242.67 320.003 242.379C319.466 241.26 318.624 240.315 317.573 239.654C316.522 238.993 315.305 238.643 314.064 238.644Z" fill="#67838E" fillOpacity="0.2" />
      <path opacity="0.6" d="M63.2214 238.644H263.441C264.68 238.645 265.894 238.996 266.942 239.657C267.99 240.318 268.831 241.262 269.366 242.379C269.504 242.669 269.566 242.99 269.546 243.311C269.526 243.632 269.425 243.943 269.251 244.214C269.078 244.484 268.838 244.706 268.555 244.859C268.271 245.011 267.954 245.088 267.632 245.084H59.0227C58.7019 245.086 58.3856 245.008 58.1034 244.855C57.8213 244.702 57.5825 244.48 57.4093 244.21C57.2362 243.94 57.1344 243.63 57.1134 243.31C57.0925 242.99 57.153 242.67 57.2895 242.379C57.8259 241.261 58.6673 240.317 59.7168 239.656C60.7663 238.995 61.9811 238.644 63.2214 238.644Z" fill="#FAFAFA" />
      <path opacity="0.3" d="M182.883 242.723H130.804C130.185 242.723 129.592 242.968 129.155 243.405C128.718 243.842 128.473 244.435 128.473 245.054H185.214C185.214 244.435 184.968 243.842 184.531 243.405C184.094 242.968 183.501 242.723 182.883 242.723Z" fill="#67838E" fillOpacity="0.2" />
      <path opacity="0.9" d="M108.689 228.05L113.253 90.2868C113.278 89.7093 113.525 89.1639 113.943 88.7643C114.361 88.3648 114.917 88.1421 115.495 88.1426H320.324C320.602 88.1414 320.878 88.1966 321.135 88.3048C321.391 88.4131 321.623 88.5722 321.817 88.7726C322.01 88.973 322.161 89.2104 322.26 89.4707C322.359 89.7309 322.404 90.0085 322.393 90.2868L317.836 228.05C317.809 228.626 317.561 229.17 317.144 229.568C316.726 229.966 316.172 230.187 315.595 230.187H110.758C110.481 230.187 110.206 230.131 109.95 230.023C109.695 229.915 109.463 229.756 109.27 229.557C109.077 229.358 108.926 229.121 108.826 228.863C108.726 228.604 108.68 228.327 108.689 228.05Z" fill="white" />
      <path opacity="0.7" d="M114.12 222.185L118.326 95.9721C118.347 95.2751 118.639 94.6138 119.14 94.1285C119.641 93.6431 120.311 93.3719 121.008 93.3722H314.288C314.648 93.372 315.005 93.4444 315.337 93.5852C315.669 93.726 315.969 93.9323 316.219 94.1918C316.47 94.4512 316.665 94.7584 316.794 95.0951C316.923 95.4318 316.983 95.7911 316.97 96.1514L312.793 222.342C312.768 223.038 312.474 223.698 311.972 224.181C311.47 224.665 310.801 224.935 310.104 224.935H116.832C116.471 224.939 116.114 224.87 115.781 224.733C115.447 224.596 115.145 224.393 114.892 224.136C114.639 223.88 114.44 223.575 114.307 223.239C114.175 222.904 114.111 222.546 114.12 222.185Z" fill="#F0F0F0" />
      <path d="M271.808 230.194H305.771L266.631 88.1426H232.676L271.808 230.194Z" fill="white" />
      <path d="M250.181 230.194H262.396L223.263 88.1426H211.041L250.181 230.194Z" fill="white" />
      <path d="M180.642 230.194H192.864L153.724 88.1426H141.502L180.642 230.194Z" fill="white" />
      <path opacity="0.4" d="M219.639 169.448C219.624 169.879 219.443 170.287 219.134 170.588C218.825 170.889 218.412 171.058 217.981 171.062C217.771 171.064 217.562 171.024 217.368 170.943C217.175 170.862 216.999 170.742 216.853 170.591C216.707 170.44 216.593 170.261 216.519 170.064C216.444 169.868 216.41 169.658 216.419 169.448C216.437 169.017 216.619 168.608 216.93 168.308C217.24 168.008 217.654 167.838 218.085 167.834C218.295 167.833 218.503 167.875 218.696 167.956C218.889 168.038 219.063 168.157 219.208 168.308C219.354 168.459 219.467 168.638 219.541 168.834C219.615 169.03 219.648 169.239 219.639 169.448Z" fill="white" />
      <path opacity="0.5" d="M118.328 174.357C106.617 162.647 98.6414 147.727 95.4101 131.484C92.1788 115.241 93.8367 98.4046 100.174 83.1039C106.512 67.8033 117.244 54.7255 131.014 45.5245C144.784 36.3235 160.973 31.4125 177.535 31.4125C194.096 31.4125 210.285 36.3235 224.055 45.5245C237.825 54.7255 248.557 67.8033 254.895 83.1039C261.232 98.4046 262.89 115.241 259.659 131.484C256.428 147.727 248.452 162.647 236.741 174.357C221.037 190.057 199.741 198.877 177.535 198.877C155.329 198.877 134.032 190.057 118.328 174.357Z" fill="white" />
      <path d="M298.884 221.289C298.594 219.789 297.937 218.385 296.971 217.202C295.044 215.133 292.982 213.198 290.95 211.226C288.918 209.253 286.841 207.341 284.726 205.451C282.612 203.56 280.505 201.678 278.361 199.825C274.043 196.142 269.628 192.563 265.041 189.149C262.949 187.603 260.835 186.079 258.668 184.599C259.18 183.214 259.284 181.712 258.967 180.27C258.651 178.828 257.927 177.507 256.882 176.464L253.737 173.318C253.187 172.767 252.557 172.302 251.869 171.936C266.279 153.078 272.982 129.447 270.62 105.831C268.257 82.2153 257.006 60.3804 239.147 44.7498C221.287 29.1193 198.153 20.862 174.433 21.6506C150.712 22.4392 128.178 32.2148 111.396 48.997C94.614 65.7793 84.8384 88.3132 84.0497 112.034C83.2611 135.754 91.5185 158.888 107.149 176.748C122.78 194.607 144.614 205.858 168.23 208.22C191.846 210.583 215.477 203.88 234.335 189.47C234.696 190.159 235.16 190.789 235.71 191.338L238.863 194.491C239.908 195.532 241.229 196.254 242.67 196.57C244.111 196.886 245.613 196.784 246.998 196.276C248.493 198.435 249.987 200.55 251.548 202.642C254.962 207.229 258.541 211.651 262.217 215.962C264.077 218.106 265.952 220.25 267.85 222.335C269.747 224.419 271.675 226.504 273.625 228.551C275.575 230.598 277.532 232.645 279.601 234.58C280.791 235.539 282.196 236.193 283.695 236.485C285.764 236.875 287.894 236.794 289.926 236.246C292.039 235.729 293.969 234.643 295.508 233.106C297.046 231.569 298.134 229.639 298.652 227.527C299.198 225.492 299.277 223.359 298.884 221.289ZM115.892 176.77C103.695 164.578 95.387 149.044 92.0196 132.13C88.6523 115.217 90.3764 97.6845 96.9741 81.7511C103.572 65.8177 114.746 52.1988 129.085 42.617C143.423 33.0351 160.281 27.9208 177.527 27.9208C194.772 27.9208 211.63 33.0351 225.968 42.617C240.307 52.1988 251.482 65.8177 258.079 81.7511C264.677 97.6845 266.401 115.217 263.034 132.13C259.666 149.044 251.359 164.578 239.162 176.77C222.813 193.113 200.643 202.294 177.527 202.294C154.41 202.294 132.24 193.113 115.892 176.77Z" fill="#1BB2FF" />
      <path d="M264.951 76.6598C263.838 74.1123 262.598 71.5871 261.268 69.189L261.97 68.8079C263.308 71.2584 264.563 73.7985 265.706 76.3685L264.951 76.6598ZM258.033 63.6904C254.318 57.8694 249.979 52.4709 245.094 47.5906L245.662 47.0228C250.586 51.9473 254.96 57.3933 258.706 63.2645L258.033 63.6904Z" fill="#1BB2FF" />
    </svg>

  )
}
