export const stlBtn = {
  width: "125px",
  height: "55px",
  padding: "16px 23px",
  textAlign: "center",
  textDecoration: "none",
  margin: "1.5rem 2px 2px",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "24px",
  gap: "10px",
  borderRadius: "8px",
};

export const feedBackBtnStl = {
  zIndex: 1300,
  position: "fixed",
  top: "75%",
  // right: "-43px",
  padding: "0.5rem",
  borderRadius: "8px",
  background: "darkorchid",
  color: "darkorchid",
  transform: "rotate(-90deg)",
  fontSize: "10px",
  height: "12px",
  transition: "all 0.5s ease",
  "&:hover": {
    height: "32px",
    right: "-33px",
    fontSize: "1rem",
    backgroundColor: "#131330",
    color: "#FFF",
  },
};

export const feedBackBtnStlExpanded = {
  zIndex: 1300,
  position: "fixed",
  top: "75%",
  // right: "-43px",
  padding: "0 0.5rem",
  borderRadius: "8px",
  transform: "rotate(-90deg)",
  height: "32px",
  right: "-33px",
  fontSize: "10px",

  background: "#131330",
  color: "#FFF",
  "&:hover": {
    background: "#131330",
    color: "#FFF",
  },
};
