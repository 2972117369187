import React from "react";

export function AdvancedReportIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.9271 6.95927V16.5243C19.9271 16.7445 19.8396 16.9557 19.6839 17.1114C19.5282 17.2671 19.317 17.3546 19.0968 17.3546C18.8765 17.3546 18.6654 17.2671 18.5096 17.1114C18.3539 16.9557 18.2665 16.7445 18.2665 16.5243V9.676L12.1223 12.6119C11.4744 12.9943 10.7348 13.1935 9.98259 13.1882C9.19368 13.1894 8.41911 12.9774 7.74079 12.5746L1.61319 9.65773C1.13152 9.39872 0.727939 9.01553 0.444319 8.54792C0.160699 8.08032 0.00737577 7.54534 0.000259522 6.99849C-0.00685672 6.45164 0.132493 5.91285 0.403849 5.43802C0.675205 4.96319 1.06868 4.56963 1.54345 4.29817C1.56587 4.28488 1.58995 4.27243 1.61319 4.2608L7.80472 1.30661C8.47316 0.92273 9.2318 0.723846 10.0026 0.730413C10.7734 0.736979 11.5285 0.94876 12.1903 1.34397L18.3138 4.2608C18.797 4.52815 19.2008 4.91864 19.4841 5.39261C19.7675 5.86657 19.9203 6.4071 19.9271 6.95927ZM9.98176 14.8471C8.91946 14.8494 7.8754 14.571 6.95532 14.04L3.32111 12.3056V14.5474C3.32161 15.4337 3.60538 16.2967 4.13101 17.0104C4.65664 17.7241 5.39661 18.2511 6.24293 18.5145C7.45212 18.8617 8.70554 19.0304 9.96349 19.0152C11.2216 19.0293 12.475 18.8595 13.6841 18.5112C14.5304 18.2478 15.2703 17.7208 15.796 17.0071C16.3216 16.2934 16.6054 15.4304 16.6059 14.544V12.3097L12.9036 14.0782C12.0149 14.5888 11.0067 14.8547 9.98176 14.8488V14.8471Z"
        fill="white"
      />
    </svg>
  );
}

export function AdvancedReportIconHeader() {
  return (
    <svg width="32" height="34" viewBox="0 0 32 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 18.9758H1.14286C0.511675 18.9758 0 19.4875 0 20.1186V31.5472C0 32.1784 0.511675 32.6901 1.14286 32.6901H8C8.63118 32.6901 9.14286 32.1784 9.14286 31.5472V20.1186C9.14286 19.4875 8.63118 18.9758 8 18.9758Z"
        fill="#506077"
      />
      <path
        d="M30.8574 9.83292H24.0003C23.3691 9.83292 22.8574 10.3446 22.8574 10.9758V31.5472C22.8574 32.1784 23.3691 32.6901 24.0003 32.6901H30.8574C31.4886 32.6901 32.0003 32.1784 32.0003 31.5472V10.9758C32.0003 10.3446 31.4886 9.83292 30.8574 9.83292Z"
        fill="#506077"
      />
      <path
        d="M19.4287 0.690063H12.5716C11.9404 0.690063 11.4287 1.20174 11.4287 1.83292V31.5472C11.4287 32.1784 11.9404 32.6901 12.5716 32.6901H19.4287C20.0599 32.6901 20.5716 32.1784 20.5716 31.5472V1.83292C20.5716 1.20174 20.0599 0.690063 19.4287 0.690063Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
