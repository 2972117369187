interface IconProps {
  color?: string;
}

const LeftArrowIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color ?? "#101D41";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M15 17.2892C15.39 16.8992 15.39 16.2692 15 15.8792L11.12 11.9992L15 8.11925C15.39 7.72925 15.39 7.09925 15 6.70925C14.61 6.31925 13.98 6.31925 13.59 6.70925L8.99998 11.2992C8.60998 11.6892 8.60998 12.3192 8.99998 12.7092L13.59 17.2992C13.97 17.6792 14.61 17.6792 15 17.2892Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default LeftArrowIcon;
