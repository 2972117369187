import React, { useEffect, useState } from "react";
import { Box, Popper, ClickAwayListener } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import DropdownButton from "../../components/Button/DropdownButton";
import { defaultPopperStyle } from "../../components/Dropdown/Dropdown";
import { useGetAllPartnersConfiguration } from "../../core/configuration/configurationsApi";
import { IPartnerConfig } from "../../core/configuration/interfaces";
import { SimpleDropdown } from "../SimpleDropdown/SimpleDropdown";
import { setCurrentPlatformParameter } from "../../parametersConstancy/platformParametersSlice";
import { setParametersConstancyWrapper } from "../../parametersConstancy/parametersConstancyUtils";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";

const SelectSinglePartner: React.FC = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchQuery, setSearchQuery] = useState("");
  const { data: partners } = useGetAllPartnersConfiguration();
  const [filteredPartners, setFilteredPartners] = useState(partners);
  const dispatch = useDispatch();
  const { selectedPartnerId } = useSelector(
    (state: RootState) => state.platformParameters.currentPlatformParameters.configurationParameters
  );

  const [selectedPartner, setSelectedPartner] = useState<IPartnerConfig | null>(
    partners?.find((p) => p.partnerDummyId === selectedPartnerId) || null
  );

  useEffect(() => {
    setFilteredPartners(partners);
  }, [partners]);

  useEffect(() => {
    selectedPartner?.partnerDummyId &&
      dispatch(
        setCurrentPlatformParameter(
          selectedPartner?.partnerDummyId &&
            setParametersConstancyWrapper(
              "configurationParameters",
              "selectedPartnerId",
              selectedPartner?.partnerDummyId
            )
        )
      );
  }, [selectedPartner]);

  const handleButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setOpen((prevOpen) => !prevOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleSelectPartner = (id: string) => {
    setSelectedPartner(partners?.find((p) => p.partnerDummyId === id) || null);
    setOpen(false);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
    const filtered = partners?.filter((p: IPartnerConfig) =>
      p.partnerName.toLowerCase().includes(value.toLowerCase())
    );

    setFilteredPartners(filtered);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      <ClickAwayListener
        onClickAway={() => {
          if (open) {
            setOpen(false);
            setAnchorEl(null);
          }
        }}
      >
        <div>
          <DropdownButton
            title={selectedPartner ? selectedPartner.partnerName : "Select partner"}
            variant="filter"
            dropdownOpened={open}
            isDisabled={false}
            buttonCallback={handleButtonClick}
            clearIconCallback={() => setSelectedPartner(null)}
            shouldBeSelected={!!selectedPartner}
            base={CONSTANT.REPORTS.PARTNERS_CONFIG.base}
            selectedItemsLength={selectedPartner ? 1 : 0}
          />

          <Popper
            id="partners-popper"
            open={open}
            anchorEl={anchorEl}
            disablePortal={false}
            placement="bottom-end"
            sx={defaultPopperStyle}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "document",
                },
              },
            ]}
          >
            <SimpleDropdown
              options={
                filteredPartners?.map((p) => {
                  return {
                    id: p.partnerDummyId,
                    name: p.partnerName,
                  };
                }) || []
              }
              selectedItem={
                selectedPartner
                  ? {
                      id: selectedPartner?.partnerDummyId,
                      name: selectedPartner?.partnerName,
                    }
                  : null
              }
              onSelectItem={handleSelectPartner}
              onSearch={handleSearch}
            />
          </Popper>
        </div>
      </ClickAwayListener>
    </Box>
  );
};

export default SelectSinglePartner;
