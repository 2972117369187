import { Navigate } from "react-router-dom";
import auth from "./auth-helper";

const PrivateRoute = ({ children, forAdmin = true, modules = [] }) => {
  const isAuth = auth.isAuthenticated();
  if (!isAuth) return <Navigate to="/login" />;
  const permitted = modules.length === 0;
  // const permitted = modules.length === 0 || auth.checkModulesVisibility(modules); // not in usage
  if (forAdmin && permitted) return children;
  return "Page is not accessible";
};

export default PrivateRoute;
