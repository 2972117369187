import EventSource from "eventsource";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";
import { ResponseNotificationDto } from "../core/notifications/noticeTypes";
// window.Buffer = window.Buffer || require("buffer").Buffer;
type EventCallback = (notification: ResponseNotificationDto) => void;

class SseEventManager {
  private eventSource: EventSource | null = null;
  private callbacks: EventCallback[] = [];
  private reconnectAttempt: number = 0;
  private maxReconnectDelay: number = 60000; // Maximum delay of 1 minute

  connect(userId: number) {
    if (this.eventSource) {
      this.eventSource.close();
    }

    let token = auth.isAuthenticated().data.accessToken;
    const url = `${CONSTANT.path.host}/sse`;

    this.eventSource = new EventSource(url, {
      headers: {
        "Content-Type": "text/event-stream",
        "Cache-Control": "no-cache",
        Connection: "keep-alive",
        Authorization: `Bearer ${token}`,
      },

      // heartbeatTimeout: CONSTANT.KEEP_ALIVE_TIMEOUT,
    });

    this.eventSource.onmessage = (event) => {
      try {
        const parsedData = JSON.parse(event.data);

        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.HEARTBEAT) {
          // console.log("Heartbeat received");
          return;
        }
        if (parsedData.type === CONSTANT.SSE_EVENT_TYPES.NEW_NOTIFICATION) {
          this.callbacks.forEach((callback) => callback(parsedData.data));
        }
      } catch (error) {
        console.error("Error parsing SSE message:", error);
      }
    };

    this.eventSource.onopen = () => {
      // console.log("SSE connection opened");
      this.reconnectAttempt = 0; // Reset reconnect attempt counter on successful connection
    };
    this.eventSource.onerror = (error) => {
      if (error instanceof Error) {
        console.error("Error message:", error.message);
        console.error("Error stack:", error.stack);
      }
      if (this.eventSource?.readyState === EventSource.CLOSED) {
        this.eventSource.close();
        this.scheduleReconnect(userId);
      } else {
        // Handle other types of errors
        this.handleError(error);
      }
    };
  }
  private handleError(error: any) {
    // Implement your error handling logic here
    console.error("Handling SSE error:", error);
    // You might want to notify the user, log the error, or take other actions
  }
  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
    this.reconnectAttempt = 0; // Reset reconnect attempt counter on disconnect
  }

  subscribe(callback: EventCallback) {
    this.callbacks.push(callback);
    return () => {
      this.callbacks = this.callbacks.filter((cb) => cb !== callback);
    };
  }

  private scheduleReconnect(userId: number) {
    const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempt), this.maxReconnectDelay);
    console.log(`Attempting to reconnect in ${delay}ms`);

    setTimeout(() => {
      this.reconnectAttempt++;
      this.connect(userId);
    }, delay);
  }
}

export const sseEventManager = new SseEventManager();
