import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { setReminderActivated } from '../../../chartSelector/chartSelectorSlice';

import "./teststyle.css";

const stl = {
  wrapper: {
    "& #light2": {
      animation: "light2 3.8s linear infinite",
      "@keyframes light2": {
        "0%": {
          transform: "translate(1%)",
          opacity: .01,
        },
        "50%": {
          transform: "translate(5%)",
          opacity: 1,
        },
        "75%": {
          transform: "translate(20%)",
          opacity: 1,
        },
        "100%": {
          transform: "translate(25%)",
          opacity: 0.1,
        }
      },
    },

    "& #dark1": {
      animation: "dark1 3.2s linear infinite",
      "@keyframes dark1": {
        "0%": {
          opacity: 0,
          transform: "translate(1%)",
        },
        "50%": {
          opacity: 1,
          transform: "translate(5%)",
        },
        "100%": {
          opacity: .1,
          transform: "translate(25%)",
        }
      }
    },
  },
};


export default function Reminder({ elemRef, stats, id = 1, top = "5px", lt = "-50px" }) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (!Boolean(elemRef.current)) return;
    if (stats) {
      if (document.querySelector("#test" + id)) {
        document.querySelector("#test" + id).style.display = "none";
      }

      dispatch(setReminderActivated(true));
      return;
    }

    if (document.querySelector("#test" + id)) return;

    let innerEl = document.createElement('div');
    innerEl.setAttribute('id', 'test' + id);
    innerEl.style.cssText = `position:absolute;top:${top};left:${lt}`;
    innerEl.innerHTML = `<svg width="80" height="40" viewBox="0 0 30 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="triangles">
          <g class="lightGroup">
            <path class="dark1" d="M12.5 8.13397C13.1667 8.51887 13.1667 9.48113 12.5 9.86603L5 14.1962C4.33333 14.5811 3.5 14.0999 3.5 13.3301L3.5 4.66987C3.5 3.90007 4.33333 3.41895 5 3.80385L12.5 8.13397Z" fill="#8F3737" fill-opacity="0.38"/>
          </g>
          <g class="lightGroup_2">
            <path class="light2" d="M12.5 8.13397C13.1667 8.51887 13.1667 9.48113 12.5 9.86603L5 14.1962C4.33333 14.5811 3.5 14.0999 3.5 13.3301L3.5 4.66987C3.5 3.90007 4.33333 3.41895 5 3.80385L12.5 8.13397Z" fill="#1BB2FF" fill-opacity="0.46"/>
            <path class="light1" d="M17.5 8.13397C18.1667 8.51887 18.1667 9.48113 17.5 9.86603L10 14.1962C9.33333 14.5811 8.5 14.0999 8.5 13.3301L8.5 4.66987C8.5 3.90007 9.33333 3.41895 10 3.80385L17.5 8.13397Z" fill="#84BDE7" fill-opacity="0.44"/>
          </g>
        </g>
      </svg>`;

    elemRef.current.appendChild(innerEl);

  }, [stats, elemRef.current])

  return null;
}
