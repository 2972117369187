import React from "react";
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Typography,
  Paper,
  FormHelperText,
} from "@mui/material";
import { styled } from "@mui/system";
import { colors } from "../../theme";

interface RadioInputItem {
  label: string;
  value: string;
  inputValue: number;
  onValueChange: (value: number) => void;
  helperText?: string;
  disabled?: boolean;
}

interface CustomRadioInputGroupProps {
  items: RadioInputItem[];
  selectedValue: string;
  onRadioChange: (value: string) => void;
  helperText?: string;
  disabled?: boolean;
}

const StyledPaper = styled(Paper)<{ isSelected: boolean; disabled: boolean }>(({ isSelected, disabled }) => ({
  display: "flex",
  alignItems: "flex-start",
  padding: "8px",
  borderRadius: "16px",
  flexDirection: "column",
  width: "100%",
  backgroundColor: disabled ? colors.gray50 : isSelected ? colors.foreground02 : colors.gray50,
  transition: "background-color 0.3s ease",
}));

const CustomRadioInput: React.FC<RadioInputItem & { isSelected: boolean; onSelect: () => void }> = ({
  label,
  value,
  inputValue,
  onValueChange,
  isSelected,
  onSelect,
  disabled = false,
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled) onValueChange(Number(event.target.value));
  };

  return (
    <StyledPaper
      elevation={0}
      isSelected={isSelected}
      disabled={disabled}
      onClick={!disabled ? onSelect : undefined}
    >
      <FormControlLabel
        disabled={disabled}
        value={value}
        control={<Radio disabled={disabled} checked={isSelected} color="primary" />}
        label={<Typography variant="body1Small400">{label}</Typography>}
      />
      <TextField
        disabled={disabled}
        variant="outlined"
        size="medium"
        value={isSelected ? inputValue : ""}
        onChange={handleInputChange}
        sx={{ width: "100%", backgroundColor: "white", border: "none", borderRadius: "8px" }}
        InputProps={{
          sx: {
            fontSize: "16px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#E0E0E0",
              borderRadius: "8px",
              //padding: "8px 8px",
            },
            "& .MuiInputBase-input ": {
              padding: "10px 10px",
            },
          },
        }}
      />
    </StyledPaper>
  );
};

const CustomRadioInputGroup: React.FC<CustomRadioInputGroupProps> = ({
  items,
  selectedValue,
  onRadioChange,
  helperText,
  disabled = false,
}) => {
  return (
    <RadioGroup
      row
      value={selectedValue}
      onChange={(event) => onRadioChange(event.target.value)}
      sx={{
        gap: "16px",
        //height: "112px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        //marginBottom: "20px",
        padding: 0,
      }}
    >
      {items.map((item) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "47%",
            padding: 0,
          }}
          key={item.value}
        >
          <CustomRadioInput
            disabled={disabled}
            key={item.value}
            label={item.label}
            value={item.value}
            inputValue={item.inputValue}
            onValueChange={item.onValueChange}
            isSelected={selectedValue === item.value}
            onSelect={() => onRadioChange(item.value)}
          />
        </Box>
      ))}
      {helperText && <FormHelperText error={true}>{helperText}</FormHelperText>}
    </RadioGroup>
  );
};

export default CustomRadioInputGroup;
