export const loginContainer = {
  display: "flex",
  height: "100vh",
  overflow: "hidden",
};

export const formWrapper = {
  margin: "1.25rem",
  width: {
    xs: "100%",
    sm: "100%",
    md: "33%",
    lg: "23.1875rem",
    xl: "33.1875rem",
  },
  backgroundColor: "#001738",
  borderRadius: "1.5625rem",
  p: {
    xs: "1.5rem",
    sm: "1.5rem",
    md: "1rem",
    lg: "2rem",
    xl: "5.0625rem",
  },
};

export const formContainer = {
  height: "100%",
  borderRadius: "20px",
  position: "relative",
  zIndex: 1,
};

export const logoContainer = {
  img: {
    width: {
      xs: "100px",
      sm: "140px",
      md: "140px",
      lg: "150px",
      xl: "190px",
    },
    height: {
      xs: "28px",
      sm: "38px",
      md: "38px",
      lg: "40px",
      xl: "46px",
    },
  },
};

export const titleSx = {
  mt: {
    xs: "8.5rem",
    sm: "8.5rem",
    md: "10.1rem",
    lg: "10.5rem",
    xl: "13.65rem",
  },
  fontWeight: 700,
  fontSize: {
    sm: "1.5rem",
    md: "1.1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
  color: "#fff",
  mb: "0.6rem",
};

export const titleSpanSx = {
  fontWeight: 700,
  fontSize: {
    sm: "1.5rem",
    md: "1.1rem",
    lg: "1.5rem",
    xl: "2rem",
  },
  lineHeight: "39px",
  color: "secondary.lightBlue",
  ml: "3px",
};

export const mainBackgroundContainer = {
  background: "#fff",
  position: "relative",
  display: {
    xs: "none",
    md: "flex",
  },
  flexGrow: {
    xs: 0,
    md: 1,
  },
  justifyContent: "center",
  alignItems: "center",
};

export const subtitleSx = {
  fontWeight: "500",
  fontSize: {
    sm: "1.1rem",
    md: "0.7rem",
    lg: "0.7rem",
    xl: "1.125rem",
  },
  color: "#fff",
};

export const error = {
  color: "primary.main",
  mt: "5px",
  fontSize: "14px",
};

export const inputSx = {
  mt: "0.2rem",
  width: "100%",
  // width: {
  //   xs: "calc(80% - 10px)",
  //   sm: "calc(50% - 10px)",
  //   lg: "calc(60% - 10px)",
  //   xl: "100%",
  // },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #fff",
    outline: "none",
  },
  input: {
    color: "#fff",
    padding: {
      xs: "0.5rem",
      sm: "0.5rem",
      md: "0.5rem",
      lg: "0.5rem",
      xl: "0.85rem",
    },
    fontSize: {
      xs: "0.625rem",
      sm: "0.5rem",
      md: "0.625rem",
      lg: "0.7rem",
      xl: "0.875rem",
    },
    "&::placeholder": {
      color: "#fff",
    },
  },
};

export const loaderSx = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const mainBackgroundIcon = {
  width: "130%",
  ml: "-30%",
  position: "relative",
  zIndex: "0",
  height: "100vh",
};

export const buttonSx = {
  width: {
    xs: "calc(80% - 10px)",
    sm: "calc(50% - 10px)",
    lg: "calc(70% - 10px)",
    xl: "15.3125rem",
  },
  width: "100%",
  padding: {
    xs: "0.25rem",
    sm: "0.5rem",
    md: "0.625rem",
    lg: "0.7rem",
    xl: "1rem",
  },
  mt: "2rem",
  // mt: {
  //   xs: ".75rem",
  //   sm: "1.5rem",
  //   md: "2rem",
  //   lg: "2.825rem",
  //   xl: "3.5625rem",
  // },
  borderRadius: "0.5rem",
  fontWeight: 600,
  fontSize: {
    xs: "0.625rem",
    sm: "0.625rem",
    md: "0.625rem",
    lg: "0.825rem",
    xl: "1.125rem",
  },
  backgroundColor: "secondary.lightBlue",
  color: "#FFFFFF",
};

export const campaignReviewSpan = {
  position: "absolute",
  left: "40px",
  bottom: "56px",
};
