import { createSlice } from "@reduxjs/toolkit";
import { CONSTANT } from "../constants/constants";
import { daysAgoSinceStartDate, getMTD } from "../utils";

const RANGE_DATE_YESTERDAY = CONSTANT.DATE_RANGE_TYPES.YESTARDAY.value;
const LAST_MONTH = CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value;
const MTD = getMTD();
const YTD = daysAgoSinceStartDate();
const initialState = {
  [RANGE_DATE_YESTERDAY]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  7: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [MTD]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [LAST_MONTH]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [YTD]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [-1]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  [-10]: {
    client_type: [],
    biddercode: [],
    site_id: [],
    placement_id: [],
  },
  processing: false,
  status: "idle",
};

export const savedRawDataSlice = createSlice({
  name: "savedRawData",
  initialState,
  reducers: {
    setSavedRawDataType(state, action) {
      let { activeRangeDate, filterBase, result, rangeTypeConstancy } = action.payload;
      if (rangeTypeConstancy) {
        const rangeType = rangeTypeConstancy;
        if (
          (rangeType.type === CONSTANT.DATE_RANGE_TYPES.YTD.type && YTD !== rangeType.value) ||
          (rangeType.type === CONSTANT.DATE_RANGE_TYPES.MTD.type && MTD !== rangeType.value)
        ) {
          const newMTD = getMTD();
          const newYTD = daysAgoSinceStartDate();
          state[newMTD] = {
            client_type: [],
            biddercode: [],
            site_id: [],
            placement_id: [],
          };
          state[newYTD] = {
            client_type: [],
            biddercode: [],
            site_id: [],
            placement_id: [],
          };
          delete state[YTD];
          delete state[MTD];
        }
      } else if (!(activeRangeDate in state)) {
        state[activeRangeDate] = {
          client_type: [],
          biddercode: [],
          site_id: [],
          placement_id: [],
        };
      }
      state[activeRangeDate][filterBase] = result.data;
      state.status = "succeeded";
    },
    resetSavedRawDataState(state, action) {
      for (let i in state) {
        if (typeof state[i] == "object") {
          state[i].client_type = [];
          state[i].biddercode = [];
          state[i].site_id = [];
          state[i].placement_id = [];
        }
      }
    },
    resetSavedCustomRawDataState(state, action) {
      state[-1].client_type = [];
      state[-1].biddercode = [];
      state[-1].site_id = [];
      state[-1].placement_id = [];
    },
  },
});

export const { setSavedRawDataType, resetSavedRawDataState, resetSavedCustomRawDataState } =
  savedRawDataSlice.actions;

export default savedRawDataSlice.reducer;
