import { createAsyncThunk } from "@reduxjs/toolkit";
import { setSavedRawDataType } from "../../savedRawDataPreviousPeriod/savedRawDataPreviousPeriodSlice";
import auth from "../../auth/auth-helper";
import { calcCustomDateforPreviousReport } from "../../utils";
import { CONSTANT } from "../../constants/constants";
import { transformColumnBaseToRowBase } from "../../core/performanceReports/advancedReport/utils";
const host = CONSTANT.path.host;

export const getReportPreviousPeriod = createAsyncThunk(
  "report/getReportPreviousPeriod",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { token, signal } = report;
    let currentState = getState();
    let jwt = auth.isAuthenticated();
    let ct = currentState.report.active_chart;
    let rt = report.rt;
    const customDateRange =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange;
    switch (rt) {
      case CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value: {
        break;
      }
      case CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value: {
        break;
      }
      case CONSTANT.DATE_RANGE_TYPES.CUSTOM.value: {
        break;
      }
      default: {
        rt = rt * 2;
      }
    }
    let dgm =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode !==
      CONSTANT.DATE_GROUP_MODES.HOUR
        ? currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode
        : CONSTANT.DATE_GROUP_MODES.DAY;
    let base = currentState.report.filterBase === "summary" ? "client_type" : currentState.report.filterBase;
    let target = "profile";
    let pidParam = "";
    let customDateParam = "";
    let rpp = "&rpp=1"; // reports of previous period
    let isAdmin = jwt.data.user.scope.title === "admin" ? true : false;
    let skipSetBaseAsActive =
      "skipSetFilterBaseAsActive" in report && report.skipSetFilterBaseAsActive ? true : false;
    let skipInternalSearch = "skipInternalSearch" in report && report.skipInternalSearch ? true : false;
    let customDateEnd = "";
    let customDateStart = "";

    if (isAdmin) {
      if (!Boolean(currentState.role.partnersSelected.length)) {
        return {
          result: { data: [] },
          activeChart: ct,
          activeRangeDate: rt === CONSTANT.DATE_RANGE_TYPES.LAST_MONTH.value ? rt : rt * 2,
        };
      }
      target = "profile_observe";
      let pids = currentState.role.partnersSelected.map((p) => p.dummy_id).join(",");
      pidParam = `&pid=${pids}`;
      // pidParam = `&pid=${currentState.role.partnersSelected}`;
    }
    if ("ct" in report) ct = report.ct;

    if ("setFilterBase" in report) base = report.setFilterBase === "summary" ? base : report.setFilterBase;

    if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      rpp = "";
      let res = calcCustomDateforPreviousReport(
        customDateRange.customDateStart,
        customDateRange.customDateEnd,
        dgm,
      );
      customDateStart = res.customDateStart;
      customDateEnd = res.customDateEnd;
      customDateParam = `&cstm_start=${customDateStart}&cstm_end=${customDateEnd}`;
    }

    if (rt === CONSTANT.DATE_RANGE_TYPES.ALL_TIME.value)
      return {
        result: { data: [] },
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        skipSetBaseAsActive: skipSetBaseAsActive,
      };

    if (
      !skipInternalSearch &&
      currentState.savedRawDataPreviousPeriod[rt] &&
      currentState.savedRawDataPreviousPeriod[rt][base]?.length > 0
    ) {
      let result = currentState.savedRawDataPreviousPeriod[rt][base];
      let data = {
        result: { data: result },
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        skipSetBaseAsActive: skipSetBaseAsActive,
      };
      if ("setFilterBase" in report) data.filterBase = report.setFilterBase;
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        data.customDateRange = { customDateStart, customDateEnd };
      }
      return data;
    }

    try {
      let response = await fetch(
        `${host}/reports/${target}/${report.id}?rt=${rt}&ct=${ct}&b=${base}&dgm=${dgm}${pidParam}${rpp}${customDateParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        },
      );

      if (!response.ok) {
        throw new Error("server can not get report");
      }

      const rangeTypeConstancy = currentState.platformParameters.currentPlatformParameters.rangeType;

      let result = {};
      const responseJSON = await response.json();
      result.data = transformColumnBaseToRowBase(responseJSON.data);
      result.reactAppVersion = responseJSON.reactAppVersion;

      let data = {
        result,
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        filterBase: base,
        skipSetBaseAsActive,
        rangeTypeConstancy,
      };

      if ("customDateRange" in report) data.customDateRange = { customDateStart, customDateEnd };
      dispatch(setSavedRawDataType(data));
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
