export const container_MonthPickerTis = {
  fontSize: "1rem",
  marginTop: "17px",
  width: "100%",
  height: "22.5rem",
  display: "flex",
  flexDirection: "column",
  backgroundColor: "#fff",
  padding: "5px 10px 10px",
  transition: "0.2s",
  borderRadius: "5px",
  zIndex: "10",
};
export const header_MonthPickerTis = {
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10px",
  paddingLeft: "7px",
};
export const year_MonthPickerTis = {
  fontWeight: 700,
  cursor: "default",
  transition: "0.2s",
};
export const navigation_MonthPickerTis = {
  marginTop: "1px",
};
export const navIcon_MonthPickerTis = {
  backgroundColor: "transparent",
  border: "none",
  cursor: "pointer",
  outline: "none",
};
// .container_MonthPickerTis > .header_MonthPickerTis > .navigation_MonthPickerTis > .navIcon_MonthPickerTis svg {
//   vertical - align: middle;
//   fill: #5d6670;
// }
export const months_MonthPickerTis = {
  display: "flex",
  flexWrap: "wrap",
  flexGrow: 1,
};
export const month_MonthPickerTis = {
  width: "33.3333%",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  color: "#001738",
  cursor: "pointer",
  transition: "0.2s",
  "&:hover:not(#theFirst):not(#theLast)": {
    backgroundColor: "#ccf3ff",
    borderRadius: "15px",
    color: "#000 !important",
  },
};
export const selected_MonthPickerTis = {
  backgroundColor: "#3d91ff",
  color: "#fff !important",
};
export const selectedFirst_MonthPickerTis = {
  backgroundColor: "#844ae9",
  color: "#fff !important",
  borderTopLeftRadius: "15px",
  borderBottomLeftRadius: "15px",
};
export const selectedLast_MonthPickerTis = {
  backgroundColor: "#3d91ff",
  color: "#fff !important",
  borderTopRightRadius: "15px",
  borderBottomRightRadius: "15px",
};
