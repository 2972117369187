export function AccountsIcon() {
  return (
    <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2887 12.2231C15.1341 11.1725 14.608 10.2121 13.8059 9.51619C13.0038 8.82025 11.9788 8.43486 10.9169 8.42993H9.92723C8.86531 8.43486 7.84034 8.82025 7.03825 9.51619C6.23616 10.2121 5.71008 11.1725 5.55547 12.2231L5.08108 15.5401C5.06593 15.6473 5.07594 15.7566 5.11033 15.8593C5.14472 15.9619 5.20254 16.0552 5.27921 16.1317C5.46524 16.3177 6.60655 17.2665 10.423 17.2665C14.2395 17.2665 15.378 16.3214 15.5668 16.1317C15.6435 16.0552 15.7013 15.9619 15.7357 15.8593C15.7701 15.7566 15.7801 15.6473 15.7649 15.5401L15.2887 12.2231Z"
        fill="#C0C7CF"
      />
      <path
        d="M6.29099 8.94158C5.39202 9.77031 4.8071 10.8843 4.6353 12.0948L4.29114 14.476C1.52856 14.4574 0.691417 13.4529 0.551893 13.2482C0.49796 13.1739 0.459473 13.0895 0.438704 13C0.417935 12.9106 0.415306 12.8178 0.430972 12.7273L0.635607 11.5739C0.742225 10.9711 0.992624 10.4029 1.36557 9.91753C1.73852 9.43211 2.22303 9.04377 2.778 8.78545C3.33298 8.52713 3.94206 8.40645 4.5536 8.43363C5.16515 8.4608 5.76112 8.63505 6.29099 8.94158Z"
        fill="#506077"
      />
      <path
        d="M20.4108 12.7273C20.4265 12.8178 20.4238 12.9106 20.4031 13C20.3823 13.0895 20.3438 13.1739 20.2899 13.2482C20.1504 13.4529 19.3132 14.4574 16.5506 14.476L16.2065 12.0948C16.0347 10.8843 15.4498 9.77031 14.5508 8.94158C15.0807 8.63505 15.6766 8.4608 16.2882 8.43363C16.8997 8.40645 17.5088 8.52713 18.0638 8.78545C18.6187 9.04377 19.1033 9.43211 19.4762 9.91753C19.8492 10.4029 20.0995 10.9711 20.2062 11.5739L20.4108 12.7273Z"
        fill="#506077"
      />
      <path
        d="M6.44862 6.89521C6.21447 7.22727 5.90364 7.49791 5.54252 7.68414C5.1814 7.87037 4.78066 7.96668 4.37435 7.96489C3.96904 7.96489 3.56953 7.86858 3.20875 7.68388C2.84796 7.49919 2.53623 7.23141 2.29924 6.9026C2.06225 6.5738 1.90679 6.19338 1.84566 5.79271C1.78454 5.39203 1.8195 4.98256 1.94767 4.59805C2.07584 4.21354 2.29355 3.86499 2.58286 3.58112C2.87216 3.29726 3.22478 3.0862 3.61166 2.96535C3.99853 2.8445 4.40859 2.81731 4.80803 2.88603C5.20747 2.95475 5.58487 3.1174 5.90912 3.36059C5.81586 3.72531 5.76898 4.10033 5.7696 4.47678C5.77031 5.32988 6.00518 6.16641 6.44862 6.89521Z"
        fill="#506077"
      />
      <path
        d="M19.0248 5.40695C19.025 5.74293 18.959 6.07567 18.8306 6.38612C18.7021 6.69657 18.5137 6.97866 18.2761 7.21623C18.0385 7.45381 17.7565 7.64222 17.446 7.77068C17.1356 7.89914 16.8028 7.96514 16.4668 7.96489C16.0605 7.96668 15.6598 7.87037 15.2987 7.68414C14.9376 7.49791 14.6267 7.22727 14.3926 6.89521C14.836 6.16641 15.0709 5.32988 15.0716 4.47678C15.0722 4.10033 15.0253 3.72531 14.9321 3.36059C15.3121 3.07557 15.764 2.902 16.2371 2.85934C16.7102 2.81667 17.1859 2.90661 17.6108 3.11905C18.0357 3.33149 18.393 3.65806 18.6428 4.06215C18.8925 4.46625 19.0248 4.93191 19.0248 5.40695Z"
        fill="#506077"
      />
      <path
        d="M10.4207 7.96486C12.3472 7.96486 13.9088 6.40319 13.9088 4.47676C13.9088 2.55033 12.3472 0.988647 10.4207 0.988647C8.4943 0.988647 6.93262 2.55033 6.93262 4.47676C6.93262 6.40319 8.4943 7.96486 10.4207 7.96486Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
export function AccountsIconHeader() {
  return (
    <svg width="32" height="28" viewBox="0 0 32 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23.7885 18.9416C23.5411 17.2606 22.6994 15.724 21.416 14.6105C20.1327 13.4969 18.4927 12.8803 16.7936 12.8724H15.2101C13.5111 12.8803 11.8711 13.4969 10.5878 14.6105C9.30441 15.724 8.4627 17.2606 8.21531 18.9416L7.4563 24.2487C7.43205 24.4202 7.44807 24.5951 7.50309 24.7594C7.55811 24.9237 7.65062 25.0729 7.7733 25.1952C8.07095 25.4929 9.89704 27.0109 16.0034 27.0109C22.1097 27.0109 23.9313 25.4988 24.2334 25.1952C24.3561 25.0729 24.4486 24.9237 24.5036 24.7594C24.5587 24.5951 24.5747 24.4202 24.5504 24.2487L23.7885 18.9416Z"
        fill="#C0C7CF"
      />
      <path
        d="M9.39215 13.691C7.95379 15.017 7.01793 16.7994 6.74305 18.7362L6.19239 22.5462C1.77226 22.5164 0.43283 20.9091 0.209591 20.5817C0.123299 20.4627 0.0617187 20.3277 0.0284885 20.1846C-0.00474167 20.0414 -0.00894839 19.8931 0.0161169 19.7483L0.343534 17.9028C0.514122 16.9384 0.91476 16.0292 1.51148 15.2526C2.1082 14.4759 2.8834 13.8546 3.77136 13.4412C4.65932 13.0279 5.63385 12.8348 6.61233 12.8783C7.5908 12.9218 8.54436 13.2006 9.39215 13.691Z"
        fill="#506077"
      />
      <path
        d="M31.9835 19.7483C32.0085 19.8931 32.0043 20.0414 31.9711 20.1846C31.9379 20.3277 31.8763 20.4627 31.79 20.5817C31.5667 20.9091 30.2273 22.5164 25.8072 22.5462L25.2565 18.7362C24.9816 16.7994 24.0458 15.017 22.6074 13.691C23.4552 13.2006 24.4088 12.9218 25.3872 12.8783C26.3657 12.8348 27.3403 13.0279 28.2282 13.4412C29.1162 13.8546 29.8914 14.4759 30.4881 15.2526C31.0848 16.0292 31.4855 16.9384 31.656 17.9028L31.9835 19.7483Z"
        fill="#506077"
      />
      <path
        d="M9.64493 10.4169C9.27031 10.9482 8.77297 11.3812 8.19518 11.6792C7.61738 11.9771 6.97621 12.1312 6.32611 12.1284C5.67761 12.1284 5.0384 11.9743 4.46114 11.6787C3.88389 11.3832 3.38511 10.9548 3.00593 10.4287C2.62675 9.90261 2.37801 9.29394 2.28021 8.65286C2.18241 8.01178 2.23835 7.35664 2.44342 6.74142C2.6485 6.1262 2.99683 5.56852 3.45972 5.11433C3.92261 4.66014 4.4868 4.32246 5.1058 4.1291C5.7248 3.93573 6.38089 3.89224 7.02 4.00218C7.65911 4.11213 8.26294 4.37238 8.78174 4.76148C8.63252 5.34502 8.55752 5.94506 8.5585 6.54739C8.55964 7.91234 8.93544 9.2508 9.64493 10.4169Z"
        fill="#506077"
      />
      <path
        d="M29.766 8.03565C29.7664 8.57322 29.6608 9.1056 29.4553 9.60232C29.2497 10.0991 28.9483 10.5504 28.5682 10.9305C28.188 11.3106 27.7367 11.6121 27.24 11.8176C26.7433 12.0232 26.2109 12.1288 25.6733 12.1284C25.0232 12.1312 24.382 11.9771 23.8042 11.6792C23.2265 11.3812 22.7291 10.9482 22.3545 10.4169C23.064 9.2508 23.4398 7.91234 23.4409 6.54739C23.4419 5.94507 23.3669 5.34502 23.2177 4.76148C23.8257 4.30544 24.5488 4.02773 25.3058 3.95947C26.0628 3.89121 26.8238 4.0351 27.5036 4.37501C28.1835 4.71493 28.7552 5.23743 29.1548 5.88398C29.5544 6.53053 29.766 7.27558 29.766 8.03565Z"
        fill="#506077"
      />
      <path
        d="M15.9999 12.1283C19.0822 12.1283 21.5809 9.62963 21.5809 6.54734C21.5809 3.46506 19.0822 0.96637 15.9999 0.96637C12.9176 0.96637 10.4189 3.46506 10.4189 6.54734C10.4189 9.62963 12.9176 12.1283 15.9999 12.1283Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
export function UsersManagementIconHeader() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2554 16.6159C19.7764 16.6159 23.4414 12.9509 23.4414 8.42988C23.4414 3.90885 19.7764 0.243835 15.2554 0.243835C10.7344 0.243835 7.06934 3.90885 7.06934 8.42988C7.06934 12.9509 10.7344 16.6159 15.2554 16.6159Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3857 30.7733C15.8333 29.1123 14.8837 26.8812 14.8837 24.4299C14.8837 21.9279 15.8735 19.6567 17.4809 17.9838C16.7561 17.9287 16.0119 17.8989 15.2558 17.8989C10.3114 17.8989 5.93414 19.1358 3.21637 20.9888C1.14307 22.4027 0 24.1992 0 26.085V28.2446C0 28.9144 0.266419 29.5588 0.741209 30.0336C1.216 30.5069 1.85898 30.7748 2.53023 30.7748L17.3857 30.7733Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.186 16.6159C19.8727 16.6159 16.3721 20.1166 16.3721 24.4299C16.3721 28.7432 19.8727 32.2438 24.186 32.2438C28.4993 32.2438 32 28.7432 32 24.4299C32 20.1166 28.4993 16.6159 24.186 16.6159ZM25.3023 23.3136V21.8252C25.3023 21.209 24.8022 20.7089 24.186 20.7089C23.5698 20.7089 23.0697 21.209 23.0697 21.8252V23.3136H21.5814C20.9652 23.3136 20.4651 23.8137 20.4651 24.4299C20.4651 25.046 20.9652 25.5461 21.5814 25.5461H23.0697V27.0345C23.0697 27.6507 23.5698 28.1508 24.186 28.1508C24.8022 28.1508 25.3023 27.6507 25.3023 27.0345V25.5461H26.7907C27.4069 25.5461 27.907 25.046 27.907 24.4299C27.907 23.8137 27.4069 23.3136 26.7907 23.3136H25.3023Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
export function RolesManagementIconHeader() {
  return (
    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.2554 16.6159C19.7764 16.6159 23.4414 12.9509 23.4414 8.42988C23.4414 3.90885 19.7764 0.243835 15.2554 0.243835C10.7344 0.243835 7.06934 3.90885 7.06934 8.42988C7.06934 12.9509 10.7344 16.6159 15.2554 16.6159Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.3857 30.7733C15.8333 29.1123 14.8837 26.8812 14.8837 24.4299C14.8837 21.9279 15.8735 19.6567 17.4809 17.9838C16.7561 17.9287 16.0119 17.8989 15.2558 17.8989C10.3114 17.8989 5.93414 19.1358 3.21637 20.9888C1.14307 22.4027 0 24.1992 0 26.085V28.2446C0 28.9144 0.266419 29.5588 0.741209 30.0336C1.216 30.5069 1.85898 30.7748 2.53023 30.7748L17.3857 30.7733Z"
        fill="#506077"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.186 16.6159C19.8727 16.6159 16.3721 20.1166 16.3721 24.4299C16.3721 28.7432 19.8727 32.2438 24.186 32.2438C28.4993 32.2438 32 28.7432 32 24.4299C32 20.1166 28.4993 16.6159 24.186 16.6159ZM25.3023 23.3136V21.8252C25.3023 21.209 24.8022 20.7089 24.186 20.7089C23.5698 20.7089 23.0697 21.209 23.0697 21.8252V23.3136H21.5814C20.9652 23.3136 20.4651 23.8137 20.4651 24.4299C20.4651 25.046 20.9652 25.5461 21.5814 25.5461H23.0697V27.0345C23.0697 27.6507 23.5698 28.1508 24.186 28.1508C24.8022 28.1508 25.3023 27.6507 25.3023 27.0345V25.5461H26.7907C27.4069 25.5461 27.907 25.046 27.907 24.4299C27.907 23.8137 27.4069 23.3136 26.7907 23.3136H25.3023Z"
        fill="#C0C7CF"
      />
    </svg>
  );
}
