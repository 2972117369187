import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setChangedParameter } from "../platformParametersSlice";
import { setParametersConstancyWrapper } from "../parametersConstancyUtils";
import { CONSTANT } from "../../constants/constants";
import { setActiveFilters } from "../../core/performanceReports/advancedReport/advancedReportSlice";
import { useLocation } from "react-router-dom";
import { CategoryType } from "../parametersConstancyTypes";

const OverviewChangedParametersHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { filterBase, units, unit, selectedPct, selectedCurrency, settingsStrictMode, active_chart } = useSelector(
    (state: RootState) => state.report,
  );
  const { filters } = useSelector((state: RootState) => state.advancedReport);
  const { active_type } = useSelector((state: RootState) => state.chartSelector);

  useEffect(() => {
    dispatch(setChangedParameter(setParametersConstancyWrapper("overviewParameters", "groupType", active_type)));
  }, [active_type, dispatch]);

  useEffect(() => {
    dispatch(setChangedParameter(setParametersConstancyWrapper("overviewParameters", "chartBase", filterBase)));
  }, [filterBase, dispatch]);

  useEffect(() => {
    dispatch(setChangedParameter(setParametersConstancyWrapper("overviewParameters", "chartType", active_chart)));
  }, [active_chart, dispatch]);

  useEffect(() => {
    let changedUnit = [];
    if (unit.length > 0 && unit.length === units.length) {
      changedUnit = [
        {
          partnerInstaceId: CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name,
          clientItemName: CONSTANT.REPORTS.SELECTED_ALL_OBJECT.name,
          unitId: "" + CONSTANT.REPORTS.SELECTED_ALL_OBJECT.id,
          color: `#${((Math.random() * 0xffffff) << 0).toString(16).padStart(6, "0")}`,
        },
      ];
    } else {
      changedUnit = unit;
    }
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("overviewParameters", "chartBaseUnits", {
          [filterBase]: {
            unit: changedUnit,
            units: [],
          },
        }),
      ),
    );

    // Function to create activeFilterItems
    const createActiveFilterItems = (idKey: number | string, nameKey: string, filterBase: string) =>
      unit.map((item) => {
        const id = item[idKey];
        let convertedId = typeof id === "string" ? Number(id) : id;
        const itemId = !isNaN(convertedId as number) ? convertedId : id;
        return {
          id: itemId,
          name: String(item[nameKey]),
          isSelected: true,
        };
      });

    // Object to map filterBase to corresponding activeFilterBase and item keys
    const filterBaseConfig = {
      [CONSTANT.OVERVIEW.CHART_BASES.CLIENT_TYPE]: {
        activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.BROWSERS.base,
        idKey: "unitId",
        nameKey: "partnerInstaceId",
      },
      [CONSTANT.OVERVIEW.CHART_BASES.BIDDERCODE]: {
        activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.BIDDERS.base,
        idKey: "partnerInstaceId",
        nameKey: "partnerInstaceId",
      },
      [CONSTANT.OVERVIEW.CHART_BASES.SITE_ID]: {
        activeFilterBase: CONSTANT.REPORTS.FILTERS_KEYS.SITES.base,
        idKey: "unitId",
        nameKey: "partnerInstaceId",
      },
    };

    if (location.pathname === CONSTANT.PAGES.OVERVIEW.path) {
      const activeFilters = Object.fromEntries(
        Object.entries(filters)
          .filter(([_, values]) => Array.isArray(values))
          .map(([key, values]) => [key, values.filter((unit: CategoryType) => unit.isSelected)]),
      );
      if (filterBaseConfig[filterBase]) {
        const { activeFilterBase, idKey, nameKey } = filterBaseConfig[filterBase];
        const payload: any = { ...activeFilters };
        // Reset all filter arrays
        payload.biddercodes = [];
        payload.browsers = [];
        payload.sites = [];
        const activeFilterItems = createActiveFilterItems(idKey, nameKey, filterBase);
        payload[activeFilterBase] = activeFilterItems;
        dispatch(setActiveFilters(payload));
      } else if (filterBase === CONSTANT.OVERVIEW.CHART_BASES.SUMMARY) {
        const payload = { ...activeFilters };
        payload.biddercodes = [];
        payload.browsers = [];
        payload.sites = [];
        dispatch(setActiveFilters(payload));
      }
    }
  }, [units, unit, filterBase, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("overviewParameters", "selectedCurrency", selectedCurrency),
      ),
    );
    dispatch(setChangedParameter(setParametersConstancyWrapper("overviewParameters", "selectedPct", selectedPct)));
  }, [selectedPct, selectedCurrency, dispatch]);
  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("overviewParameters", "settingsStrictMode", settingsStrictMode),
      ),
    );
  }, [settingsStrictMode, dispatch]);

  return null;
};

export default OverviewChangedParametersHandler;
