import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { applyPctMode } from "../../report/reportSlice";
import auth from "../../auth/auth-helper";
import { getReport } from "../../report/reportThunks/getReport";
import { getReportPreviousPeriod } from "../../report/reportThunks/getReportPreviousPeriod";
import { colors } from "../../theme";
import { Typography } from "@mui/material";
import { CONSTANT } from "../../constants/constants";
import { applyPctAndCurrencyToSummaryChart } from "../../summary/summaryThunks/applyPctAndCurrencyToSummaryChart";

export default function Selection() {
  const initialRender = useRef(true);
  const dispatch = useDispatch();
  const { filterBase, selectedPct, selectedCurrency, uniqPairs } = useSelector((state) => state.report);
  const customDateRange = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters.customDateRange,
  );
  const { dateRange } = useSelector(
    (state) => state.platformParameters.currentPlatformParameters.commonUserPlatformParameters,
  );
  const active_range_date = dateRange.value;
  const { allTimeDateStart, allTimeDateEnd } = useSelector((state) => state.summary);

  const [val, setval] = useState(`${selectedCurrency}-${selectedPct}`);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    setval(`${selectedCurrency}-${selectedPct}`);
  }, [selectedCurrency, selectedPct]);

  const handleChange = (event) => {
    const jwt = auth.isAuthenticated();
    const abortController = new AbortController();
    const signal = abortController.signal;

    let payloadReports = {
      id: jwt.data.user.id,
      setFilterBase: filterBase,
      token: jwt.data.accessToken,
      skipRefreshUints: true,
      rt: active_range_date,
      customDateRange: customDateRange,
      // ct: active_chart == 0 ? 1 : active_chart,
      signal,
      allTimeDateStart,
      allTimeDateEnd,
    };

    if (active_range_date === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value)
      payloadReports.customDateRange = customDateRange;
    setval(event.target.value);
    dispatch(applyPctMode(event.target.value));

    dispatch(applyPctAndCurrencyToSummaryChart(event.target.value));

    dispatch(getReport(payloadReports))
      .unwrap()
      .then(() => dispatch(getReportPreviousPeriod(payloadReports)));
  };

  const options = uniqPairs.map((i) => {
    let str = `${i.currency}-${i.pct}`;
    return (
      <MenuItem key={"option_select_" + str} value={str}>
        <Typography variant="body1Small600">{str}</Typography>
      </MenuItem>
    );
  });

  return (
    <Box id="theSelection" sx={{ width: 100 }}>
      <FormControl fullWidth>
        <Select
          style={{
            width: "100px",
            fontSize: "1rem",
            outline: "none",
            height: "30px",
            border: "none",
            fontWeight: 700,
          }}
          MenuProps={{
            sx: {
              "& .MuiMenuItem-root.Mui-selected": {
                backgroundColor: colors.lightGrey,
              },
            },
          }}
          variant="standard"
          disableUnderline
          value={val}
          onChange={handleChange}
        >
          {options}
        </Select>
      </FormControl>
    </Box>
  );
}
