import {
  getValuesFromRawDataForWidgets,
  convertToValues,
  filterValues,
  prepareLineChartsForWidgetBoxes,
  getReportValues,
  prepareReportDates,
  convertToZeroValues,
  getPercentageDifference,
} from "../utils";

export const getSummaryReducer = (state, action) => {
  let { data, resultPreviousPeriod } = action.payload.result;

  if (data.length === 0) {
    state.widgetChartCurrentData = [];
    state.widgetChartPreviousPeriodData = [];
    state.currentPeriodSummaryValues = convertToZeroValues();
    state.previousPeriodSummaryValues = convertToZeroValues();
    // state.currentSummaryRawData = {}//seems this is not in usage - commented for a while
    state.comparisonResult = {};

    return;
  }
  data = data.filter((i) => i.pct === state.selectedPct && i.currency === state.selectedCurrency);
  resultPreviousPeriod = resultPreviousPeriod.filter(
    (i) => i.pct === state.selectedPct && i.currency === state.selectedCurrency,
  );

  let filterBase = state.filterBase;
  let pct = state.selectedPct;

  // let pct = data[0].pct;
  let summaryFilterBase = state.unit.map((i) => i.unitId);
  switch (filterBase) {
    case "client_type":
      data = filterValues(data, summaryFilterBase, "browser");
      resultPreviousPeriod = filterValues(resultPreviousPeriod, summaryFilterBase, "browser");
      break;
    case "site_id":
      data = filterValues(data, summaryFilterBase, "dpsite_vr_id");
      resultPreviousPeriod = filterValues(resultPreviousPeriod, summaryFilterBase, "dpsite_vr_id");
      break;
    case "biddercode":
      summaryFilterBase = state.unit.map((i) => i.clientItemName);
      data = filterValues(data, summaryFilterBase, "biddercode");
      resultPreviousPeriod = filterValues(resultPreviousPeriod, summaryFilterBase, "biddercode");
      break;
    case "placement_id":
      summaryFilterBase = state.unit.map((i) => +i.clientItemName);
      data = filterValues(data, summaryFilterBase, "placement_id");
      resultPreviousPeriod = filterValues(resultPreviousPeriod, summaryFilterBase, "placement_id");
      break;
  }

  //get agregated values, calculate for widgets and add to store
  let agregatedValues = getValuesFromRawDataForWidgets(data);
  let agregatedValuesPreviousPeriod = getValuesFromRawDataForWidgets(resultPreviousPeriod);

  let { activeRangeDate, dateGroupingMode } = getReportValues(action);
  let { reportDateStart, chartLastDate } = prepareReportDates(activeRangeDate, action, state);
  let { reportDateStart: prevreportDateStart, chartLastDate: prevchartLastDate } = prepareReportDates(
    activeRangeDate,
    action,
    state,
    true,
  );

  state.widgetDataProcessing = false;
  // state.currentSummaryRawData = agregatedValues//seems this is not in usage - commented for a while
  state.previousPeriodSummaryRawData = agregatedValuesPreviousPeriod;
  state.currentPeriodSummaryValues = convertToValues(agregatedValues, pct);
  state.previousPeriodSummaryValues =
    resultPreviousPeriod.length > 0 ? convertToValues(agregatedValuesPreviousPeriod, pct) : convertToZeroValues();
  // state.previousPeriodSummaryValues = convertToValues(agregatedValuesPreviousPeriod, pct);

  let comparisonResult = {};
  if (resultPreviousPeriod.length > 0) {
    for (let i in state.previousPeriodSummaryValues) {
      comparisonResult[i] = getPercentageDifference(
        state.previousPeriodSummaryValues[i],
        state.currentPeriodSummaryValues[i],
      );
    }
  }

  state.comparisonResult = comparisonResult;
  state.widgetChartCurrentData = prepareLineChartsForWidgetBoxes(
    data,
    reportDateStart.startDate,
    chartLastDate,
    pct,
    dateGroupingMode,
  );
  state.widgetChartPreviousPeriodData = prepareLineChartsForWidgetBoxes(
    resultPreviousPeriod,
    prevreportDateStart.startDate,
    prevchartLastDate,
    pct,
    dateGroupingMode,
  );
};
