import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  FormHelperText,
  Typography,
} from "@mui/material";

interface SimpleDropdownProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  options: { id: number; name: string }[];
  error?: boolean;
  helperText?: string;
}

export const SimpleDropdown: React.FC<SimpleDropdownProps> = ({
  label,
  value,
  onChange,
  options,
  error = false,
  helperText = "",
}) => {
  const handleChange = (event: SelectChangeEvent<number>) => {
    onChange(event.target.value as number);
  };

  return (
    <FormControl fullWidth variant="outlined" error={error}>
      <Typography variant="body1" sx={{ fontWeight: 500, marginBottom: "4px" }}>
        {label}
      </Typography>
      <Select value={value ?? ""} onChange={handleChange} displayEmpty>
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {option.name}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};
