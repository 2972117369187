import React from 'react'

export function SettingsIcon() {
  return (
    <svg width="20" height="23" viewBox="0 0 20 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.372977 17.2885C1.13627 18.6132 2.829 19.0683 4.15375 18.3051C4.15466 18.3045 4.15553 18.304 4.15643 18.3035L4.56706 18.0663C5.34221 18.7295 6.23319 19.2438 7.19516 19.5834V20.0568C7.19516 21.5857 8.43461 22.8252 9.96353 22.8252C11.4924 22.8252 12.7319 21.5857 12.7319 20.0568V19.5834C13.694 19.2433 14.585 18.7284 15.36 18.0645L15.7725 18.3026C17.0975 19.067 18.7915 18.6126 19.5559 17.2875C20.3204 15.9624 19.866 14.2685 18.5409 13.504L18.1311 13.2678C18.3156 12.2642 18.3156 11.2355 18.1311 10.2318L18.5409 9.99561C19.8659 9.23115 20.3204 7.53725 19.5559 6.21216C18.7915 4.8871 17.0976 4.43261 15.7725 5.19707L15.3619 5.43424C14.5859 4.77187 13.6943 4.25851 12.7319 3.91999V3.44659C12.7319 1.91767 11.4924 0.678223 9.96353 0.678223C8.43461 0.678223 7.19516 1.91767 7.19516 3.44659V3.91999C6.23306 4.26011 5.34203 4.77502 4.56706 5.43891L4.15457 5.19993C2.82947 4.43542 1.13558 4.88991 0.371117 6.21497C-0.393343 7.54002 0.0611027 9.23396 1.3862 9.99842L1.79592 10.2346C1.61148 11.2382 1.61148 12.267 1.79592 13.2706L1.3862 13.5069C0.0647794 14.2734 -0.388196 15.964 0.372977 17.2885ZM9.96353 8.06056C12.0021 8.06056 13.6547 9.71315 13.6547 11.7517C13.6547 13.7903 12.0021 15.4429 9.96353 15.4429C7.92497 15.4429 6.27238 13.7903 6.27238 11.7517C6.27238 9.71315 7.92497 8.06056 9.96353 8.06056Z" fill="#1BB2FF" />
    </svg>
  )
}

export const SettingsIconHeader = (props) => {
  return (
    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.26056 0.197266H10.2606C13.2061 0.197266 15.5939 2.58508 15.5939 5.53058V9.53058C15.5939 12.4761 13.2061 14.8639 10.2606 14.8639H6.26056C3.31506 14.864 0.927246 12.4761 0.927246 9.53058V5.53058C0.927246 2.58508 3.31506 0.197266 6.26056 0.197266Z" fill="#001738" />
      <path d="M23.5941 0.197266H27.5941C30.5396 0.197266 32.9274 2.58508 32.9274 5.53058V9.53058C32.9274 12.4761 30.5396 14.8639 27.5941 14.8639H23.5941C20.6486 14.8639 18.2607 12.4761 18.2607 9.53058V5.53058C18.2607 2.58508 20.6485 0.197266 23.5941 0.197266Z" fill="#001738" />
      <path d="M6.26056 17.5306H10.2606C13.2061 17.5306 15.5939 19.9185 15.5939 22.864V26.8639C15.5939 29.8095 13.2061 32.1973 10.2606 32.1973H6.26056C3.31506 32.1973 0.927246 29.8095 0.927246 26.864V22.864C0.927246 19.9185 3.31506 17.5306 6.26056 17.5306Z" fill="#001738" />
      <path d="M23.5941 17.5306H27.5941C30.5396 17.5306 32.9274 19.9185 32.9274 22.864V26.864C32.9274 29.8095 30.5396 32.1973 27.5941 32.1973H23.5941C20.6486 32.1973 18.2607 29.8095 18.2607 26.864V22.864C18.2607 19.9185 20.6485 17.5306 23.5941 17.5306Z" fill="#001738" />
    </svg>

  );
}