import React, { ReactNode, useEffect, useState } from "react";
import { Box, SxProps } from "@mui/material";
import GroupFilter from "./GroupFilter";
import { CONSTANT, getCurrentPageIdByLocationPathname } from "../../../../constants/constants";
import { useLocation } from "react-router-dom";
import { CategoriesType, CategoryType } from "../../../../parametersConstancy/parametersConstancyTypes";

export interface FilterGroupHandlerProps {
  options: CategoriesType;
  values: CategoriesType;
  isReadOnlyView?: boolean;
  setFiltersOptions: React.Dispatch<React.SetStateAction<CategoriesType>>;
  setFiltersValues: React.Dispatch<React.SetStateAction<CategoriesType>>;
  withPartners?: boolean;
  selectAllEnabled?: boolean;
  actionItemsTop?: ReactNode;
  base?: string;
  additionalFilterIsReporting?: boolean;
  textFieldStyle?: SxProps;
  id?: string;
}

const GroupFilterHandler: React.FC<FilterGroupHandlerProps> = ({
  options,
  values,
  isReadOnlyView = false,
  setFiltersOptions,
  setFiltersValues,
  withPartners = false,
  selectAllEnabled = false,
  actionItemsTop,
  base = CONSTANT.REPORTS.PARTNERS.base,
  additionalFilterIsReporting = false,
  textFieldStyle,
  id,
}) => {
  const location = useLocation();
  const [openFilter, setOpenFilter] = useState<string>("");
  const handleToggleOpen = (base: string) => () => {
    setOpenFilter((prev) => (prev === base ? "" : base));
  };
  const [searchValue, setSearchValue] = useState<string>("");
  const [selectAllSelected, setSelectAllSelected] = useState(options[base]?.length === 0);
  const handleSelectAllPartners = (selectAllSelected: boolean) => {
    setSelectAllSelected(selectAllSelected);
    if (selectAllSelected) {
      setFiltersValues({
        [CONSTANT.REPORTS.PARTNERS.base]: values[CONSTANT.REPORTS.PARTNERS.base].concat(
          options[CONSTANT.REPORTS.PARTNERS.base]
        ),
      });
      setFiltersOptions({ [CONSTANT.REPORTS.PARTNERS.base]: [] });
    } else {
      setFiltersValues({ [CONSTANT.REPORTS.PARTNERS.base]: [] });
      setFiltersOptions({
        [CONSTANT.REPORTS.PARTNERS.base]: options[CONSTANT.REPORTS.PARTNERS.base].concat(
          values[CONSTANT.REPORTS.PARTNERS.base]
        ),
      });
    }
  };

  const handleSelectItems = (base: string) => (option: CategoryType) => {
    const isMultiSelection = base !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base;
    const shouldBeSelected = base === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && options.platforms.length === 0;
    const filterValues: CategoryType[] = values[base];
    const filterOptions: CategoryType[] = options[base];
    let newSelected = [...filterValues];
    let newUnSelected = [...filterOptions];
    const selectedIndex = filterValues.findIndex((item: CategoryType) => item.id === option.id);
    const unSelectedIndex = filterOptions.findIndex((item: CategoryType) => item.id === option.id);

    if (!isMultiSelection) {
      if (selectedIndex === -1) {
        // If the item is not already selected, select it
        // Move the currently selected item back to unselected
        if (newSelected.length > 0) {
          newUnSelected.push(newSelected[0]);
        }
        newSelected = [option];
        if (unSelectedIndex !== -1) {
          newUnSelected.splice(unSelectedIndex, 1);
        }
      } else {
        // If the item is already selected, deselect it
        newSelected = [];
        newUnSelected.push(option);
      }
    } else if (selectedIndex === -1) {
      // Multi-selection logic

      // Move from unselected to selected
      newSelected.push(option);
      if (unSelectedIndex !== -1) {
        newUnSelected.splice(unSelectedIndex, 1);
      }
    } else if (!shouldBeSelected || newSelected.length !== 1) {
      // Move from selected to unselected
      newSelected.splice(selectedIndex, 1);
      newUnSelected.push(option);
    }

    newSelected.length === newUnSelected.length && values[base].length > 0
      ? setSelectAllSelected(true)
      : setSelectAllSelected(false);
    setFiltersValues((prev) => ({ ...prev, [base]: newSelected }));
    setFiltersOptions((prev) => ({ ...prev, [base]: newUnSelected }));
  };

  useEffect(() => {
    if (selectAllEnabled && options[base].length === 0) setSelectAllSelected(true);
    else setSelectAllSelected(false);
  }, [values, options]);

  const curentPageId: number = getCurrentPageIdByLocationPathname(location.pathname) ?? -1;
  const groupFilters = withPartners ? { PARTNERS: CONSTANT.REPORTS.PARTNERS } : CONSTANT.REPORTS.FILTERS_KEYS;

  const filters = Object.values(groupFilters).map((category) => {
    const title = category.title;
    const categoryBase = category.base;
    let isDisabled = false;
    if (curentPageId === CONSTANT.PAGES.VISITOR_RECOGNITION.id) {
      isDisabled =
        (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.ACTION_TERMINATIONS.base &&
          values.platforms?.length > 0) ||
        (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.PLATFORMS.base && values.actionTerminations?.length > 0) ||
        (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && values.platforms?.length > 0) ||
        (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base && values.countries?.length > 0) ||
        (categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.COUNTRIES.base && values.sites?.length > 0);
    }
    const renderCondition = isReadOnlyView ? values[categoryBase]?.length > 0 : options[categoryBase]?.length >= 0;
    if (renderCondition && (openFilter === "" || openFilter === categoryBase)) {
      return (
        <GroupFilter
          key={categoryBase}
          isReadOnlyView={isReadOnlyView}
          options={options[categoryBase]
            .filter((option) => {
              return ("" + option.name).toLowerCase().includes(("" + searchValue).toLowerCase());
            })
            .filter((option) => (additionalFilterIsReporting ? option.isReporting : true))}
          values={values[categoryBase]
            .filter((values) => {
              return ("" + values.name).toLowerCase().includes(("" + searchValue).toLowerCase());
            })
            .filter((option) => (additionalFilterIsReporting ? option.isReporting : true))}
          handleToggleOpen={handleToggleOpen(categoryBase)}
          openedFilter={openFilter}
          base={categoryBase}
          buttonTitle={title}
          handleSelectItems={handleSelectItems(categoryBase)}
          handleSearch={(value) => setSearchValue(value)}
          isMultiSelection={categoryBase !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base}
          shouldBeSelected={
            categoryBase === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base && options.platforms?.length === 0
          }
          isDisabled={isDisabled}
          selectAllEnabled={selectAllEnabled}
          selectAllSelected={selectAllSelected}
          handleSelectAll={handleSelectAllPartners}
          actionItemsTop={actionItemsTop}
          textFieldStyle={textFieldStyle}
          id={id}
        />
      );
    }
  });

  return <Box>{filters}</Box>;
};

export default GroupFilterHandler;
