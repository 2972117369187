// src/components/ConnectionForm.tsx
import React, { useState } from "react";
import {
  Button,
  TextField,
  Box,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useCreateConnectionMutation } from "./connectionsApi";
import { ConnectionType } from "./enums";
import { useParams } from "react-router-dom";
import ConnectionTable from "./ConnectionTable";

const ConnectionForm: React.FC = () => {
  const [error, setError] = useState(false);
  const { id } = useParams();

  const [formData, setFormData] = useState({
    accountId: Number(id) as any,
    name: "",
    strategy: "",
    clientId: "",
    clientSecret: "",
    domain: "",
    description: "",
  });
  if (error) {
    //throw new Error("Intentionally thrown error for testing");
    console.log((formData as any).impossibleField.toString());
  }

  const [createConnection, { isLoading }] = useCreateConnectionMutation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    createConnection(formData)
      .unwrap()
      .then(() => alert("Connection created successfully!"))
      .catch((error) => console.error("Failed to create connection:", error));
  };

  return (
    <Box id="create-connection-form" component="form" onSubmit={handleSubmit} noValidate overflow={"hidden"}>
      <Box sx={{ mt: 1, width: "70%" }}>
        <Typography component="h1" variant="h5">
          Create Connection
        </Typography>
        <TextField
          id="name-input"
          margin="normal"
          required
          fullWidth
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          autoFocus
        />
        <FormControl fullWidth margin="normal">
          <InputLabel>Strategy</InputLabel>
          <Select
            labelId="strategy-label"
            id="strategy-select"
            name="strategy"
            value={formData.strategy}
            label="Strategy"
            onChange={handleSelectChange}
          >
            {Object.entries(ConnectionType).map(([key, value]) => (
              <MenuItem id="strategy-select-item" key={key} value={value}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          id="client-id-input"
          margin="normal"
          required
          fullWidth
          label="Client ID"
          name="clientId"
          value={formData.clientId}
          onChange={handleChange}
        />
        <TextField
          id="client-secret-input"
          margin="normal"
          required
          fullWidth
          label="Client Secret"
          name="clientSecret"
          value={formData.clientSecret}
          onChange={handleChange}
        />
        <TextField
          id="domain-input"
          margin="normal"
          required
          fullWidth
          label="Domain"
          name="domain"
          value={formData.domain}
          onChange={handleChange}
        />
        <TextField
          id="description-input"
          margin="normal"
          fullWidth
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          multiline
          rows={4}
        />
        <Button id="submit-btn" type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
          Submit
        </Button>
        {/* <Button
          onClick={() => {
            setError(true);
          }}
        >
          Throw error
        </Button> */}
      </Box>
      <Box>
        <ConnectionTable accountId={Number(id) as any}></ConnectionTable>
      </Box>
    </Box>
  );
};

export default ConnectionForm;
