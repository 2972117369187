import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { CONSTANT } from "../../constants/constants";
import auth from "../../auth/auth-helper";

interface ConnectionFormData {
  accountId: number;
  name: string;
  strategy: string;
  clientId: string;
  clientSecret: string;
  domain: string;
  description: string;
}

interface ConnectionData {
  id: number;
  accountId: number;
  name: string;
  strategy: string;
  clientId: string;
  clientSecret: string;
  domain: string;
  description: string;
  createdAt: string;
  updatedAt: string;
}

const SERVER_URL = CONSTANT.path.host;

export const connectionsApi = createApi({
  reducerPath: "connectionsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${SERVER_URL}/auth/`,
    prepareHeaders: (headers) => {
      let token = auth.isAuthenticated().data.accessToken;
      if (token) headers.set("Authorization", `Bearer ${token}`);
    },
  }),
  tagTypes: ["ConnectionAccount"],

  endpoints: (builder) => ({
    createConnection: builder.mutation<void, ConnectionFormData>({
      query: (data) => ({
        url: "connection",
        method: "POST",
        body: data,
      }),
      onQueryStarted(data, { dispatch, queryFulfilled }) {
        queryFulfilled.then(() => {
          dispatch(connectionsApi.util.invalidateTags([{ type: "ConnectionAccount", id: data.accountId }]));
        });
      },
    }),
    fetchConnectionByAccountId: builder.query<ConnectionData[], number>({
      query: (id) => `connection/account/${id}`,
      transformResponse: (response: { result: ConnectionData[] }) => response.result,
      providesTags: (result, error, id) => [{ type: "ConnectionAccount", id }],
    }),
    deleteConnection: builder.mutation<{ success: boolean; deletedConnectionId: string }, string>({
      query: (id) => ({
        url: `connection/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, id) => [{ type: "ConnectionAccount" }],
    }),
  }),
});

export const { useCreateConnectionMutation, useFetchConnectionByAccountIdQuery, useDeleteConnectionMutation } =
  connectionsApi;
