import { useState } from 'react';

export const useTogglePopovers = ({ elemRef } = false) => {
  const [anchorEl, setEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (event) => {
    if (elemRef) {
      setEl(elemRef.current);
    } else {
      setEl(event.currentTarget);
    }
  };

  const closeMenu = () => {
    setEl(null);
  };

  return {
    openMenu,
    closeMenu,
    anchorEl,
    open,
  };
};