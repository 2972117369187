import { Box, Divider, Popover, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useGetReceivedNotificationsQuery, useMarkNotificationAsReadMutation } from "./notificationsApi";
import { colors } from "../../theme";
import ReceivedNotificationsList from "./ReceivedNotificationsList";
import { NotificationLevel, NotificationStatus, ResponseNotificationDto } from "./noticeTypes";
import NotificationsListIcon from "../../components/IconComponents/NotificationsListIcon";

interface ReceivedNotificationsProps {
  setLastNotice: React.Dispatch<React.SetStateAction<ResponseNotificationDto | null>>;
  unreadMessagesCount: number;
  setUnreadMessagesCount: React.Dispatch<React.SetStateAction<number>>;
  isOpenByBannerClick: boolean;
  setIsOpenByBannerClick: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReceivedNotifications: React.FC<ReceivedNotificationsProps> = ({
  setLastNotice,
  unreadMessagesCount,
  setUnreadMessagesCount,
  isOpenByBannerClick,
  setIsOpenByBannerClick,
}) => {
  const { data: receivedNotifications, isSuccess, isLoading, refetch } = useGetReceivedNotificationsQuery();
  const [markAsRead] = useMarkNotificationAsReadMutation();

  const unreadedNotices =
    receivedNotifications?.filter((notice) => notice.status.id === NotificationStatus.SENT.id) ?? [];

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [isHoveringPopover, setIsHoveringPopover] = useState(false);
  const notificationIconRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   const intervalId = setInterval(
  //     () => {
  //       refetch();
  //     },
  //     5 * 60 * 1000,
  //   ); // 5 minutes in milliseconds
  //   return () => clearInterval(intervalId);
  // }, [refetch]);

  useEffect(() => {
    setUnreadMessagesCount(unreadedNotices.length);
    const warningUnreadedNotices = unreadedNotices.filter(
      (notice) => notice.level.id === NotificationLevel.HIGH_PRIORITY.id
    );
    if (unreadMessagesCount < unreadedNotices.length && warningUnreadedNotices.length !== 0) {
      setLastNotice(warningUnreadedNotices[warningUnreadedNotices.length - 1]);
    } else if (warningUnreadedNotices.length === 0) {
      setLastNotice(null);
    }
  }, [unreadedNotices]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (isHandlePopoverClose: boolean = false) => {
    timeoutRef.current = setTimeout(() => {
      if (isHandlePopoverClose || !isHoveringPopover) {
        setAnchorEl(null);
        setIsOpenByBannerClick(false);
      }
    }, 100);
  };
  const handlePopoverMouseEnter = () => {
    setIsHoveringPopover(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };
  const handlePopoverMouseLeave = () => {
    setIsHoveringPopover(false);
    handlePopoverClose(true);
  };
  //   const open = Boolean(anchorEl);

  const handleClickMarkAllNoticesAsRead = async (event: React.MouseEvent<HTMLSpanElement>): Promise<void> => {
    event.stopPropagation();
    const unreadedNoticesIds = unreadedNotices?.map((notice) => notice.id) ?? [];
    try {
      await markAsRead(unreadedNoticesIds).unwrap();
    } catch (error) {
      console.error("Failed to mark notification as read:", error);
    }
  };

  return (
    <Box>
      <Box
        id="notifications-icon"
        ref={notificationIconRef}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        aria-owns={Boolean(anchorEl) ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={refetch}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={() => handlePopoverClose(true)}
      >
        <NotificationsListIcon />
      </Box>
      <Popover
        id="notifications-popover"
        sx={{
          pointerEvents: "none",
          padding: "0",
          margin: "0",
        }}
        open={Boolean(anchorEl) || isOpenByBannerClick}
        anchorEl={anchorEl || notificationIconRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              display: "flex",
              width: "384px",
              height: "478px",
              padding: "8px 0px 8px 0px",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "8px",
              borderRadius: "24px",
              background: colors.mainWhite,
              boxShadow: "0px 4px 4px 0px rgba(16, 29, 65, 0.10), 0px -2px 6px 0px rgba(16, 29, 65, 0.10)",
              pointerEvents: "auto",
              overflow: "hidden",
            },
            onMouseLeave: handlePopoverMouseLeave,
            onMouseEnter: handlePopoverMouseEnter,
          },
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => handlePopoverClose(true)}
        // disableRestoreFocus
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Box
            sx={{
              padding: "0",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                padding: "8px 24px 12px 24px",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                alignSelf: "stretch",
                textAlign: "center",
                gap: "8x",
              }}
            >
              <Typography id="notifications-title" variant="h3Medium600" sx={{ fontWeight: 700 }}>
                Notifications
              </Typography>
              <Box id="notifications-mark-all-read-btn" onClick={handleClickMarkAllNoticesAsRead}>
                {unreadedNotices && unreadedNotices?.length > 0 ? (
                  <Typography
                    variant="body2XSmall"
                    sx={{ fontWeight: 600, lineHeight: "20px", color: colors.brandDefault, cursor: "pointer" }}
                  >
                    Mark all as read
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Divider
              sx={{
                width: "100%",
                borderColor: colors.borderDefault,
              }}
            />
            {!isLoading && isSuccess && receivedNotifications && receivedNotifications.length > 0 && (
              <ReceivedNotificationsList notifications={receivedNotifications} />
            )}
          </Box>{" "}
        </Box>
      </Popover>
    </Box>
  );
};

export default ReceivedNotifications;
