import { store } from "../../../store/store";
import { CONSTANT } from "../../../constants/constants";
import auth from "../../../auth/auth-helper";
import { setFilter, setFiltersIsLoading } from "./advancedReportSlice";

const SERVER_URL = CONSTANT.path.host;
const DEFAULT_SITE_FILTRATION_DESELECTION = CONSTANT.defaultPctSiteFiltrationDeselection;

const getCategories = async (categories, partnersSelected, signal, { pctSiteReportsDeselection } = false) => {
  let { advancedReport, role } = store.getState();
  if (advancedReport.lastFiltersLoadedType === CONSTANT.REPORTS.LAST_FILTERS_LOADED_TYPES.ADVANCED_REPORT) {
    return;
  }
  const dispatch = store.dispatch;
  let pctSiteReportsDeselectParam = "";

  let jwt = auth.isAuthenticated();
  let id = jwt.data.user.id;
  let token = jwt.data.accessToken;

  let target = "categories";
  let queryParam = `?id=${id}`;
  if (auth.isAdminRole()) {
    target = "categories_observe";
    let pid = partnersSelected.map((p) => p.dummy_id).join(",");
    queryParam = `?pid=${pid}`;
    // queryParam = `?pid=${partnersSelected}`;
  }

  //list of sites with applied % of report amount - exclude minor values
  //default pctSiteReportsDeselection value = 10%

  if (Boolean(pctSiteReportsDeselection) && pctSiteReportsDeselection !== DEFAULT_SITE_FILTRATION_DESELECTION) {
    let pctDeselect = pctSiteReportsDeselection / 100;
    pctSiteReportsDeselectParam = `&pct_site_deselect=${pctDeselect}`;
  } else {
    if (+advancedReport.pctSiteReportsDeselection !== DEFAULT_SITE_FILTRATION_DESELECTION) {
      let pctDeselect = advancedReport.pctSiteReportsDeselection / 100;
      pctSiteReportsDeselectParam = `&pct_site_deselect=${pctDeselect}`;
    }
  }
  let requests = categories.map((name) => {
    dispatch(setFiltersIsLoading({ name, value: true }));
    const queryParamAdopted =
      partnersSelected.length === role.partnersList.length && name === "sites" ? `?pid=*` : queryParam;
    return fetch(`${SERVER_URL}/statistics/${target}/${name}${queryParamAdopted}${pctSiteReportsDeselectParam}`, {
      method: "GET",
      headers: {
        authorization: "Bearer " + token,
      },
      signal: signal,
    })
      .then((response) => response.json())
      .then((data) => {
        dispatch(setFilter(data));
      })
      .catch((error) => console.error("Error fetching categories:", error));
  });

  await Promise.allSettled(requests);
};

export { getCategories };
