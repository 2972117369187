import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store/store";
import { setChangedParameter, setCurrentPlatformParameter } from "../platformParametersSlice";
import { setParametersConstancyWrapper } from "../parametersConstancyUtils";
import { CategoriesType, CategoryType } from "../parametersConstancyTypes";
import { CONSTANT } from "../../constants/constants";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";

const PerformanceReportChangedParametersHandler: React.FC = () => {
  const dispatch = useAppDispatch();
  const { filters }: { filters: CategoriesType } = useSelector((state: RootState) => state.advancedReport);
  const { filtersIsLoading }: any = useSelector((state: RootState) => state.advancedReport);
  const { activeAreaChartType }: any = useSelector((state: RootState) => state.advancedReport);
  const { currenciesToDisplay }: any = useSelector((state: RootState) => state.advancedReport.widgetChart);
  const columnVisibilityModel = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.performanceReportParameters.columnVisibilityModel ?? {},
  );
  const columnVisibilityModelVR: GridColumnVisibilityModel = useSelector(
    (state: RootState) =>
      state.platformParameters.currentPlatformParameters.performanceReportParameters.columnVisibilityModelVR ?? {},
  );

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "performanceReportParameters",
          "areaChartCurrenciesToDisplay",
          currenciesToDisplay,
        ),
      ),
    );
  }, [currenciesToDisplay, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper("performanceReportParameters", "activeAreaChartType", activeAreaChartType),
      ),
    );
  }, [activeAreaChartType, dispatch]);

  useEffect(() => {
    const activeFilters = Object.fromEntries(
      Object.entries(filters)
        .filter(([_, values]) => Array.isArray(values))
        .map(([key, values]: [string, CategoryType[]]) => {
          const selectedValues = values.filter((unit: CategoryType) => unit.isSelected);
          if (
            key !== CONSTANT.REPORTS.FILTERS_KEYS.PCT.base &&
            key !== CONSTANT.REPORTS.FILTERS_KEYS.CURRENCIES.base &&
            key !== CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base &&
            !filtersIsLoading[key] &&
            filters[key] &&
            selectedValues?.length > 0 &&
            filters[key].length === selectedValues.length
          ) {
            return [key, [CONSTANT.REPORTS.SELECTED_ALL_OBJECT]];
          } else if (key === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base) {
            return [key, values];
          } else {
            if (key === CONSTANT.REPORTS.FILTERS_KEYS.CALLS.base) {
              return [key, values];
            } else {
              return [key, selectedValues];
            }
          }
        }),
    );
    dispatch(
      setChangedParameter(setParametersConstancyWrapper("performanceReportParameters", "filters", activeFilters)),
    );
    dispatch(
      setCurrentPlatformParameter(
        setParametersConstancyWrapper("performanceReportParameters", "filters", activeFilters),
      ),
    );
  }, [filters, dispatch]);

  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "performanceReportParameters",
          "columnVisibilityModel",
          columnVisibilityModel,
        ),
      ),
    );
  }, [columnVisibilityModel, dispatch]);
  useEffect(() => {
    dispatch(
      setChangedParameter(
        setParametersConstancyWrapper(
          "performanceReportParameters",
          "columnVisibilityModelVR",
          columnVisibilityModelVR,
        ),
      ),
    );
  }, [columnVisibilityModelVR, dispatch]);

  return null;
};

export default PerformanceReportChangedParametersHandler;
