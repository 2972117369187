import moment from "moment";
import { CONSTANT } from "../../../../constants/constants";
import { uniqs } from "../../../../report/utils";
import { nFormatter } from "../../../../utils";

const incorrectData = {
  tableText: "---",
  tooltipText: "Incorrect data",
};

export const addColumns = (
  activeFilters,
  defaultColumns,
  optionalColumns,
  apt,
  combinedDataMode,
  partnersAmount,
  timeGrouping,
  dateGroupingMode,
) => {
  let copy = defaultColumns
    .slice()
    .filter((i) => "fieldApt" in i === false || i.fieldApt === apt)
    .filter((i) => "patnerName" in i === false || (i.patnerName === combinedDataMode && partnersAmount > 0));

  let res = [];
  for (let i in activeFilters) {
    if (!Array.isArray(activeFilters[i])) continue;
    if (dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR && i === CONSTANT.REPORTS.FILTERS_KEYS.SITES.base)
      continue;
    if (activeFilters[i].length > 0 && [i] in optionalColumns) {
      res.push(optionalColumns[i]);
    }
  }

  for (let i of copy) {
    if (i.field === "submitted_date" && dateGroupingMode === CONSTANT.DATE_GROUP_MODES.HOUR) {
      i.groupedByHours = true;
      break;
    } else {
      i.groupedByHours = false;
      break;
    }
  }
  // }
  copy.splice(4, 0, ...res);
  return copy;
};

export const getValues = (array, currencyArray) => {
  let accumulator = {
    total_cpm1: {},
    total_cpm2: {},
    avg_cpma: {},
    avg_cpmb: {},
    abRevActualRateLift: {},
    sum2RevMulti: {},
    sum2RevNonMulti: {},
    totalRows1ByCurrency: {},
    totalRows2ByCurrency: {},
  };

  // Define keys that should be treated as dictionaries with currency keys
  const dictionaryKeys = [
    "total_cpm1",
    "total_cpm2",
    "avg_cpma",
    "avg_cpmb",
    "sum2RevMulti",
    "sum2RevNonMulti",
    "totalRows1ByCurrency",
    "totalRows2ByCurrency",
  ];

  for (let item of array) {
    const itemCurrency = item.currency;

    // Handle dictionary keys
    for (let key of dictionaryKeys) {
      if (currencyArray.includes(itemCurrency)) {
        accumulator[key][itemCurrency] = accumulator[key][itemCurrency] || 0;
        let val;
        switch (key) {
          case "totalRows1ByCurrency": {
            val = Number(item["total_rows1"]);
            break;
          }
          case "totalRows2ByCurrency": {
            val = Number(item["total_rows2"]);
            break;
          }
          case "sum2RevMulti": {
            val = Number((item["total_cpm2"] * item["pct"]) / (100 - item["pct"]));
            break;
          }
          case "sum2RevNonMulti": {
            val = Number((item["total_cpm2"] * 100) / (100 - item["pct"]));
            break;
          }
          default:
            val = Number(item[key]);
        }
        if (!Number.isNaN(val)) {
          accumulator[key][itemCurrency] += val;
        }
      }
    }

    // Handle other numeric keys
    for (let key in item) {
      if (
        !dictionaryKeys.includes(key) &&
        key !== "currency" &&
        key !== "sum2RevNonMulti" &&
        key !== "sum2RevMulti"
      ) {
        let val = Number(item[key]);
        if (!Number.isNaN(val)) {
          accumulator[key] = (accumulator[key] || 0) + val;
        }
      }
    }
  }

  return accumulator;
};

export const filterValues = (items, ids, key = "id") => {
  let filteredItems = items.filter((i) => {
    return ids.includes(i[key]);
  });

  return filteredItems;
};

export const filterValuesLoop = (items, ids, key = "id") => {
  let filteredItems = [];
  for (let i = 0; i < items.length; i++) {
    if (ids.includes(items[i][key])) {
      filteredItems.push(items[i]);
    }
  }
  return filteredItems;
};

export const calcAvgCpmToSummaryRow = (summary, currencyArray) => {
  let sum_avg_cpma = {};
  let sum_avg_cpmb = {};
  let sum_total_cpm1 = {};
  let sum_total_cpm2 = {};

  currencyArray.forEach((currency) => {
    // Use the avg_cpma and avg_cpmb values from the summary object
    let avg_cpma_value = (1000 * summary.total_cpm1[currency]) / summary.totalRows1ByCurrency[currency] || 0;
    let avg_cpmb_value = (1000 * summary.total_cpm2[currency]) / summary.totalRows2ByCurrency[currency] || 0;
    let total_cpm1_value = summary.total_cpm1[currency] || 0;
    let total_cpm2_value = summary.total_cpm2[currency] || 0;

    // Assign the values to the respective currency keys
    sum_avg_cpma[currency] = {
      value: avg_cpma_value,
      tableText: nFormatter(avg_cpma_value, 2, true, false),
      tooltipText: nFormatter(avg_cpma_value, 2, false, true),
    };
    sum_avg_cpmb[currency] = {
      value: avg_cpmb_value,
      tableText: nFormatter(avg_cpmb_value, 2, true, false),
      tooltipText: nFormatter(avg_cpmb_value, 2, false, true),
    };

    sum_total_cpm1[currency] = {
      tableText: nFormatter(total_cpm1_value, 2, true, false,  {limitMetricPrefix: true, setCommas: true}),
      tooltipText: nFormatter(total_cpm1_value, 2, false, true),
    };

    sum_total_cpm2[currency] = {
      tableText: nFormatter(total_cpm2_value, 2, true, false,  {limitMetricPrefix: true, setCommas: true}),
      tooltipText: nFormatter(total_cpm2_value, 2, false, true),
    };
  });

  return {
    sum_avg_cpma,
    sum_avg_cpmb,
    sum_total_cpm1,
    sum_total_cpm2,
  };
};

export const calcAvgCpmRateLiftToSummaryRow = (sum_avg_cpma, sum_avg_cpmb) => {
  let res = {};
  for (const property in sum_avg_cpma) {
    if (sum_avg_cpmb[property].value === 0) {
      res[property] = {
        tableText: incorrectData.tableText,
        tooltipText: incorrectData.tooltipText,
      };
    } else {
      const calc =
        (100 * (sum_avg_cpma[property].value - sum_avg_cpmb[property].value)) / sum_avg_cpmb[property].value;
      res[property] = {
        tableText: nFormatter(calc, 2, false, false) + "%",
        tooltipText: nFormatter(calc, 2, false, false) + "%",
        value: calc,
      };
    }
  }
  return res;
};

export const calcAbFillRateLiftToSummaryRow = (summary) => {
  if (summary.total_rows2 > 0) {
    if (summary.total_rows2Norm === 0) return 0;
    const res = ((summary.total_rows1 - summary.total_rows2Norm) / summary.total_rows2Norm) * 100;
    return res;
  }
  return 0;
};

export const calcDeploymentRevLiftToSummaryRow = (summary, currencyArray) => {
  let sum_abRevRateLift = {};
  let sum_abRevActualRateLift = {};

  currencyArray.forEach((c) => {
    if (!summary.total_cpm2Norm || summary?.total_cpm2Norm[c] === 0) {
      sum_abRevRateLift[c] = {
        tableText: incorrectData.tableText,
        tooltipText: incorrectData.tooltipText,
      };
      sum_abRevActualRateLift[c] = {
        tableText: incorrectData.tableText,
        tooltipText: incorrectData.tooltipText,
      };
    } else {
      //let num =
      let num_sum_abRevRateLift =
        ((summary.total_cpm1[c] - summary.sum2RevMulti[c]) / summary.sum2RevMulti[c]) * 100;
      sum_abRevRateLift[c] = {
        tableText: nFormatter(num_sum_abRevRateLift, 2, true, false),
        tooltipText: nFormatter(num_sum_abRevRateLift, 2, false, true),
      };
      let num_sum_abRevActualRateLift = summary.sum2RevNonMulti[c]
        ? ((summary.total_cpm1[c] + summary.total_cpm2[c] - summary.sum2RevNonMulti[c]) /
            summary.sum2RevNonMulti[c]) *
          100
        : null; // Deployment lift
      sum_abRevActualRateLift[c] = {
        tableText: num_sum_abRevActualRateLift
          ? nFormatter(num_sum_abRevActualRateLift, 2, true, false) + " %"
          : incorrectData.tableText,
        tooltipText: num_sum_abRevActualRateLift
          ? nFormatter(num_sum_abRevActualRateLift, 2, false, true)
          : incorrectData.tooltipText,
      };
    }
  });
  return { sum_abRevRateLift, sum_abRevActualRateLift };
};

export const abRevLiftCalc = (rows, currencyArray) => {
  let res = {};
  currencyArray.forEach((cur) => {
    const rowsWithCurrency = rows.filter((row) => row.currency === cur);
    const sum = rowsWithCurrency.reduce((sum, current) => (sum += current.abRevLift), 0);
    res[cur] = {
      tableText: nFormatter(sum, 2, true, false,  {limitMetricPrefix: true, setCommas: true}),
      tooltipText: nFormatter(sum, 2, false, true),
      value: sum,
    };
  });
  return res;
};

export const aggregateValuesToSummaryRow = (
  rows,
  activeFilters,
  source = "",
  pctOptions,
  pctFilters,
  currencyOptions,
  currencyFilters,
) => {
  let sumPct = "";
  let pctArray = [];
  if (pctFilters?.length === 0) pctArray = pctOptions.map((o) => o.name || o.pct);
  else pctArray = pctFilters.map((o) => o.name || o.id); //TODO: unify fields id - pct

  let currencyArray = [];
  if (currencyFilters.length === 0) currencyArray = currencyOptions.map((o) => o.name || o.currency);
  else currencyArray = currencyFilters.map((o) => o.name || o.currency);

  let summary = getValues(rows, currencyArray);
  let optionalColumnsSummaryAndPct = {};
  let activeFiltersArray = Object.keys(activeFilters).filter(
    (i) => Array.isArray(activeFilters[i]) && activeFilters[i].length,
  );
  // map filters
  activeFiltersArray.forEach((filter, index) => {
    activeFiltersArray[index] = CONSTANT.filtersKeysUnderscore[filter];
  });

  let mapOfUniqs = uniqs(rows, [...activeFiltersArray, "pName"]);

  mapOfUniqs.forEach((value, key, map) => {
    //TO DO : find another solution for cases if there's only one partner
    let arr = Array.from(value);
    if (key !== "pName") {
      optionalColumnsSummaryAndPct[key] = { tableText: arr.length };
    }
    if (key === "pName" && !arr.includes(undefined)) {
      optionalColumnsSummaryAndPct[key] = arr.length;
    }
  });

  let { sum_avg_cpma, sum_avg_cpmb, sum_total_cpm1, sum_total_cpm2 } = calcAvgCpmToSummaryRow(
    summary,
    currencyArray,
  );

  let sum_avg_cpmRateLift = calcAvgCpmRateLiftToSummaryRow(sum_avg_cpma, sum_avg_cpmb);

  let abFillRateLift = calcAbFillRateLiftToSummaryRow(summary);

  // let abRevLiftString = "";

  const abRevLift = abRevLiftCalc(rows, currencyArray);

  let pctArrToString = pctArray.sort((a, b) => b - a).join("%, \n") + "%";
  sumPct = {
    tableText: pctArrToString,
    tooltipText: pctArrToString,
  };

  //let { sum_abRevRateLift, sum_abRevActualRateLift } = calcDeploymentRevLiftToSummaryRow(summary, pctArray);
  let { sum_abRevRateLift, sum_abRevActualRateLift } = calcDeploymentRevLiftToSummaryRow(summary, currencyArray);
  return {
    total_rows1: {
      tableText: summary.total_rows1 > 0 ? nFormatter(summary.total_rows1, 2, true, false) : 0,
      tooltipText: summary.total_rows1 > 0 ? nFormatter(summary.total_rows1, 2, false, true) : 0,
    },
    total_rows2: {
      tableText: summary.total_rows2 > 0 ? nFormatter(summary.total_rows2, 2, true, false) : 0,
      tooltipText: summary.total_rows2 > 0 ? nFormatter(summary.total_rows2, 2, false, true) : 0,
    },
    avg_cpma: sum_avg_cpma,
    avg_cpmb: sum_avg_cpmb,
    // abRevRateLift: sum_abRevRateLift, // see columns model -  "100% Deployment revenue lift" not in usage
    abRevActualRateLift: sum_abRevActualRateLift,
    abRevRateLift: sum_abRevRateLift,
    avgCpmRateLift: sum_avg_cpmRateLift,
    abFillRateLift: {
      tableText: abFillRateLift.toFixed(2) + "%",
      tooltipText: abFillRateLift.toFixed(2) + "%",
    },
    ...optionalColumnsSummaryAndPct,
    pct: sumPct,
    abRevLift: abRevLift,
    total_cpm1: sum_total_cpm1,
    total_cpm2: sum_total_cpm2,
    pName: {
      tableText: optionalColumnsSummaryAndPct.pName,
    },
    submitted_date: {
      tableText: "Total: ",
    },
  };
};

export const getRenderPeriod = (date, groupingType, renderSelectedDate) => {
  switch (groupingType) {
    case CONSTANT.DATE_GROUP_MODES.DAY:
      return moment(date).format("MMM DD, YYYY");
    case CONSTANT.DATE_GROUP_MODES.MONTH:
      return moment(date).format("MMMM YYYY");
    case CONSTANT.DATE_GROUP_MODES.QUARTER:
      const quarterDate = moment(date);
      const y = moment(date).format("YY");
      const quarter = quarterDate.quarter();
      const startOfQuarter = moment().quarter(quarter).startOf("quarter").format("MMM");
      const endOfQuarter = moment().quarter(quarter).endOf("quarter").format("MMM");
      return `${startOfQuarter} '${y} - ${endOfQuarter} '${y}`;
    case CONSTANT.DATE_GROUP_MODES.AGGREGATED:
      return renderSelectedDate();
    default:
      break;
  }
};
// export const retriveModelItemUnit = (unit, currency, valueAmount) => {
//   if (unit === "currency") {
//     return Boolean(currency) ? currency : "n/a";
//   }

//   if (unit === "%") {
//     return unit;
//   }

//   if (unit === "country") {
//     return valueAmount > 1 ? "countries" : unit;
//   }

//   return valueAmount > 1 ? unit + "s" : unit;
// };
