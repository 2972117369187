import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../../auth/auth-helper";
import { CONSTANT } from "../../constants/constants";
import { getReportPctModes } from "../../report/utils";
import { checkVersion } from "../../infrastructure/infrastructureHelpers";
import { transformColumnBaseToRowBase } from "../../core/performanceReports/advancedReport/utils";

const host = CONSTANT.path.host;

export const getSummaryReport = createAsyncThunk(
  "summary/getSummaryReport",
  async (report, { rejectWithValue, dispatch, getState }) => {
    let { token, signal } = report;
    let currentState = getState();
    let ct = currentState.report.active_chart;
    let rt = report.rt;
    let customDateRange = report.customDateRange;
    let dgm =
      currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode !==
      CONSTANT.DATE_GROUP_MODES.HOUR
        ? currentState.platformParameters.currentPlatformParameters.commonUserPlatformParameters.dateGroupingMode
        : CONSTANT.DATE_GROUP_MODES.DAY;
    let target = "report_summary";
    let pidParam = "";
    let customDateParam = "";

    if (auth.isAdminRole()) {
      if (!Boolean(currentState.role.partnersSelected.length)) {
        return {
          result: { data: [] },
          activeChart: ct,
          activeRangeDate: rt,
        };
      }
      target = "report_summary_monitoring";
      let pids = currentState.role.partnersSelected
        .filter((p) => p.isSelected)
        .map((p) => p.dummy_id)
        .join(",");
      pidParam = `&pid=${pids}`;
    }
    if ("ct" in report) ct = report.ct;

    if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
      customDateParam = `&cstm_start=${customDateRange.customDateStart}&cstm_end=${customDateRange.customDateEnd}`;
    }

    try {
      let response = await fetch(
        `${host}/reports/${target}/${report.id}?rt=${rt}&ct=${ct}&dgm=${dgm}${pidParam}${customDateParam}`,
        {
          method: "GET",
          headers: {
            authorization: "Bearer " + token,
          },
          signal,
        },
      );

      if (!response.ok) {
        throw new Error("server can not get report");
      }

      let result = {};
      const responseJSON = await response.json();
      result.reportSummary = transformColumnBaseToRowBase(responseJSON.reportSummary);
      result.reactAppVersion = responseJSON.reactAppVersion;
      checkVersion(result.reactAppVersion);
      let { pctModes, uniqPairs } = getReportPctModes(result.reportSummary);

      let data = {
        result,
        activeChart: ct,
        activeRangeDate: rt,
        dateGroupingMode: dgm,
        reportSelectedPct: currentState.report.selectedPct ? currentState.report.selectedPct : uniqPairs[0]?.pct,
        reportSelectedCurrency: currentState.report.selectedCurrency
          ? currentState.report.selectedCurrency
          : uniqPairs[0]?.currency,
        // reportSelectedPct: pctModes[0],
        reportRawPct: currentState.report.rawPct.length === 0 ? pctModes : currentState.report.rawPct,
        uniqPairs: uniqPairs,
        settingsStrictMode: currentState.report.settingsStrictMode,
      };

      if ("setFilterBase" in report) data.filterBase = report.setFilterBase;
      if (rt === CONSTANT.DATE_RANGE_TYPES.CUSTOM.value) {
        data.customDateRange = customDateRange;
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  },
);
