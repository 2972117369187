import auth from "../auth/auth-helper";
export const checkVersion = async (newVersion: string) => {
  const storedVersion = localStorage.getItem("reactAppVersion");
  if (!storedVersion || storedVersion === "undefined") {
    localStorage.setItem("reactAppVersion", newVersion);
  } else {
    if (storedVersion !== newVersion) {
      //dispatch(updateVersion(serverVersion));
      console.log("App version updated, reloading the page...");
      localStorage.setItem("reactAppVersion", newVersion);
      window.location.reload();
      auth.clearJWT(() => {});
    }
  }
};
