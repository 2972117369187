import React, { useState } from 'react';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess'; import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

const buttonStl = {
  width: "4rem",
  height: "4rem",
  position: "fixed",
  right: "1.3%",
  bottom: "40px",
  zIndex: "1500",
  cursor: "pointer",
  color: "#131330",
  opacity: 0.55,
  '&:hover': {
    backgroundColor: "rgba(27, 178, 255, 0.5)",
    color: "secondary.mainDark",
    opacity: 0.75,
  },
}

const ScrollButton = () => {
  const [visible, setVisible] = useState(false)
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true)
    }
    else if (scrolled <= 300) {
      setVisible(false)
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Box>
      <Button
        id="thScroll"
        sx={{ ...buttonStl, display: visible ? 'inline' : 'none' }}
      >
        <ExpandLessIcon onClick={scrollToTop} sx={{ fontSize: "3rem", }} />
      </Button>
    </Box>
  );
}

export default ScrollButton; 