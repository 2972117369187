import * as React from "react";
import { Chip } from "@mui/material";
import { colors } from "../../theme";
interface UserStatusChipProps {
  label: string;
}

const UserRoleLabel: React.FC<UserStatusChipProps> = ({ label }) => {
  return (
    <Chip
      label={label}
      size="medium"
      sx={{
        bgcolor: colors.primaryDark5,
        color: colors.primaryDark100,
      }}
    />
  );
};

export default UserRoleLabel;
