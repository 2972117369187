export const saveToSessionStorage = (key: any, value: any) => {
  try {
    const serializedValue = JSON.stringify(value);
    sessionStorage.setItem(key, serializedValue);
  } catch (error) {
    console.error("Error saving to session storage:", error);
  }
};

export const loadFromSessionStorage = (key: any, defaultValue: any = null) => {
  try {
    const serializedValue = sessionStorage.getItem(key);
    return serializedValue ? JSON.parse(serializedValue) : defaultValue;
  } catch (error) {
    console.error("Error loading from session storage:", error);
    return defaultValue;
  }
};
