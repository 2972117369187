import { useState } from "react";
import { useDispatch } from "react-redux";
import { resetEditedUser, setAvatrDialogOpen } from "../users/usersSlice";
export default function useCreateAccountBoard({ elemRef, customId } = false) {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    dispatch(setAvatrDialogOpen(false));
    dispatch(resetEditedUser());
    setOpen(false);
  };

  return {
    open,
    handleClickOpen,
    onClose,
  };
}
