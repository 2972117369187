import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { BasicTooltip } from "../../../../components/BasicTooltip/BasicTooltip";
import { colors } from "../../../../theme";

const renderHeaderOnHoverStl = (h = 45) => {
  return {
    // wordWrap: "normal !important",
    height: `${h}px !important`,
    overflow: "hidden !important",
    textOverflow: "ellipsis !important",
    whiteSpace: "nowrap !important",
  };
};

export const mainTableStyle = (baseline = "center", trimOnHover = false) => ({
  // height: "500px",
  // width: "99%",
  "& .MuiDataGrid-root": {
    border: "none",
    // position: "static", // N.B.: applied here position "static" - is the way to make "export" btn related to parent's parent wrapper (#mainTableBlockWrapper)
    "&:focus ": {
      border: "none",
    },
    "& .MuiDataGrid-columnHeadersInner": {
      lineHeight: "80px !important",
      maxHeight: "80px !important",
    },
    "& .MuiDataGrid-columnHeaders": {
      // lineHeight: "40px !important",
      // maxHeight: "80px !important",
      minHeight: "80px !important",
      fontSize: "14px",
      borderTop: `1px solid ${colors.border}`,
      borderRadius: "0",
      fontWeight: 700,
      position: "sticky",
      backgroundColor: "#fff",
      zIndex: 9,
      top: 0, // Don't forget this, required for the stickiness
      // borderBottom: "1px solid #1BB2FF",
      "&:hover .MuiDataGrid-columnSeparator": {
        visibility: "hidden",
      },
      "&:focus ": {
        border: "none",
      },
    },
    "& .group-header": {
      maxHeight: "40px !important",
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "14px",
      padding: "0px",
      border: "unset",
      borderBottom: `1px solid ${colors.border}`,
      borderLeft: `1px solid ${colors.border}`,
      alignItems: "center",
      "&:focus ": {
        border: "none",
      },
    },
    ".MuiDataGrid-columnHeaderTitleContainer": {
      display: "flex",
      flexDirection: "row !important",
      justifyContent: "center",
      alignItems: "center",
      padding: "0 10px",
      border: "unset",
      borderStyle: "none !important",
      borderBottom: "none !important",
      // "&:focus ": {
      //   border: "none",
      // },
    },
    "& .single-group-header": {
      maxHeight: "40px !important",
      padding: "0px",
      textAlign: "center",
      justifyContent: "center",
      alignItems: "center",
      borderLeft: `1px solid ${colors.border}`,
      // "&:focus ": {
      //   border: "none",
      // },
    },

    "& .group-header-child": {
      maxHeight: "40px !important",
      textAlign: "center",
      fontSize: "14px",
      padding: "0px 10px 0px 0px",
      justifyContent: "center",
      alignItems: "center",
      border: `none`,
      borderLeft: `1px solid ${colors.border}`,
      // borderBottom: `1px solid red`,
      // "&:focus ": {
      //   border: "none",
      // },
    },
    "& .group-header-single-child": {
      maxHeight: "40px !important",
      textAlign: "center",
      fontSize: "14px",
      padding: "0px 5px 0px 0px",
      justifyContent: "center",
      alignItems: "center",
      border: `none`,
      borderLeft: `1px solid ${colors.border}`,
      // borderBottom: `1px solid ${colors.border}`,
      // "&:focus ": {
      //   border: "none",
      // },
    },
    ".MuiDataGrid-columnHeaderDraggableContainer": {
      display: "flex",
      flexDirection: "row !important",
    },
    "& .group-header-single-child .MuiDataGrid-columnHeaderDraggableContainer": {
      display: "flex",
      flexDirection: "row !important",
      position: "relative",
      padding: "0px 10px 0px 5px",
      top: "-45%",
      // "&:focus ": {
      //   border: "none",
      // },
    },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontSize: "14px",
      border: "none",
      borderStyle: "none",
      alignItems: "center",
      // "&:focus ": {
      //   border: "none",
      // },
    },

    "&:hover": null,

    "& .MuiDataGrid-withBorderColor": {
      "&:last-child": {
        padding: `0px !important`,
        "& span": {
          marginRight: "11px",
        },
      },
    },

    "& .MuiIconButton-root": {
      "&:last-child": {
        padding: `0px 6px !important`,
      },
    },
    "& .MuiDataGrid-row.Mui-selected": {
      background: "#fff",
      border: "none",
    },
  },
  "& .MuiDataGrid-cell": {
    // borderBottom: "1px solid #E0E2E7",
    // padding: "0",
    // height: "60px",
    border: "none",
    "&:focus": {
      outline: "1px solid red",
      border: "none",
    },
  },

  "& .MuiDataGrid-overlayWrapper": {
    // position: -webkit-sticky;
    justifyContent: "center",
    alignItems: "center",
    position: "sticky",
    top: "80px",
    /* left: 0; */
    width: 0,
    height: "40px !important",
    zIndex: 9,
  },
  "& .MuiDataGrid-overlayWrapperInner": {
    // pointerEvents: "none", //enables tooltips in table
    justifyContent: "center",
    alignItems: "center",
    height: "40px !important",
  },
  "& .MuiDataGrid-virtualScroller": {
    justifyContent: "center",
    alignItems: "center",
    color: "secondary.mainDark",
    fontWeight: 400,
    fontSize: "12px",
    // Undo the margins that were added to push the rows below the previously fixed header
    marginTop: "0 !important",
    overflow: "visible !important",
    // borderLeft: "1px solid #E0E2E7",
  },
  "& .MuiDataGrid-main": {
    // Not sure why it is hidden by default, but it prevented the header from sticking
    overflow: "visible",
  },
  // "& .MuiDataGrid-columnHeaderTitle": {
  //   whiteSpace: "normal",
  //   lineHeight: "normal",
  //   wordWrap: "break-word",
  //   height: trimOnHover ? "35px !important" : "auto",
  //   "&:hover": trimOnHover ? { ...renderHeaderOnHoverStl(45) } : null,
  // },
  // "& .MuiDataGrid-iconButtonContainer > button": {
  //   fontSize: ".875rem",
  //   padding: "0px",
  //   marginRight: "8px !important",
  //   marginLeft: "8px !important",
  // },
  // "& .MuiDataGrid-menuIcon": {
  //   margin: "0px",
  //   order: "-1 !important", // Moves the menu icon to the left
  // },

  [`& .Mui-even`]: {
    backgroundColor: "secondary.tableOffWhite",
  },
  // "& .MuiDataGrid-virtualScrollerRenderZone > .MuiDataGrid-row .Mui-selected": {
  //   background: "red!important"
  // }
  // ".MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
  //removes cell border in table when clicking on it
  //   outline: "none !important",
  // },
  ".MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within": {
    //removes cell border in table column header  when clicking on it
    outline: "none !important",
  },
  ".MuiDataGrid-root .MuiDataGrid-columnHeader": {
    //removes left border in table column header  when clicking on it
    "&:first-of-type": {
      // color: `red !important`,
      borderLeft: "none",
    },
  },
  ".highlight-cell": {
    backgroundColor: "#fff",
    // borderLeft: "1px solid #84DAFF",
    // borderRight: "1px solid #84DAFF",
    boxShadow: "0px 2px 11px 0px rgba(169,169,169,0.35)",
  },
  ".highlight-cell-b": {
    backgroundColor: "#9ae3ef17",
  },
});

export const summaryRowWrapper = {
  justifyContent: "center",
  alignItems: "center",
  //paddingLeft: "30px",
  margin: "8px 0px 32px",
  borderRadius: "8px",
  background: "#F0FAFF",
  "& div :not(:last-child)": {
    margin: "0px 5px 0px 0px",
  },
  width: "100%",
};

export const summaryRowItem = {
  // padding: "10px 3px 10px 10px",
  // flex: 1,
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 9px 18px rgba(0, 23, 56, 0.05)",
};

export const summaryRowItemHeaderName = {
  height: "35px",
  fontSize: "14px",
  overflow: "hidden",
  paddingBottom: "12px",
};

export const columnTablePanelStyle = {
  // MuiDataGrid-panel
  // top: '-100px !important',
  // right: "25px !important",
  "& .MuiPaper-root": {
    // padding: "1rem",
    // minWidth: "20rem",
    boxShadow: "0px 12px 40px 0px rgba(0, 0, 0, 0.1)",
    color: "#001738",
    borderRadius: "1rem",
    overflow: "hidden",
    maxHeight: "unset",
  },
};

export const BasicGridItem = (props) => {
  return (
    <Grid
      key={"BasicGridItem" + props.index}
      sx={{
        flex: props.flex ? props.flex : 1,
        width: "100%",
        display: "flex",
        fontSize: "14px",
        textAlign: "center",
      }}
    >
      {props.children}
    </Grid>
  );
};

export const UnitedCell = (props) => {
  const backgroundColor = props?.backgroundColor ? props?.backgroundColor : "#fff";
  const cellKey = props.cellKey + "UnitedCell" || "UnitedCell";

  return (
    <BasicGridItem flex={props.flex}>
      <Box
        key={cellKey}
        sx={{
          backgroundColor: backgroundColor,
          borderRadius: "8px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          // paddingLeft: "9px",
          // paddingRight: "9px",
        }}
      >
        <BasicTooltip tooltipText={props.tooltipText}>{props.children}</BasicTooltip>
      </Box>
    </BasicGridItem>
  );
};
export const DividedCellWrapper = (props) => {
  const cellKey = props.cellKey + "DividedCellWrapper" || "DividedCellWrapper";
  return (
    <BasicGridItem flex={props.flex}>
      <Box
        key={cellKey}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          width: "100%",
        }}
      >
        {props.children}
      </Box>
    </BasicGridItem>
  );
};
export const DividedCellItem = (props) => {
  const cellKey = props.cellKey + "DividedCellItem" || "DividedCellItem";
  return (
    <Box
      key={cellKey}
      sx={{
        backgroundColor: props.field === "total_cpm1" ? "#47C9FF" : "#fff",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        padding: "5px 10px 5px 10px",
        width: "100%",
        height: "50px",
      }}
    >
      <BasicTooltip tooltipText={props.tooltipText}>{props.children}</BasicTooltip>
    </Box>
  );
};
