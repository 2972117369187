import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp } from "@mui/x-data-grid";
import { useDeleteConnectionMutation, useFetchConnectionByAccountIdQuery } from "./connectionsApi";
import { Box, Button, Typography } from "@mui/material";
import DeleteButton from "./DeleteButton";

// Define the columns based on your table structure
const columns: GridColDef[] = [
  { field: "id", headerName: "ID", flex: 1, minWidth: 100 },
  { field: "name", headerName: "Name", flex: 1, minWidth: 100 },
  { field: "strategy", headerName: "Strategy", flex: 1, minWidth: 100 },
  { field: "auth0Id", headerName: "Auth0 ID", flex: 1, minWidth: 100 },
  { field: "clientId", headerName: "Client ID", flex: 1, minWidth: 100 },
  { field: "domain", headerName: "Domain", flex: 1, minWidth: 100 },
  //   { field: "status", headerName: "Status" },
  {
    field: "createdAt",
    headerName: "Created At",

    type: "dateTime",
    valueGetter: (params) => (params.value ? new Date(params.value) : null),
    flex: 1,
    minWidth: 100,
  },
  //   {
  //     field: "updatedAt",
  //     headerName: "Updated At",
  //     type: "dateTime",
  //     valueGetter: (params) => (params.value ? new Date(params.value) : null),
  //   },
  { field: "description", headerName: "Description", flex: 1, minWidth: 100 },
  {
    field: "actions",
    headerName: "Actions",
    sortable: false,
    renderCell: (params) => <DeleteButton id={params.row.auth0Id} />,
    flex: 1,
    minWidth: 100,
  },
];

const ConnectionTable: React.FC<{ accountId: number }> = ({ accountId }) => {
  const { data: connections, error, isLoading } = useFetchConnectionByAccountIdQuery(accountId);
  return (
    <Box id="connections-table-container" sx={{ height: 700, width: "100%" }}>
      <Typography id="connections-table-title" component="h1" variant="h5">
        Manage account's connections
      </Typography>
      <DataGrid
        autoHeight
        rows={connections || []}
        columns={columns}
        getRowId={(row) => row.id}
        sx={{
          "& .MuiDataGrid-virtualScroller": {
            overflowX: "hidden",
            overflowY: "scroll",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            fontWeight: "bold",
          },
        }}
      />
    </Box>
  );
};

export default ConnectionTable;
