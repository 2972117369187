import React, { useState, useEffect } from "react";
import { Button, Box, Avatar } from "@mui/material";
import TextField from "@mui/material/TextField";
import Loader from "../../components/Loader";
import { addUserToAccount } from "../api/userDetails-api";
import RoleMenu from "../helpers/RoleMenu";
import { boardWrapperStl, stl, titleStl, titleStlAsterics, inputSx } from "./boardStl";
import { buttonSx } from "../../core/performanceReports/advancedReport/styles";
import SelectAvatarBox from "./SelectAvatarBox";
import { useDispatch, useSelector } from "react-redux";
import { setEditedUser } from "../users/usersSlice";
import { validatorFunctions } from "../helpers/board-helpers";
import { useGetAccountsQuery, useGetAllUsersQuery } from "../accountsApi";
import { SelectItemsWrapper } from "../../components/SelectItems/SelectItemsWrapper";
import auth from "../../auth/auth-helper";
import CreateNewRoleHandler from "./CreateNewRoleHandler";
import { Typography } from "antd";
const INPT_LENGTH = 50;

export default function AddUserToAccountBoardHandler(props) {
  // const jwt = auth.isAuthenticated();
  // const skipFetch = false; // jwt.data?.user?.acctId !== -7;
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const { editedUser, loggedUser } = useSelector((state) => state.users);
  const [targetAccount, setTargetAccount] = useState(props?.value || null);
  const { data: accounts, isFetching, isLoading, refetch: refetchAccounts } = useGetAccountsQuery();
  const [accountsWithSelection, setAccountsWithSelection] = useState();
  const [accountRoles, setAccountRoles] = useState([]);
  const { refetch: refetchUsers } = useGetAllUsersQuery();
  const [isCreatingRole, setIsCreatingRole] = useState(false);

  const [formValues, setFormValues] = useState({
    firstName: {
      value: editedUser.firstName,
      error: false,
      errorMessage: "Please, enter user's name ( " + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.lastName,
    },
    lastName: {
      value: editedUser.lastName,
      error: false,
      errorMessage: "Please, enter user's last name ( " + INPT_LENGTH + " symbols)",
      validationFunction: validatorFunctions.lastName,
    },
    email: {
      value: editedUser.email,
      error: false,
      errorMessage: "Please, add an email addres in correct format",
      validationFunction: validatorFunctions.email,
    },
    phone: {
      value: editedUser.phone,
      error: false,
      errorMessage: "Please, add phone num. (+XXXXXXXXXXXX ('+' is optional))",
      validationFunction: validatorFunctions.phone,
    },
    position: {
      value: editedUser.position,
      error: false,
      errorMessage: "Text too long",
      validationFunction: validatorFunctions.position,
    },
    role: {
      value: props.value.roles?.length ? props.value.roles[0].id : "",
      error: false,
      errorMessage: "Users' role should be provided",
      validationFunction: [(val) => !!val.trim().length],
    },
    avatarUrl: {
      value: "",
      error: false,
      errorMessage: "Wrong avatar url",
      validationFunction: [(val) => true],
    },
    targetAccount: {
      value: "",
      error: false,
      errorMessage: "",
      validationFunction: [(val) => true],
    },
  });
  useEffect(() => {
    if (editedUser.avatarUrl !== "" && formValues.avatarUrl.value !== editedUser.avatarUrl)
      setFormValues({
        ...formValues,
        avatarUrl: { ...formValues.avatarUrl, value: editedUser.avatarUrl },
      });
  }, []);
  useEffect(() => {
    const newTargetAccount = accounts?.list.find((a) => a.id === targetAccount?.id);
    setAccountsWithSelection(
      accounts?.list
        .filter((a) => a.canAdmin)
        .map((a) => {
          return { ...a, isSelected: a.id === targetAccount?.id || false };
        }),
    );
    let accountRoles = newTargetAccount?.roles.map((r) => {
      return { ...r, isSelected: false };
    });
    setTargetAccount(newTargetAccount);
    setAccountRoles(accountRoles);
  }, [isCreatingRole, accounts]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(setEditedUser({ ...editedUser, [name]: value }));
    setFormValues({
      ...formValues,
      [name]: { ...formValues[name], value, error: false },
    });
  };
  const handleSetSelectedAccounts = (newSelectedItems) => {
    setAccountsWithSelection(newSelectedItems);
    setAccountRoles(newSelectedItems.find((i) => i.isSelected).roles);
    setTargetAccount(newSelectedItems.find((i) => i.isSelected));
    setFormValues({
      ...formValues,
      accounts: newSelectedItems.filter((i) => i.isSelected),
    });
  };

  const handleleRoleSelection = (roleId) => {
    setFormValues({
      ...formValues,
      role: { ...formValues["role"], value: roleId, error: false },
    });
  };

  const handleRetrieve = (e) => {
    const formFields = Object.keys(formValues);
    let newFormValues = { ...formValues };
    let errors = [];

    for (let index = 0; index < formFields.length; index++) {
      const currentField = formFields[index];
      if (currentField === "role" || currentField === "accounts") continue;
      const currentValueStr = formValues[currentField].value.toString();

      for (let validator of formValues[currentField].validationFunction) {
        if (!validator(currentValueStr)) {
          newFormValues = {
            ...newFormValues,
            [currentField]: { ...newFormValues[currentField], error: true },
          };
          errors.push({ ...newFormValues[currentField], error: true });
        }
      }
    }

    if (!!errors.length) {
      setFormValues(newFormValues);
      return;
    }

    setShowLoader(true);

    let data = {
      firstName: formValues.firstName.value,
      lastName: formValues.lastName.value,
      phone: formValues.phone.value,
      email: formValues.email.value,
      position: formValues.position.value,
      role: formValues.role.value,
      avatarUrl: formValues.avatarUrl.value,
    };

    addUserToAccount(data, targetAccount.id).then((data) => {
      setShowLoader(false);

      if (data.error) {
        console.log(data.error);
        alert("User is not added: " + data.error);
      } else {
        // props.updateDataAndRows(data);
        refetchAccounts();
        refetchUsers();
        props.onClose();
      }
    });
  };

  let isDisabled =
    !Boolean(formValues.firstName.value) ||
    !Boolean(formValues.lastName.value) ||
    !Boolean(formValues.email.value) ||
    !Boolean(formValues.role.value.length > 0);
  if (showLoader || isFetching || isLoading) return <Loader sx={{ height: "80vh" }} />;

  return !isCreatingRole ? (
    <div id='add-user-to-account-popup' >
      <div style={boardWrapperStl}>
        <SelectAvatarBox avatarUrl={props.value.avatarUrl}></SelectAvatarBox>

        {/* First name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            First name
          </Box>
          <TextField
            sx={inputSx}
            id='first-name-input'
            onChange={handleChange}
            error={formValues.firstName.error}
            helperText={formValues.firstName.error && formValues.firstName.errorMessage}
            value={formValues.firstName.value}
            placeholder="First name"
            name="firstName"
            autoFocus
          />
        </Box>

        {/* Last name */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Last name
          </Box>
          <TextField
            id='last-name-input'
            sx={inputSx}
            onChange={handleChange}
            error={formValues.lastName.error}
            helperText={formValues.lastName.error && formValues.lastName.errorMessage}
            value={formValues.lastName.value}
            name="lastName"
            placeholder="Last name"
          />
        </Box>

        {/* Phone number */}
        <Box sx={stl}>
          <Box sx={titleStl}>Phone number</Box>
          <TextField
            id='phone-input'
            sx={inputSx}
            placeholder="+XXXXXXXXXXXX ('+' is optional)"
            name="phone"
            value={formValues.phone.value}
            onChange={handleChange}
            error={formValues.phone.error}
            helperText={formValues.phone.error && formValues.phone.errorMessage}
          />
        </Box>

        {/* email */}
        <Box sx={stl}>
          <Box
            sx={{
              ...titleStl,
              ...titleStlAsterics,
            }}
          >
            Email
          </Box>
          <TextField
            id='email-input'
            sx={inputSx}
            placeholder="Enter user's email"
            name="email"
            required
            value={formValues.email.value}
            onChange={handleChange}
            error={formValues.email.error}
            helperText={formValues.email.error && formValues.email.errorMessage}
          />
        </Box>

        {/* position */}
        <Box sx={stl}>
          <Box sx={titleStl}>Position</Box>
          <TextField
            id='position-input'
            sx={inputSx}
            placeholder="Enter users' position"
            name="position"
            value={formValues.position.value}
            onChange={handleChange}
            error={formValues.position.error}
            helperText={formValues.position.error && formValues.position.errorMessage}
          />

          <Box sx={stl}>
            <Box
              sx={{
                ...titleStl,
                ...titleStlAsterics,
              }}
            >
              Account
            </Box>
            <SelectItemsWrapper
              itemName="account"
              itemFieldName="company"
              items={accountsWithSelection}
              setSelectedItems={handleSetSelectedAccounts}
              isSingleSelect={true}
              id='company-select'
            />
          </Box>
          <Box
            sx={{
              ...stl,
              border: formValues.role.error ? "1px solid red" : null,
              borderRadius: "4px",
              width: "100%",
            }}
          >
            <RoleMenu
              onSelect={handleleRoleSelection}
              accountRoles={accountRoles}
              actionItem={{
                name: "Create custom role",
                onClick: () => {
                  setIsCreatingRole(true);
                },
              }}
            />
          </Box>
        </Box>
      </div>
      <div style={{ display: "flex", justifyContent: "center", position: "relative" }}>
        <Button id="add-user-btn" disabled={isDisabled} onClick={handleRetrieve} sx={buttonSx}>
          Add user
        </Button>
      </div>
    </div>
  ) : (
    <>
      <Box id='create-new-role-popup' sx={{ display: "flex", justifyContent: "center" }}>
        <Typography id='subtitle'>Creating a role...</Typography>
        <Button
          id='cancel-btn'
          color="error"
          onClick={() => {
            setIsCreatingRole(false);
          }}
        >
          Cancel
        </Button>
      </Box>
      <CreateNewRoleHandler
        targetAccount={targetAccount}
        value={props.value}
        onClose={() => {
          setIsCreatingRole(false);
          refetchAccounts();
        }}
      />
    </>
  );
}
