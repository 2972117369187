interface IconProps {
  color?: string;
}

const UploadIcon: React.FC<IconProps> = ({ color }) => {
  const fillColor = color || "#263558";
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.708 9.32L9.79 11.253C9.60405 11.4403 9.4997 11.6936 9.4997 11.9575C9.4997 12.2214 9.60405 12.4747 9.79 12.662C9.88133 12.7544 9.9901 12.8278 10.11 12.8779C10.2299 12.928 10.3586 12.9538 10.4885 12.9538C10.6184 12.9538 10.7471 12.928 10.867 12.8779C10.9869 12.8278 11.0957 12.7544 11.187 12.662L12.477 11.361L13.813 12.708C13.9043 12.8005 14.013 12.874 14.1329 12.9241C14.2527 12.9743 14.3813 13.0002 14.5113 13.0003C14.6412 13.0004 14.7699 12.9747 14.8898 12.9247C15.0098 12.8747 15.1186 12.8014 15.21 12.709C15.3953 12.5216 15.4993 12.2687 15.4995 12.0052C15.4997 11.7417 15.396 11.4887 15.211 11.301L13.246 9.321C13.1456 9.21904 13.0259 9.13806 12.8939 9.08275C12.762 9.02744 12.6203 8.99891 12.4772 8.99881C12.3341 8.99872 12.1924 9.02707 12.0604 9.0822C11.9283 9.13734 11.8085 9.21817 11.708 9.32Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5 10.007L11.501 19.993C11.501 20.55 11.949 21.001 12.501 21C13.054 21 13.501 20.55 13.501 19.993L13.5 10.006C13.5 9.451 13.052 9 12.5 9C11.948 9 11.499 9.451 11.5 10.007Z"
        fill={fillColor}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.438 5.481C8.18114 5.43897 7.92128 5.4179 7.661 5.418C4.856 5.419 2.5 7.62 2.5 10.499C2.5 13.408 4.885 16 7.6 16H10.481V14.007H7.6C5.943 14.007 4.485 12.344 4.485 10.499C4.485 8.721 5.954 7.412 7.589 7.412H7.601C7.99 7.412 8.287 7.463 8.571 7.562L8.741 7.625C9.346 7.873 9.616 7.379 9.616 7.379L9.766 7.112C10.496 5.765 11.967 5.016 13.482 4.993C14.487 5.003 15.454 5.37825 16.2027 6.04875C16.9514 6.71924 17.4306 7.63919 17.551 8.637L17.597 8.977C17.597 8.977 17.668 9.502 18.262 9.502C18.275 9.502 18.274 9.507 18.285 9.507H18.539C19.675 9.507 20.515 10.466 20.515 11.665C20.515 12.872 19.528 14.007 18.445 14.007H14.481V16H18.445C20.605 16 22.5 13.955 22.5 11.665C22.5 9.666 21.188 8.002 19.362 7.591C18.655 4.884 16.309 3.039 13.476 3C11.501 3.021 9.575 3.901 8.438 5.481Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default UploadIcon;
