import { createAsyncThunk } from "@reduxjs/toolkit";
import auth from "../auth/auth-helper";
import { CONSTANT } from "../constants/constants";
import { RootState } from "../store/store";
import {
  FilterGroup,
  PageChangedParametersDto,
  UserParametersConstancyRequestDto,
} from "./parametersConstancyTypes";
import { resetChangedParameters } from "./platformParametersSlice";
import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";
import { checkVersion } from "../infrastructure/infrastructureHelpers";

const SERVER_URL = CONSTANT.path.host;

export const storeParametersConstancy = createAsyncThunk(
  "changedParameters/storeParametersConstancy",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const {
      commonUserPlatformParameters,
      performanceReportParameters,
      overviewParameters,
      accountsParameters,
      configurationParameters,
    } = state.platformParameters.changedParameters;
    const { currentTemplate } = state.platformParameters;

    let token = auth.isAuthenticated().data.accessToken;
    const userPlatformParametersDto: UserParametersConstancyRequestDto = {
      templateId: currentTemplate.id,
      changedParameters: {
        commonUserPlatformParameters,
        overviewParameters,
        performanceReportParameters,
        accountsParameters,
        configurationParameters,
      },
    };
    await fetch(`${SERVER_URL}/parameters-constancy`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
      body: JSON.stringify({ userPlatformParametersDto }),
    });
    dispatch(resetChangedParameters());
    // No need to return any response
  }
);

export const getParametersConstancy = async () => {
  let token = auth.isAuthenticated().data.accessToken;

  try {
    const response = await fetch(`${SERVER_URL}/parameters-constancy`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      },
    });

    if (!response.ok) {
      console.error("Error fetching constancy parameters");
      return null;
    }

    let result: PageChangedParametersDto = await response.json();

    return result;
  } catch (error) {
    console.error("Error fetching constancy parameters...", error);
  }
};

const customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${SERVER_URL}/filter-groups`,
    prepareHeaders: (headers) => {
      let token = auth.isAuthenticated().data.accessToken;
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  });

  const result: any = await baseQuery(args, api, extraOptions);

  if (result.data && "reactAppVersion" in result.data) {
    checkVersion((result.data as any).reactAppVersion);
  }

  return result;
};

export const filterGroupsApi = createApi({
  reducerPath: "filterGroupsApi",
  baseQuery: customBaseQuery,
  tagTypes: ["FilterGroups"],
  endpoints: (builder) => ({
    createFilterGroup: builder.mutation<FilterGroup, FilterGroup>({
      query: (filterGroupDto) => ({
        url: "",
        method: "POST",
        body: filterGroupDto,
      }),
      invalidatesTags: ["FilterGroups"],
    }),
    getFilterGroups: builder.query<FilterGroup[], void>({
      query: () => ({
        url: "",
        method: "GET",
      }),
      providesTags: ["FilterGroups"],
    }),
    updateFilterGroup: builder.mutation<FilterGroup, FilterGroup>({
      query: (updateData) => ({
        url: `/${updateData.id}`,
        method: "PATCH",
        body: updateData,
      }),
      invalidatesTags: ["FilterGroups"],
    }),
    deleteFilterGroup: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FilterGroups"],
    }),
  }),
});

export const {
  useCreateFilterGroupMutation,
  useGetFilterGroupsQuery,
  useUpdateFilterGroupMutation,
  useDeleteFilterGroupMutation,
} = filterGroupsApi;
